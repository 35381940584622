import { round } from "mathjs";

export function realParseFloat(s: string): number {
  s = s.replace(/[^\d,.-]/g, ""); // strip everything except numbers, dots, commas and negative sign
  if (navigator.language.substring(0, 2) !== "de" && /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/.test(s)) {
    // if not in German locale and matches #,###.######
    s = s.replace(/,/g, ""); // strip out commas
    return parseFloat(s) || 0; // convert to number
  } else if (/^-?(?:\d+|\d{1,3}(?:\.\d{3})+)(?:,\d+)?$/.test(s)) {
    // either in German locale or not match #,###.###### and now matches #.###,########
    s = s.replace(/\./g, ""); // strip out dots
    s = s.replace(/,/g, "."); // replace comma with dot
    return parseFloat(s) || 0;
  } // try #,###.###### anyway
  else {
    s = s.replace(/,/g, ""); // strip out commas
    return parseFloat(s) || 0; // convert to number
  }
}

export function roundToNearestCents(number: any, round = 0.05): number {
  if (round === 0.05) {
    return Number((Math.ceil(number * 20 - 0.5) / 20).toFixed(2));
  } else {
    return Number((Math.round(number / round) * round).toFixed(2));
  }
}

export function roundInput(input: string, decimal = 2) {
  return round(realParseFloat(input), decimal) || 0;
}

export function roundAmount(amount: number, decimal = 2) {
  if (amount === undefined) return 0;
  return round(amount, decimal) || 0;
}

export const IBAN_CODE_LENGTHS = {
  AD: 24,
  AE: 23,
  AT: 20,
  AZ: 28,
  BA: 20,
  BE: 16,
  BG: 22,
  BH: 22,
  BR: 29,
  CH: 21,
  CY: 28,
  CZ: 24,
  DE: 22,
  DK: 18,
  DO: 28,
  EE: 20,
  ES: 24,
  FI: 18,
  FO: 18,
  FR: 27,
  GB: 22,
  GI: 23,
  GL: 18,
  GR: 27,
  GT: 28,
  HR: 21,
  HU: 28,
  IE: 22,
  IL: 23,
  IS: 26,
  IT: 27,
  JO: 30,
  KW: 30,
  KZ: 20,
  LB: 28,
  LI: 21,
  LT: 20,
  LU: 20,
  LV: 21,
  MC: 27,
  MD: 24,
  ME: 22,
  MK: 19,
  MR: 27,
  MT: 31,
  MU: 30,
  NL: 18,
  NO: 15,
  PK: 24,
  PL: 28,
  PS: 29,
  PT: 25,
  QA: 29,
  RO: 24,
  RS: 22,
  SA: 24,
  SE: 24,
  SI: 19,
  SK: 24,
  SM: 27,
  TN: 24,
  TR: 26,
  AL: 28,
  BY: 28,
  CR: 22,
  EG: 29,
  GE: 22,
  IQ: 23,
  LC: 32,
  SC: 31,
  ST: 25,
  SV: 28,
  TL: 23,
  UA: 29,
  VA: 22,
  VG: 24,
  XK: 20,
};
