import { Asset, InvoiceStatus, Offer, OfferStatus, ProjectStatus } from "src/app/domains";
import { EvaAddress } from "src/app/domains/address";
import { Phone } from "src/app/domains/phone";

import { Contact } from "../../contact/domains/contact";
import { Tag } from "../../eva-company/domains/eva-company-tag";

export enum CustomerStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export enum RelationType {
  CUSTOMER = "CUSTOMER",
  PROSPECT = "PROSPECT",
}

export enum CustomerType {
  INDIVIDUAL = "INDIVIDUAL",
  COMPANY = "COMPANY",
}

export class Customer {
  customer_id: string = "";
  customer_corporate_id: string = "";
  eva_company_id: string = "";
  name: string = "";
  address: string = "";
  address_lat: number = 0;
  address_lng: number = 0;
  contact: string = "";
  internal_notes: string = "";
  contacts: Contact[] = [];
  addresses: EvaAddress[] = [];
  tags_ids: string[];
  tags: Tag[] = [];
  local_company_id: string = "";
  vat_number: string = "";

  created_on?: Date;
  created_by?: string;
  country: string;

  updated_on?: Date;
  updated_by?: string;

  status: string = CustomerStatus.ENABLED;
  relation: string = RelationType.CUSTOMER;

  contacts_ids: string[];

  customer_address: EvaAddress = new EvaAddress();
  phone_office: string = "";
  phone_mobile: string = "";
  mobile_phone_details: Phone;
  office_phone_details: Phone;
  mail_office: string = "";
  metadata = {};
  customer_type: CustomerType = CustomerType.COMPANY;
  assets: Asset[];

  offers: Offer[] = [];

  constructor() {
    this.country = "CH";
  }

  public static createInstance(jsonData: Customer): Customer {
    const c = Object.assign(new Customer(), jsonData);
    c.created_on = jsonData.created_on ? new Date(jsonData.created_on) : null;
    c.updated_on = jsonData.updated_on ? new Date(jsonData.updated_on) : null;
    if (jsonData.offers) {
      c.offers = Offer.createInstances(jsonData.offers);
    }

    if (jsonData.customer_address) {
      c.customer_address = EvaAddress.createInstance(jsonData.customer_address);
    }
    if (jsonData.addresses) {
      c.addresses = EvaAddress.createInstances(jsonData.addresses);
    }
    if (jsonData.tags) {
      c.tags = Tag.createInstances(jsonData.tags);
    }

    if (jsonData.contacts) {
      c.contacts = Contact.createInstances(jsonData.contacts);
    } else c.contacts = [];
    if (jsonData.mobile_phone_details) {
      c.mobile_phone_details = Phone.createInstance(jsonData.mobile_phone_details);
    }
    if (jsonData.office_phone_details) {
      c.office_phone_details = Phone.createInstance(jsonData.office_phone_details);
    }

    return c;
  }

  public static createInstances(jsonData: any[]): Customer[] {
    return jsonData.map(Customer.createInstance);
  }

  clone(): Customer {
    let s = Object.assign(new Customer(), this);
    s.offers = Offer.createInstances(s.offers);
    return s;
  }

  static get statusLabel() {
    return {
      [CustomerStatus.ENABLED]: "Activé",
      [CustomerStatus.DISABLED]: "Désactivé",
    };
  }

  excelExport(financialData = false) {
    return {
      id: this.customer_corporate_id,
      name: this.name,
      contact: this.contact,
      address: this.address,
      status: this.status,
    };
  }

  // CUSTOMER_NAME

  get customer_name() {
    if (this.customer_type === CustomerType.COMPANY) {
      return this.name;
    } else {
      return this.contacts[0].display_name;
    }
  }

  // OFFERS
  get onGoingOffers(): Offer[] {
    if (!this.offers) return [];
    return this.offers.filter((x) => x.status === OfferStatus.ON_GOING);
  }
  get offersValueOnGoing(): number {
    return this.onGoingOffers.reduce((tot, item) => tot + item.totalNet(), 0);
  }
  get validatedOffers(): Offer[] {
    if (!this.offers) return [];
    return this.offers.filter((x) => x.status === OfferStatus.VALIDATED);
  }
  get offersValueValidated(): number {
    return this.validatedOffers.reduce((tot, item) => tot + item.totalNet(), 0);
  }

  // PROJECTS
  get allProjects() {
    let projects = this.offers
      .filter((x) => [OfferStatus.ON_GOING, OfferStatus.VALIDATED].includes(x.status))
      .flatMap((x) => x.project)
      .filter((x) => x);
    let deduplicate_projects = [];
    for (let p of projects) {
      if (!deduplicate_projects.find((x) => x.project_id === p.project_id)) {
        deduplicate_projects.push(p);
      }
    }
    return deduplicate_projects;
  }
  get onGoingProjects() {
    return this.allProjects.filter((x) => x.status === ProjectStatus.ON_GOING);
  }
  get onGoingProjectsCount() {
    return this.onGoingProjects.length;
  }
  get terminatedProjects() {
    return this.allProjects.filter((x) => x.status === ProjectStatus.TERMINATED);
  }
  get terminatedProjectsCount() {
    return this.terminatedProjects.length;
  }

  // INVOICES
  get allValidInvoices() {
    return this.offers.flatMap((x) => x.validInvoices);
  }
  get allInvoices() {
    return this.offers.flatMap((x) => x.allInvoices);
  }

  get waitingSentInvoices() {
    return this.allValidInvoices.filter((x) => x.status === InvoiceStatus.VALIDATED);
  }
  get waitingSentInvoicesValue() {
    return this.waitingSentInvoices.reduce((tot, item) => tot + item.gross_total_price, 0);
  }

  get waitingPaymentInvoices() {
    return this.allValidInvoices.filter((x) => x.status === InvoiceStatus.SENT);
  }
  get waitingPaymentInvoicesValue() {
    return this.waitingPaymentInvoices.reduce((tot, item) => tot + item.gross_total_price, 0);
  }

  get latePaymentInvoices() {
    let today = new Date();
    return this.allValidInvoices
      .filter((x) => x.status === InvoiceStatus.SENT)
      .filter((x) => x.payment_deadline > today);
  }
  get latePaymentInvoicesValues() {
    return this.latePaymentInvoices.reduce((tot, item) => tot + item.gross_total_price, 0);
  }

  get allBilledInvoices() {
    return this.allValidInvoices.filter((x) => [InvoiceStatus.SENT, InvoiceStatus.PAID].includes(x.status));
  }
  get sumBilled() {
    return this.allBilledInvoices.reduce((tot, item) => tot + item.gross_total_price, 0);
  }
  get percentBilled() {
    let sumBilled = this.sumBilled;
    return sumBilled > 0 ? (sumBilled / this.offersValueValidated) * 100 : 0;
  }

  get paidInvoices() {
    return this.allValidInvoices.filter((x) => x.status === InvoiceStatus.PAID);
  }
  get paidInvoicesValues() {
    return this.paidInvoices.reduce((tot, item) => tot + item.gross_total_price, 0);
  }
}
