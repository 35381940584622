import { Asset, Invoice, OfferItemGroup } from "src/app/domains";

import { Intervention } from "../../interventions/domains/intervention";
import { ContractBillingConfig } from "./contract_billing_config";
import { ContractInstallation } from "./contract_installation";

export enum ContractStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export enum ContractStatusLabel {
  ENABLED = "Actif",
  DISABLED = "Inactif",
}

export class Contract {
  contract_id = "";
  contract_corporate_id = "";
  eva_company_id = "";
  customer_id = "";
  contract_address_id = "";
  name = "";
  contract_start_date: Date = new Date();
  contract_end_date: Date = null;
  desc = "";
  customer_contacts_ids = [];
  renewable = true;
  renew_start_date: Date = new Date();
  renew_frequence_months = 12;
  employee_managers_ids = [];
  employee_technicians_ids = [];
  status: ContractStatus = ContractStatus.ENABLED;
  installations: ContractInstallation[] = [];
  contract_quotation: OfferItemGroup = null;
  renew_project_id = "";
  billing_config: ContractBillingConfig = new ContractBillingConfig();

  assets: Asset[] = [];

  // External data

  customer_name = "";
  interventions: Intervention[] = [];
  invoices: Invoice[] = [];

  public static createInstance(jsonData: Contract): Contract {
    const d = Object.assign(new Contract(), jsonData);
    if (d.installations) {
      d.installations = ContractInstallation.createInstances(d.installations);
    }
    if (d.contract_start_date) {
      d.contract_start_date = new Date(d.contract_start_date);
    }
    if (d.contract_end_date) {
      d.contract_end_date = new Date(d.contract_end_date);
    }
    if (d.renew_start_date) {
      d.renew_start_date = new Date(d.renew_start_date);
    }
    if (d.interventions) {
      d.interventions = Intervention.createInstances(d.interventions);
    }
    if (d.invoices) {
      d.invoices = Invoice.createInstances(d.invoices);
    }
    if (d.contract_quotation) {
      d.contract_quotation = OfferItemGroup.createInstance(d.contract_quotation);
    }
    if (d.billing_config) {
      d.billing_config = ContractBillingConfig.createInstance(d.billing_config);
    } else {
      d.billing_config = new ContractBillingConfig();
    }

    return d;
  }

  public static createInstances(jsonData: any[]): Contract[] {
    return jsonData.map(Contract.createInstance);
  }

  clone(): Contract {
    let d = Object.assign(new Contract(), this);
    return d;
  }

  public static cloneMany(contracts): Contract[] {
    return contracts.map((contract) => contract.clone());
  }

  getInstallation(contract_installation_id) {
    return this.installations.find(
      (installation) => installation.contract_installation_id === contract_installation_id
    );
  }
}
