import { EvaAddress } from "src/app/domains/address";
import { Phone } from "src/app/domains/phone";
import { getTodayUtc } from "src/app/shared/helpers/date.helpers";

import { EvaCompanyAudit } from "../../../domains/audit";
import { VAT } from "../../../domains/vat";
import { Intervention } from "../../interventions/domains/intervention";
import { MealBreak } from "../eva-company-hr/meal-break";
import { EvaCompanyBillingConfig } from "./eva-company-billing-config";
import { EvaCompanyExpenseReportConfig } from "./eva-company-expense-report-config";
import { Tag } from "./eva-company-tag";
import { InterventionConfig } from "./intervention_config";

export enum EvaCompanyStatus {
  ENABLED = "ENABLED",
  TEST = "TEST",
  DISABLED = "DISABLED",
}

export enum EvaFeatures {
  CORE = "CORE",
  EXPENSE_REPORT = "EXPENSE_REPORT",
  BUDGET_SPECULATIVE = "BUDGET_SPECULATIVE",
  MAINTENANCE_CONTRACT = "MAINTENANCE_CONTRACT",
  SEND_OFFER_TENDER = "SEND_OFFER_TENDER",
  QR_INVOICE = "QR_INVOICE",
  NOTIFICATION_SUMMARY = "NOTIFICATION_SUMMARY",
  PLANNING_NOTIFICATION = "PLANNING_NOTIFICATION",
  OFFER_GANTT = "OFFER_GANTT",
  OFFERS_V2 = "OFFERS_V2",
  CATALOG = "CATALOG",
  ANNUAL_SCHEDULE = "ANNUAL_SCHEDULE",
  CREDIT_NOTE = "CREDIT_NOTE",
  SCAN_EMAIL = "SCAN_EMAIL",
  INVOICE_V2 = "INVOICE_V2",
  BANKING = "BANKING",
  AUTH_EMAIL = "AUTH_EMAIL",
  NOTIF_PURCHASE_ORDER = "NOTIF_PURCHASE_ORDER",
}

export class HTMLEntry {
  entry_id: string;
  name: string;
  contentHTML: string;
  type: string;
  document_type: string;

  public static createInstance(jsonData: any): HTMLEntry {
    return Object.assign(new HTMLEntry(), jsonData);
  }

  public static createInstances(jsonData: any[]): HTMLEntry[] {
    return jsonData.map((e) => HTMLEntry.createInstance(e));
  }

  clone() {
    return Object.assign(new HTMLEntry(), this);
  }
}

export class DocumentEvaCompanyApparence {
  fontSize: string;
  fontFamily: string;
  verticalPadding: string;
  horizontalPadding: string;
  color: string;

  constructor() {
    this.fontSize = "10px";
    this.fontFamily = "Arial";
    this.verticalPadding = "25px";
    this.horizontalPadding = "25px";
    this.color = "#cfd6ff";
  }

  public static createInstance(jsonData: any): DocumentEvaCompanyApparence {
    const e = Object.assign(new DocumentEvaCompanyApparence(), jsonData);

    return e;
  }
}
export class PredifinedTimes {
  predifined_times_id: string;
  label: string;
  value: Times[] = [];

  constructor() {
    this.label = "";
  }

  public static createInstance(jsonData: any): PredifinedTimes {
    const e = Object.assign(new PredifinedTimes(), jsonData);
    if (jsonData.value) {
      e.value = Times.createInstances(jsonData.value);
    }

    return e;
  }

  public static createInstances(jsonData: PredifinedTimes[]): PredifinedTimes[] {
    return jsonData.map((e) => PredifinedTimes.createInstance(e));
  }
}
export class Times {
  times_id: string;
  start: Date;
  end: Date;
  type: string;
  meal_break_request: boolean;

  constructor() {
    this.meal_break_request = false;
  }

  public static createInstance(jsonData: any): Times {
    const e = Object.assign(new Times(), jsonData);
    e.start = jsonData.start ? new Date(jsonData.start) : null;
    e.end = jsonData.end ? new Date(jsonData.end) : null;

    return e;
  }

  public static createInstances(jsonData: Times[]): Times[] {
    return jsonData.map((e) => Times.createInstance(e));
  }
}

export class GlobalConfigOffer {
  is_show_country_in_address: boolean;
  is_show_project: boolean;
  is_show_customer: boolean;
  is_show_employee: boolean;
  is_show_offer: boolean;
  recap_level: number;
  is_show_signature: boolean;
  is_show_page_number: boolean;

  constructor() {
    this.is_show_country_in_address = false;
    this.is_show_project = true;
    this.is_show_customer = true;
    this.is_show_employee = true;
    this.is_show_offer = true;
    this.recap_level = 4;
    this.is_show_signature = false;
    this.is_show_page_number = true;
  }

  public static createInstance(jsonData: any): GlobalConfigOffer {
    const e = Object.assign(new GlobalConfigOffer(), jsonData);

    return e;
  }
}

export class GlobalConfigInvoice {
  is_show_country_in_address: boolean;
  is_show_project: boolean;
  is_show_employee: boolean;
  is_show_offer: boolean;
  is_show_detailed_offer: boolean;

  constructor() {
    this.is_show_country_in_address = false;
    this.is_show_project = true;
    this.is_show_employee = true;
    this.is_show_offer = true;
    this.is_show_detailed_offer = false;
  }

  public static createInstance(jsonData: any): GlobalConfigInvoice {
    const e = Object.assign(new GlobalConfigInvoice(), jsonData);

    return e;
  }
}

export class OfferSignature {
  name: string;
  display_employee_name: boolean;

  constructor() {
    this.display_employee_name = true;
  }

  public static createInstance(jsonData: any): OfferSignature {
    const e = Object.assign(new OfferSignature(), jsonData);

    return e;
  }
}

export class DocumentEvaCompanyInfo {
  company_infoHTML: string;
  location: string;
  header_list: HTMLEntry[] = [];
  header_invoice_list: HTMLEntry[] = [];
  outro_list: HTMLEntry[] = [];
  outro_invoice_list: HTMLEntry[] = [];
  closing_list: HTMLEntry[] = [];
  closing_invoice_list: HTMLEntry[] = [];
  footer_list: HTMLEntry[] = [];
  annex_list: HTMLEntry[] = [];
  header_selected_id: string;
  header_invoice_selected_id: string;
  outro_selected_id: string;
  outro_invoice_selected_id: string;
  closing_selected_id: string;
  closing_invoice_selected_id: string;
  footer_selected_id: string;
  apparence: DocumentEvaCompanyApparence = new DocumentEvaCompanyApparence();
  global_config_offer: GlobalConfigOffer = new GlobalConfigOffer();
  global_config_invoice: GlobalConfigInvoice = new GlobalConfigInvoice();
  signature: OfferSignature = new OfferSignature();

  public static createInstance(jsonData: any): DocumentEvaCompanyInfo {
    const e = Object.assign(new DocumentEvaCompanyInfo(), jsonData);
    if (jsonData.header_list) {
      e.header_list = HTMLEntry.createInstances(jsonData.header_list);
    }
    if (jsonData.header_invoice_list) {
      e.header_invoice_list = HTMLEntry.createInstances(jsonData.header_invoice_list);
    }
    if (jsonData.outro_list) {
      e.outro_list = HTMLEntry.createInstances(jsonData.outro_list);
    }
    if (jsonData.outro_invoice_list) {
      e.outro_invoice_list = HTMLEntry.createInstances(jsonData.outro_invoice_list);
    }
    if (jsonData.closing_list) {
      e.closing_list = HTMLEntry.createInstances(jsonData.closing_list);
    }
    if (jsonData.closing_invoice_list) {
      e.closing_invoice_list = HTMLEntry.createInstances(jsonData.closing_invoice_list);
    }
    if (jsonData.footer_list) {
      e.footer_list = HTMLEntry.createInstances(jsonData.footer_list);
    }
    if (jsonData.annexes) {
      e.annex_list = HTMLEntry.createInstances(jsonData.annex_list);
    }
    if (jsonData.apparence) {
      e.apparence = DocumentEvaCompanyApparence.createInstance(jsonData.apparence);
    }
    if (jsonData.global_config_offer) {
      e.global_config_offer = GlobalConfigOffer.createInstance(jsonData.global_config_offer);
    }
    if (jsonData.global_config_invoice) {
      e.global_config_invoice = GlobalConfigInvoice.createInstance(jsonData.global_config_invoice);
    }
    if (jsonData.signature) {
      e.signature = OfferSignature.createInstance(jsonData.signature);
    }
    return e;
  }
}

export class EvaCompany {
  eva_company_id: string;
  name: string;
  address: string;
  address_details: EvaAddress = new EvaAddress();
  mobile_phone_details: Phone = new Phone();
  office_phone_details: Phone = new Phone();
  address_lat: number = 0;
  address_lng: number = 0;
  logo: string;
  url: string;
  phone_mobile: string;
  phone_office: string;
  mail: string;
  vat_number: string;
  local_company_id: string;
  employees: [];
  vat_list: VAT[] = [];
  features_enabled: string[] = [];
  employees_order: string[] = [];
  descHTML: string;
  header_companyHTML: string;
  sign: string;
  billing_config: EvaCompanyBillingConfig = new EvaCompanyBillingConfig();
  expense_report_config: EvaCompanyExpenseReportConfig = new EvaCompanyExpenseReportConfig();
  status: EvaCompanyStatus;
  isShowsOffersV2Only: boolean;

  is_meal_break_allowed: boolean;
  meal_break_list: MealBreak[] = [];
  planning_predifined_times: PredifinedTimes[] = [];
  doc_info: DocumentEvaCompanyInfo = new DocumentEvaCompanyInfo();
  intervention_config: InterventionConfig = new InterventionConfig();

  doc_headerHTML: string;
  doc_company_infoHTML: string;
  doc_footerHTML: string;
  primaryCurrency: string;
  country: string;

  offer_intro_default: string;
  offer_outro_default: string;

  name_initials: string;

  audits: EvaCompanyAudit[] = [];
  constructor() {
    this.employees = [];
    this.primaryCurrency = "CHF";
    this.country = "CH";
    this.mobile_phone_details = new Phone();
    this.office_phone_details = new Phone();
  }

  public static createInstance(jsonData: any): EvaCompany {
    const e = Object.assign(new EvaCompany(), jsonData);

    if (jsonData.vat_list) {
      e.vat_list = VAT.createInstances(jsonData.vat_list);
    }
    if (jsonData.meal_break_list) {
      e.meal_break_list = MealBreak.createInstances(jsonData.meal_break_list);
    }
    if (jsonData.planning_predifined_times) {
      e.planning_predifined_times = PredifinedTimes.createInstances(jsonData.planning_predifined_times);
    }
    if (jsonData.audits) {
      e.audits = EvaCompanyAudit.createInstances(jsonData.audits);
    }
    if (jsonData.doc_info) {
      e.doc_info = DocumentEvaCompanyInfo.createInstance(jsonData.doc_info);
    }
    if (jsonData.address_details) {
      e.address_details = EvaAddress.createInstance(jsonData.address_details);
    }
    if (jsonData.mobile_phone_details) {
      e.mobile_phone_details = Phone.createInstance(jsonData.mobile_phone_details);
    }
    if (jsonData.office_phone_details) {
      e.office_phone_details = Phone.createInstance(jsonData.office_phone_details);
    }

    if (!jsonData.billing_config) {
      e.billing_config = new EvaCompanyBillingConfig();
    }
    if (!jsonData.expense_report_config) {
      e.expense_report_config = new EvaCompanyExpenseReportConfig();
    }
    if (jsonData.intervention_config) {
      e.intervention_config = InterventionConfig.createInstance(jsonData.intervention_config);
    } else {
      e.intervention_config = new InterventionConfig();
    }

    return e;
  }

  public static createInstances(jsonData: any[]): EvaCompany[] {
    return jsonData.map(EvaCompany.createInstance);
  }

  clone(): EvaCompany {
    let v = Object.assign(new EvaCompany(), this);
    v.audits = EvaCompanyAudit.createInstances(v.audits);
    return v;
  }

  hasFeature(feature: EvaFeatures): boolean {
    return this.features_enabled.includes(feature);
  }

  addFeature(feature: EvaFeatures) {
    if (!this.hasFeature(feature)) {
      this.features_enabled = [...this.features_enabled, feature];
    }
  }

  removeFeature(feature: EvaFeatures) {
    this.features_enabled = this.features_enabled.filter((x) => x !== feature);
  }
  getCurrentMealBreak() {
    const currentDate = new Date();
    const sortedMealBreaks = [...this.meal_break_list];
    sortedMealBreaks.sort((a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime());
    for (let i = 0; i < sortedMealBreaks.length; i++) {
      const element = sortedMealBreaks[i];
      if (currentDate >= element.start_date) {
        return element;
      }
    }
    return null;
  }
  getDateMealBreak(date = new Date()) {
    let mealBreaksBefore: MealBreak[] = [];
    this.meal_break_list.forEach((mealBreak) => {
      if (new Date(mealBreak.start_date) < date) {
        mealBreaksBefore.push(mealBreak);
      }
    });
    mealBreaksBefore.sort((a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime());
    if (mealBreaksBefore.length > 0)
      return mealBreaksBefore.reduce((prev, curr) =>
        new Date(prev.start_date) > new Date(curr.start_date) ? prev : curr
      );
    return null;
  }

  getFirstVat(): VAT {
    if (this.vat_list.length > 0) {
      return this.vat_list[0];
    }
    return null;
  }
}
