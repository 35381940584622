<div class="offline" *ngIf="!networkStatus">
  <div class="text">
    <p class="title">⚠️ Vous semblez déconnecté d'Internet ⚠️</p>
    <p>Merci de vérifier votre connexion avant de reprendre l'utilisation de EVA !</p>
  </div>
</div>

<router-outlet></router-outlet>

<p-toast class="break-word" key="pdo" [preventOpenDuplicates]="true" [baseZIndex]="99999"></p-toast>

<p-toast></p-toast>
