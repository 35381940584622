import { Asset, Supplier } from "src/app/domains";
import { EvaAddress } from "src/app/domains/address";

import { Customer } from "../../customer/domains/customer";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { Phone } from "src/app/domains/phone";
import { Tag } from "../../eva-company/domains/eva-company-tag";

export enum ContactStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export class Contact {
  contact_id = "";
  contact_corporate_id = "";
  eva_company_id = "";
  salutation = "";
  first_name = "";
  last_name = "";
  job_title = "";
  birthday = "";
  address = new EvaAddress();
  mail = "";
  mail_secondary = "";
  phone_mobile = "";
  phone_pro = "";
  mobile_phone_details: Phone;
  office_phone_details: Phone;
  comment = "";
  language = "FR";
  metadata = {};
  assets: Asset[];
  tags_ids: string[];
  tags: Tag[] = [];

  status: ContactStatus = ContactStatus.ENABLED;

  created_on?: Date;
  created_by?: string;

  updated_on?: Date;
  updated_by?: string;

  customers: Customer[] = [];
  suppliers: Supplier[] = [];

  get display_name() {
    return this.first_name + " " + this.last_name;
  }

  get emailSendTo(): string {
    return `${this.display_name} <${this.mail}>`;
  }

  constructor() {
    this.salutation = "Madame";
  }

  public static createInstance(jsonData: Contact): Contact {
    const c = Object.assign(new Contact(), jsonData);
    if (jsonData.customers) {
      c.customers = Customer.createInstances(jsonData.customers);
    }
    if (jsonData.suppliers) {
      c.suppliers = Supplier.createInstances(jsonData.suppliers);
    }
    if (jsonData.address) {
      c.address = EvaAddress.createInstance(jsonData.address);
    }
    if (jsonData.mobile_phone_details) {
      c.mobile_phone_details = Phone.createInstance(jsonData.mobile_phone_details);
    }
    if (jsonData.office_phone_details) {
      c.office_phone_details = Phone.createInstance(jsonData.office_phone_details);
    }
    if (jsonData.tags) {
      c.tags = Tag.createInstances(jsonData.tags);
    }

    return c;
  }

  public static createInstances(jsonData: any[]): Contact[] {
    return jsonData.map(Contact.createInstance);
  }

  clone(): Contact {
    let s = Object.assign(new Contact(), this);
    return s;
  }

  static get statusLabel() {
    return {
      [ContactStatus.ENABLED]: "Activé",
      [ContactStatus.DISABLED]: "Désactivé",
    };
  }

  excelExport() {
    return {
      id: this.contact_corporate_id,
      first_name: this.first_name,
      last_name: this.last_name,
      phone_office: this.phone_pro,
      phone_mobile: this.phone_mobile,
      mail: this.mail,
      metier: this.job_title,
      status: this.status,
    };
  }
}
