import { PurchaseOrder } from "../features/purchase-order/domains/purchase-order";
import { Supplier } from "../features/supplier/domains/supplier";
import { Invoice } from "./invoice";

export enum CreditNoteObjectType {
  ON_GOING = "ON_GOING",
  FINISHED = "FINISHED",
  CANCELLED = "CANCELLED",
  ALL = "ALL",
}

export class CreditNote {
  credit_note_id: string;
  eva_company_id: string;
  supplier_id: string;
  source_purchase_order_id: string;
  isForPurchaseOrder: boolean;
  name: string;
  gross_price: number;
  remaining_amount: number;
  status: CreditNoteObjectType;
  created_on: Date;
  created_by: string;
  invoices_ids: string[];
  credit_note_asset_name: string;
  credit_note_asset_id: string;
  comment: string;

  // External data
  supplier: Supplier;
  invoices: Invoice[];
  purchase_order: PurchaseOrder;

  constructor() {
    this.invoices_ids = [];
    this.name = "";
    this.gross_price = 0;
    this.credit_note_asset_id = "";
    this.credit_note_asset_name = "";
  }

  public static createInstance(jsonData: any): CreditNote {
    let cn = Object.assign(new CreditNote(), jsonData);

    cn.created_on = jsonData.created_on ? new Date(jsonData.created_on) : null;
    cn.supplier = jsonData.supplier ? Supplier.createInstance(jsonData.supplier) : null;
    cn.invoices = jsonData.invoices ? Invoice.createInstances(jsonData.invoices) : [];
    cn.purchase_order = jsonData.purchase_order ? PurchaseOrder.createInstance(jsonData.purchase_order) : null;

    return cn;
  }

  public static createInstances(jsonData: any[]): CreditNote[] {
    return jsonData.map(CreditNote.createInstance);
  }

  public getCreditNoteInvoices(invoices: Invoice[]) {
    let tmpInvoices = [];

    for (let invoice_id of this.invoices_ids) {
      tmpInvoices.push(invoices.find((x) => x.invoice_id === invoice_id));
    }

    return tmpInvoices;
  }

  public getFormErrors(creditNote: CreditNote): string[] {
    let errors = [];

    if (creditNote.name.trim() === "") {
      errors.push("Le nom de la note de crédit ne doit pas être vide.");
    }
    if (creditNote.gross_price === 0) {
      errors.push("Le montant de la note de crédit doit être supérieur à 0");
    }
    if (!creditNote.gross_price) {
      errors.push("Le montant de la note de crédit ne doit pas être vide");
    }

    return errors;
  }
}
