import { OfferItemGroup } from "src/app/domains";

import { ContractBillingConfig } from "../../customer/domains/contract_billing_config";

export class InterventionConfig {
  billing_config: ContractBillingConfig = new ContractBillingConfig();
  default_contract_quotation: OfferItemGroup = new OfferItemGroup("Facturation du contrat", "BASE");

  public static createInstance(jsonData: InterventionConfig): InterventionConfig {
    const c = Object.assign(new InterventionConfig(), jsonData);

    if (jsonData.billing_config) {
      c.billing_config = ContractBillingConfig.createInstance(jsonData.billing_config);
    }
    if (jsonData.default_contract_quotation) {
      c.default_contract_quotation = OfferItemGroup.createInstance(jsonData.default_contract_quotation);
    } else {
      c.default_contract_quotation = new OfferItemGroup("Facturation du contrat", "BASE");
    }

    return c;
  }

  public static createInstances(jsonData: any[]): InterventionConfig[] {
    return jsonData.map(InterventionConfig.createInstance);
  }
}
