import * as moment from "moment";
// models
import { Employee, EmployeeActivity, EmployeeActivityType, EvaCompany } from "src/app/domains/internal";

import { EvaCompanyService } from "../features/eva-company/services/evaCompany.service";
import { getWeekStartDate } from "../shared/helpers/date.helpers";

export enum EmployeeWeekActivityStatus {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  NO_DATA = "NO_DATA",
  DRAFT = "DRAFT",
  WAITING_VALIDATION = "WAITING_VALIDATION",
}

export enum EmployeeWeekActivityStatusLabel {
  ACCEPTED = "Acceptée",
  REJECTED = "Refusée",
  NO_DATA = "Pas de données",
  DRAFT = "Brouillon",
  WAITING_VALIDATION = "En attente de validation",
}

export class EmployeeWeekActivity {
  employee_week_activity_id: string;
  employee_id: string;
  week: number;
  year: number;
  status: EmployeeWeekActivityStatus;
  comment: string;
  admin_comment: string;
  admin_validator: string;
  activities: EmployeeActivity[];

  employee: Employee;

  dailyActivities: EmployeeActivity[][];
  dailyActivitiesWeekend: EmployeeActivity[][];

  constructor() {
    this.status = EmployeeWeekActivityStatus.DRAFT;
    this.activities = [];
  }

  get editAllowed() {
    return [
      EmployeeWeekActivityStatus.REJECTED,
      EmployeeWeekActivityStatus.NO_DATA,
      EmployeeWeekActivityStatus.DRAFT,
    ].includes(this.status);
  }

  get startDate() {
    return getWeekStartDate(this.year, this.week);
  }

  public static createInstance({
    employee_week_activity_id,
    employee_id,
    week,
    year,
    status,
    comment,
    admin_comment,
    admin_validator,
    activities,
    employee,
  }): EmployeeWeekActivity {
    const e = new EmployeeWeekActivity();
    e.employee_week_activity_id = employee_week_activity_id;
    e.employee_id = employee_id;
    e.week = week;
    e.year = year;
    e.comment = comment;
    e.admin_validator = admin_validator;
    e.admin_comment = admin_comment;
    e.status = status;
    e.activities = [];
    for (const rawActivity of activities) {
      const a = EmployeeActivity.createInstance(rawActivity);
      e.activities.push(a);
    }
    if (employee) {
      e.employee = Employee.createInstance(employee);
    }
    e.dailyActivities = e.getDailyActivities();
    e.dailyActivitiesWeekend = e.getDailyActivitiesWeekend();
    return e;
  }

  public static createInstances(weekActivities: any[]): EmployeeWeekActivity[] {
    return weekActivities.map(EmployeeWeekActivity.createInstance);
  }

  public isEditAllowed(): boolean {
    return ["NO_DATA", "DRAFT", "REJECTED"].includes(this.status);
  }

  get costHours(): number {
    let total = 0.0;
    if (this.activities) {
      total += this.activities
        .filter((x) => x.activity_type === EmployeeActivityType.WORKING)
        .reduce((tot, item) => tot + item.activity_cost, 0);
    }
    return total;
  }

  get cost(): number {
    return this.costHours + this.mealPaybackRequestAmount;
  }

  get mealPaybackRequestCount(): number {
    const activities = this.activities.filter(
      (x) => x.activity_type === EmployeeActivityType.MEAL_BREAK && x.meal_payback_request
    );
    return activities ? activities.length : 0;
  }

  get mealPaybackRequestAmount(): number {
    const totalAmount = this.activities.reduce((amount, activity) => {
      if (activity.activity_type === EmployeeActivityType.MEAL_BREAK && activity.meal_payback_request) {
        amount += activity.meal_payback_amount;
      }
      return amount;
    }, 0);

    return totalAmount;
  }

  get totalDaysWorked(): number {
    return this.totalHoursWorked / 8.25;
  }

  get totalHoursWorked(): number {
    let total = 0.0;
    if (this.activities) {
      total += this.activities
        .filter((x) => [EmployeeActivityType.WORKING, EmployeeActivityType.SCHEDULE].includes(x.activity_type))
        .reduce((tot, item) => tot + item.hours_count, 0);
    }
    return total;
  }

  get totalHoursOff(): number {
    return this.totalDaysOff * 8.25;
  }

  get totalDaysOff(): number {
    let total = 0.0;
    for (const activity of this.activities) {
      if (activity.activity_type === EmployeeActivityType.ABSENCE) {
        const currentDateCompare = new Date(activity.start_date);
        const endCompare = new Date(activity.end_date);

        while (currentDateCompare < endCompare) {
          total += 0.5;
          currentDateCompare.setTime(currentDateCompare.getTime() + 12 * 60 * 60 * 1000);
        }
      }
    }
    return total;
  }

  get weekStartDate(): Date {
    return moment().year(this.year).isoWeek(this.week).startOf("isoWeek").toDate();
  }

  get weekEndDate(): Date {
    return moment().year(this.year).isoWeek(this.week).endOf("isoWeek").toDate();
  }

  public getDailyActivitiesWeekend(): EmployeeActivity[][] {
    let dailyActivities: EmployeeActivity[][] = [];

    for (let i = 0; i < 7; i++) {
      dailyActivities[i] = [];
    }

    try {
      for (let activity of this.activities) {
        if (activity.activity_type === EmployeeActivityType.ABSENCE) {
          // split multiple days activity into 12 hours activities
          let currentDt = new Date(activity.start_date);
          while (currentDt < activity.end_date) {
            let startSplit = new Date(currentDt);
            let endSplit = moment(startSplit).add(12, "hours").toDate();
            if (moment(startSplit).isoWeekday() - 1 < 7) {
              let splitActivity = Object.assign(new EmployeeActivity(), activity);
              splitActivity.start_date = startSplit;
              splitActivity.end_date = endSplit;
              dailyActivities[moment(startSplit).isoWeekday() - 1].push(splitActivity);
            }

            currentDt = new Date(endSplit);
          }
        } else if (
          [EmployeeActivityType.MEAL_BREAK, EmployeeActivityType.SCHEDULE, EmployeeActivityType.WORKING].includes(
            activity.activity_type
          )
        ) {
          dailyActivities[moment(activity.start_date).isoWeekday() - 1].push(activity);
        }
      }
    } catch (ex) {
      console.error(ex);
    }

    for (let i = 0; i < 7; i++) {
      dailyActivities[i] = dailyActivities[i].sort((a, b) => (a.start_date > b.start_date ? 1 : -1));
    }

    return dailyActivities;
  }

  public getDailyActivities(): EmployeeActivity[][] {
    let dailyActivities: EmployeeActivity[][] = [];

    for (let i = 0; i < 5; i++) {
      dailyActivities[i] = [];
    }

    try {
      for (let activity of this.activities) {
        if (activity.activity_type === EmployeeActivityType.ABSENCE) {
          // split multiple days activity into 12 hours activities
          let currentDt = new Date(activity.start_date);
          while (currentDt < activity.end_date) {
            let startSplit = new Date(currentDt);
            let endSplit = moment(startSplit).add(12, "hours").toDate();
            if (moment(startSplit).isoWeekday() - 1 < 5) {
              let splitActivity = Object.assign(new EmployeeActivity(), activity);
              splitActivity.start_date = startSplit;
              splitActivity.end_date = endSplit;
              dailyActivities[moment(startSplit).isoWeekday() - 1].push(splitActivity);
            }

            currentDt = new Date(endSplit);
          }
        } else if (
          [EmployeeActivityType.MEAL_BREAK, EmployeeActivityType.SCHEDULE, EmployeeActivityType.WORKING].includes(
            activity.activity_type
          )
        ) {
          let index = moment(activity.start_date).isoWeekday() - 1;
          if (index < dailyActivities.length) {
            dailyActivities[moment(activity.start_date).isoWeekday() - 1].push(activity);
          }
        }
      }
    } catch (ex) {
      console.error(ex);
    }

    for (let i = 0; i < 5; i++) {
      dailyActivities[i] = dailyActivities[i].sort((a, b) => (a.start_date > b.start_date ? 1 : -1));
    }

    return dailyActivities;
  }
}
