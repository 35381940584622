export class PurchaseOrderCreateDeliveryInput {
  delivery_date: Date;
  employee_id: string;
  delivery_percent: number;
  comment: string;
  attachments_ids: string[];

  public constructor(init?: Partial<PurchaseOrderCreateDeliveryInput>) {
    Object.assign(this, init);
  }
}
