import { round } from "mathjs";
import { OfferUnitType, Supplier } from "src/app/domains/internal";

export interface IOfferSupplierDiscountGoal {
  offer_unit_type: OfferUnitType;
  supplier_id: string;
  supplier_discount_goal_rate: number;
  comment?: string;
  cost_price: number;
  selling_price: number;
  auction_discount: number;

  marginRate: number;
  marginTotal: number;
  sellingAuction: number;
  sellingPriceAuction: number;
  discountGoalTotal: number;
  costPriceWithDiscountGoal: number;

  // sum of purchase orders
  projectedConsumed: number;
  // sum of invoices
  realConsumed: number;

  // external
  supplier?: Supplier;
}

export class OfferSupplierDiscountGoal implements IOfferSupplierDiscountGoal {
  offer_unit_type: OfferUnitType;
  supplier_id: string;
  supplier_discount_goal_rate: number;
  comment?: string;
  cost_price: number;
  selling_price: number;
  sellingPriceAuction: number;
  projectedConsumed: number;
  realConsumed: number;

  // external
  supplier: Supplier;

  constructor(values: any) {
    Object.assign(this, values);
  }

  get marginRate(): number {
    return Math.abs((this.selling_price / this.cost_price) * 100 - 100) || 0;
  }

  get marginTotal(): number {
    return this.selling_price - this.cost_price;
  }

  get sellingAuction(): number {
    return this.selling_price - this.sellingPriceAuction;
  }

  get auction_discount(): number {
    return Math.abs((this.sellingPriceAuction / this.selling_price) * 100 - 100) || 0;
  }

  // get sellingPriceAuction(): number {
  //   return this.selling_price - this.sellingAuction;
  // }

  get discountGoalTotal(): number {
    return (this.sellingPriceAuction * this.supplier_discount_goal_rate) / 100;
  }

  get costPriceWithDiscountGoal(): number {
    return round(this.sellingPriceAuction - this.discountGoalTotal, 10);
  }
}

export class OfferSupplierDiscountGoalStatic implements IOfferSupplierDiscountGoal {
  offer_unit_type: OfferUnitType;
  supplier_id: string;

  comment?: string;
  cost_price: number;
  get marginRate(): number {
    return Math.abs((this.selling_price / this.cost_price) * 100 - 100) || 0;
  }
  marginTotal: number;
  selling_price: number;

  get auction_discount(): number {
    return Math.abs((this.sellingPriceAuction / this.selling_price) * 100 - 100) || 0;
  }
  sellingAuction: number;
  sellingPriceAuction: number;

  get supplier_discount_goal_rate(): number {
    return (1 - this.costPriceWithDiscountGoal / this.sellingPriceAuction) * 100;
  }
  discountGoalTotal: number;
  costPriceWithDiscountGoal: number;
  supplier?: Supplier;
  projectedConsumed: number;
  realConsumed: number;

  constructor(values: any) {
    Object.assign(this, values);
  }
}
