export class Phone {
  countryCode: string;
  dialCode: string;
  e164Number: string;
  internationalNumber: string;
  nationalNumber: string;
  number: string;

  constructor() {}

  public static createInstance(jsonData: any): Phone {
    return Object.assign(new Phone(), jsonData);
  }

  public static createInstances(jsonData: any[]): Phone[] {
    return jsonData.map(Phone.createInstance);
  }
}
