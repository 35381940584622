export class FieldValue {
  field_value_id: string = "";
  intervention_type_field_id: string = "";
  value: string = "";

  public static createInstance(jsonData: any): FieldValue {
    const d = Object.assign(new FieldValue(), jsonData);
    return d;
  }

  public static createInstances(jsonData: any[]): FieldValue[] {
    return jsonData.map(FieldValue.createInstance);
  }

  clone(): FieldValue {
    let d = Object.assign(new FieldValue(), this);
    return d;
  }

  public static multiClone(fieldValues: FieldValue[]): FieldValue[] {
    return fieldValues.map((fieldValue) => fieldValue.clone());
  }
}
