export function setInLocalStorage(value, key, eva_company_id = "", additionalKeys = []) {
  let finalKey = createKey(key, eva_company_id, additionalKeys);
  localStorage.setItem(finalKey, JSON.stringify(value));
}

export function getFromLocalStorage(key, eva_company_id = "", additionalKeys = []) {
  let finalKey = createKey(key, eva_company_id, additionalKeys);
  let storedData = localStorage.getItem(finalKey);
  return storedData ? JSON.parse(storedData) : null;
}

export function setInSessionStorage(value, key, eva_company_id = "", additionalKeys = []) {
  let finalKey = createKey(key, eva_company_id, additionalKeys);
  sessionStorage.setItem(finalKey, JSON.stringify(value));
}

export function getFromSessionStorage(key, eva_company_id = "", additionalKeys = []) {
  let finalKey = createKey(key, eva_company_id, additionalKeys);
  let storedData = sessionStorage.getItem(finalKey);
  return storedData ? JSON.parse(storedData) : null;
}

function createKey(key, eva_company_id = "", additionalKeys = []) {
  let finalKey = "";
  if (eva_company_id) {
    finalKey = eva_company_id + "-";
  }
  finalKey += key + "-" + additionalKeys.join("-");
  return finalKey;
}
