export class ContractBillingConfig {
  anticipated = false;
  anticipated_frequency = 12;
  anticipated_start_date = null;

  public static createInstance(jsonData: any): ContractBillingConfig {
    const d = Object.assign(new ContractBillingConfig(), jsonData);
    if (d.anticipated_start_date) {
      d.anticipated_start_date = new Date(d.anticipated_start_date);
    }
    return d;
  }

  public static createInstances(jsonData: any[]): ContractBillingConfig[] {
    return jsonData.map(ContractBillingConfig.createInstance);
  }

  clone(): ContractBillingConfig {
    let d = Object.assign(new ContractBillingConfig(), this);
    return d;
  }
}
