import { Intercom } from "ng-intercom";
import { Observable } from "rxjs";
import { EvaService } from "src/app/core";
import { Employee, EvaCompany, EvaFeatures, User } from "src/app/domains/internal";
import { EmployeesService } from "src/app/features/employees/services/employees.service";
import { EvaCompanyService } from "src/app/features/eva-company/services/evaCompany.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { getDateFromObjectId } from "src/app/shared/helpers/date.helpers";
import { environment } from "src/environments/environment";

import { Location } from "@angular/common";
import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const EVA_ADMIN_ROUTES: RouteInfo[] = [
  {
    path: "/superadmin/eva-company",
    title: "Sociétés",
    icon: "ni-building text-primary",
    class: "",
  },
  {
    path: "/eva/admin/users",
    title: "Utilisateurs",
    icon: "ni-circle-08 text-primary",
    class: "",
  },
  {
    path: "/superadmin/catalog",
    title: "Catalogues",
    icon: "ni-collection text-primary",
    class: "",
  },
  {
    path: "/superadmin/eva-tasks",
    title: "Eva tasks",
    icon: "ni-check-bold text-primary",
    class: "",
  },
];
export const ADMIN_ROUTES: RouteInfo[] = [
  {
    path: "/admin/working-activities",
    title: "Temps de travail",
    icon: "ni-watch-time text-primary",
    class: "",
  },
  {
    path: "/projects",
    title: "Chantiers",
    icon: "ni-settings text-primary",
    class: "",
  },
  {
    path: "/contacts",
    title: "Contacts",
    icon: "ni-circle-08 text-pink",
    class: "",
  },
  {
    path: "/suppliers",
    title: "Fournisseurs",
    icon: "ni-basket text-primary",
    class: "",
  },
  {
    path: "/customers",
    title: "Clients",
    icon: "ni-building text-primary",
    class: "",
  },
  {
    path: "/offers",
    title: "Offres",
    icon: "ni-archive-2 text-primary",
    class: "",
  },

  {
    path: "/purchase-orders",
    title: "Bons de commandes",
    icon: "ni-planet text-blue",
    class: "",
  },
  {
    path: "/employees",
    title: "Collaborateurs",
    icon: "ni-circle-08 text-pink",
    class: "",
  },
  {
    path: "/workshops",
    title: "Ateliers",
    icon: "ni-building text-primary",
    class: "",
  },
  {
    path: "/invoices/received",
    title: "Factures reçues",
    icon: "ni-bullet-list-67 text-primary",
    class: "",
  },
  {
    path: "/invoices/issued",
    title: "Factures émises",
    icon: "ni-bullet-list-67 text-primary",
    class: "",
  },
  {
    path: "/admin/team-planning",
    title: "Planning",
    icon: "ni-calendar-grid-58 text-primary",
    class: "",
  },
  {
    path: "/stats",
    title: "Statistiques",
    icon: "ni-chart-pie-35 text-primary",
    class: "",
  },
  {
    path: "/budget",
    title: "Budget",
    icon: "ni-money-coins text-primary",
    class: "",
  },
  {
    path: "admin/eva-company",
    title: "Société",
    icon: "ni ni-building text-primary",
    class: "",
  },
];

export const OFFICE_ROUTES: RouteInfo[] = [
  {
    path: "/projects",
    title: "Chantiers",
    icon: "ni-settings text-primary",
    class: "",
  },
  {
    path: "/contacts",
    title: "Contacts",
    icon: "ni-circle-08 text-pink",
    class: "",
  },
  {
    path: "/suppliers",
    title: "Fournisseurs",
    icon: "ni-basket text-primary",
    class: "",
  },
  {
    path: "/customers",
    title: "Clients",
    icon: "ni-building text-primary",
    class: "",
  },
  {
    path: "/offers",
    title: "Offres",
    icon: "ni-archive-2 text-primary",
    class: "",
  },

  {
    path: "/purchase-orders",
    title: "Bons de commandes",
    icon: "ni-planet text-blue",
    class: "",
  },
  {
    path: "/workshops",
    title: "Ateliers",
    icon: "ni-building text-primary",
    class: "",
  },
  {
    path: "/admin/team-planning",
    title: "Planning",
    icon: "ni-calendar-grid-58 text-primary",
    class: "",
  },
];

export const ACCOUNTANT_ROUTES: RouteInfo[] = [
  {
    path: "/invoices/received",
    title: "Factures reçues",
    icon: "ni-bullet-list-67 text-primary",
    class: "",
  },
  {
    path: "/invoices/issued",
    title: "Factures émises",
    icon: "ni-bullet-list-67 text-primary",
    class: "",
  },
  {
    path: "/expenses-report",
    title: "Notes de frais",
    icon: "ni-archive-2 text-primary",
    class: "",
  },
];

export const EMPLOYEE_ROUTES: RouteInfo[] = [
  {
    path: "/employee/working-activity",
    title: "Mon temps de travail",
    icon: "ni-settings text-primary",
    class: "",
  },
  {
    path: "/employee/projects",
    title: "Mes chantiers",
    icon: "ni-basket text-primary",
    class: "",
  },
  {
    path: "/employee/purchase-orders",
    title: "Mes bons de commande",
    icon: "ni-basket text-primary",
    class: "",
  },
  {
    path: "/employee/offers",
    title: "Mes offres",
    icon: "ni-archive-2 text-primary",
    class: "",
  },

  {
    path: "/employee/my-planning",
    title: "Mon Planning",
    icon: "ni-calendar-grid-58 text-primary",
    class: "",
  },
  {
    path: "/employee/my-team",
    title: "Mon équipe",
    icon: "ni-single-02 text-primary",
    class: "",
  },
];

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  public currentUser: User = new User();
  public adminMenuItems: any[];
  public adminLightMenuItems: any[];
  public officeMenuItems: any[];
  public accountantMenuItems: any[];
  public evaAdminMenuItems: any[];
  public employeeMenuItems: any[];
  public isCollapsed = true;
  public focus;
  public listTitles: any[];
  public location: Location;

  currentEmployee$: Observable<Employee> = this.employeesService.getCurrentEmployee();

  public showSearch = false;

  public currentEvaCompany: EvaCompany = null;
  public currentEvaCompanyId: string = null;

  public currentEvaCompaniesAvailable: EvaCompany[] = [];

  public get showGlobalLoader(): boolean {
    return this.evaService.showGlobalLoader;
  }

  public get isMobile() {
    if (window.matchMedia("(max-width: 767px)").matches) return true;
    else return false;
  }

  constructor(
    location: Location,
    private router: Router,
    public authenticationService: AuthenticationService,
    public evaCompanyService: EvaCompanyService,
    private employeesService: EmployeesService,
    private evaService: EvaService,
    public intercom: Intercom
  ) {
    this.authenticationService.currentUser.subscribe((x) => (this.currentUser = x));
    this.currentEvaCompany = this.evaCompanyService.currentEvaCompany;
    this.currentEvaCompanyId = this.currentEvaCompany.eva_company_id;
    this.location = location;
    this.currentEvaCompaniesAvailable = this.evaCompanyService.getCurrentEvaCompaniesAvailable();
  }

  get menuClassName(): string {
    return !this.isCollapsed ? "show" : "";
  }

  ngOnInit() {
    if (!this.authenticationService.currentUserValue) {
      this.router.navigate(["login"]);
    }
    this.evaAdminMenuItems = EVA_ADMIN_ROUTES.filter((menuItem) => menuItem);

    this.evaCompanyService.currentEvaEmployee.employee_position_current.admin_allow_stats
      ? (this.adminMenuItems = ADMIN_ROUTES.filter((menuItem) => menuItem))
      : (this.adminMenuItems = ADMIN_ROUTES.filter((menuItem) => menuItem.title != "Statistiques"));

    this.officeMenuItems = OFFICE_ROUTES.filter((menuItem) => menuItem);
    this.accountantMenuItems = ACCOUNTANT_ROUTES.filter((menuItem) => menuItem);
    this.employeeMenuItems = EMPLOYEE_ROUTES.filter((menuItem) => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
    this.currentEvaCompaniesAvailable = this.evaCompanyService.getCurrentEvaCompaniesAvailable();
    this.evaCompanyService.load();

    if (this.evaCompanyService.getCurrentEvaEmployee().is_employed) {
      this.employeeMenuItems.push({
        path: "/employee/my-hr-space",
        title: "Mon espace RH",
        icon: "ni-satisfied text-primary",
        class: "",
      });
    }
    if (
      [
        "5e9e2215356088ad932381a4",
        "5e9e2215356088ad932381a5",
        "5e9e2215356088ad932381a8",
        "658012d4517a544a7fcd9aa6",
        "658012be517a544a7fcd9a7d",
      ].includes(this.evaCompanyService.currentEvaCompany.eva_company_id)
    ) {
      this.employeeMenuItems.push({
        path: "/employee/my-group",
        title: "Planning THERMO",
        icon: "ni-calendar-grid-58 text-primary",
        class: "",
      });
    }

    if (this.evaCompanyService.currentEvaCompany.hasFeature(EvaFeatures.MAINTENANCE_CONTRACT)) {
      this.employeeMenuItems.push({
        path: "/employee/interventions",
        title: "Mes interventions",
        icon: "ni-briefcase-24 text-primary",
        class: "",
      });
      this.officeMenuItems.push({
        path: "/interventions",
        title: "Interventions",
        icon: "ni-briefcase-24 text-primary",
        class: "",
      });
      this.adminMenuItems.push({
        path: "/interventions",
        title: "Interventions",
        icon: "ni-briefcase-24 text-primary",
        class: "",
      });
    }

    if (this.evaCompanyService.currentEvaCompany.hasFeature(EvaFeatures.EXPENSE_REPORT)) {
      this.employeeMenuItems.push({
        path: "/employee/expenses-report",
        title: "Mes notes de frais",
        icon: "ni-archive-2 text-primary",
        class: "",
      });
    }

    if (this.evaCompanyService.currentEvaCompany.hasFeature(EvaFeatures.SCAN_EMAIL)) {
      this.adminMenuItems.push({
        path: "/scan-emails",
        title: "Scan Emails",
        icon: "ni-email-83 text-primary",
        class: "",
      });
    }

    if (
      this.evaCompanyService.currentEvaCompany.hasFeature(EvaFeatures.EXPENSE_REPORT) &&
      this.evaCompanyService.currentEvaEmployee.employee_position_current.admin_allow_expense_reports
    ) {
      this.adminMenuItems.push({
        path: "/expenses-report",
        title: "Notes de frais",
        icon: "ni-archive-2 text-primary",
        class: "",
      });
    }
    if (this.evaCompanyService.currentEvaCompany.hasFeature(EvaFeatures.CATALOG)) {
      this.adminMenuItems.push({
        path: "/catalog",
        title: "Catalogue",
        icon: "ni-collection text-primary",
        class: "",
      });
    }
    if (this.evaCompanyService.currentEvaCompany.hasFeature(EvaFeatures.BANKING)) {
      this.adminMenuItems.push({
        path: "/admin/banking",
        title: "Banking",
        icon: "ni-money-coins text-primary",
        class: "",
      });
    }

    try {
      this.currentEmployee$.subscribe((employee) => {
        if (employee) {
          // add intercom info
          this.intercom.boot({
            app_id: environment.intercomId, // from your Intercom config
            email: employee.email,
            user_id: employee.email,
            created_at: getDateFromObjectId(employee.employee_id),
            name: employee.display_name,
            user_hash: employee.user_hash,
            phone: employee.phone ? employee.phone : employee.phone_personnal,
            web_version: environment.appVersion,
            company: {
              id: this.evaCompanyService.currentEvaCompany.eva_company_id,
              name: this.evaCompanyService.currentEvaCompany.name,
              website: this.evaCompanyService.currentEvaCompany.url,
            },
            employee_type: employee.employee_type,
            hide_default_launcher: this.isMobile,
            widget: {
              activator: "#intercom",
            },
          });

          // add sentry info
          Sentry.setUser({
            email: employee.email,
            id: employee.user_id,
            username: employee.display_name,
          });
          Sentry.setTag("eva_company_id", this.evaCompanyService.currentEvaCompany.eva_company_id);
          Sentry.setTag("eva_company_name", this.evaCompanyService.currentEvaCompany.name);
          Sentry.setTag("employee_type", employee.employee_type);
        }
      });
    } catch (ex) {
      console.error(ex);
    }

    try {
    } catch (ex) {
      console.error(ex);
    }
  }

  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (let item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  public logout(): void {
    this.authenticationService.logout();
    this.router.navigate(["/login"]);
  }

  refreshCompanies() {
    this.evaCompanyService.load();
    this.currentEvaCompaniesAvailable = this.evaCompanyService.getCurrentEvaCompaniesAvailable();

    this.evaService.showSuccess("Entreprises rechargées !");
  }

  public changeEvaCompany(): void {
    this.currentEvaCompany = this.currentEvaCompaniesAvailable.find(
      (company) => company.eva_company_id === this.currentEvaCompanyId
    );
    this.evaCompanyService.changeCompany(this.currentEvaCompany.eva_company_id);
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    if (window.innerWidth > 768) {
      this.isCollapsed = true;
    }
  }
}
