import { InvoiceType } from "src/app/domains";
import { SearchItem, SearchObjectType } from "src/app/domains/searchItem";

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "eva-search-result",
  templateUrl: "./eva-search-result.component.html",
  styleUrls: ["./eva-search-result.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvaSearchResultComponent {
  @Input() loading = false;
  @Input() searchItems: SearchItem[] = [];
  @Output() hideOverlay: EventEmitter<any> = new EventEmitter<any>();

  public get resultOffers(): SearchItem[] {
    return this.searchItems.filter((x) => x.object_category === SearchObjectType.OFFER);
  }
  public get resultCustomers(): SearchItem[] {
    return this.searchItems.filter((x) => x.object_category === SearchObjectType.CUSTOMER);
  }
  public get resultPurchaseOrders(): SearchItem[] {
    return this.searchItems.filter((x) => x.object_category === SearchObjectType.PURCHASE_ORDER);
  }
  public get resultProjects(): SearchItem[] {
    return this.searchItems.filter((x) => x.object_category === SearchObjectType.PROJECT);
  }
  public get resultInvoicesReceived(): SearchItem[] {
    return this.searchItems.filter((x) => x.object_category === SearchObjectType.INVOICE_RECEIVED);
  }
  public get resultInvoicesIssued(): SearchItem[] {
    return this.searchItems.filter((x) => x.object_category === SearchObjectType.INVOICE_ISSUED);
  }
  public get resultSuppliers(): SearchItem[] {
    return this.searchItems.filter((x) => x.object_category === SearchObjectType.SUPPLIER);
  }
  public get resultEmployees(): SearchItem[] {
    return this.searchItems.filter((x) => x.object_category === SearchObjectType.EMPLOYEE);
  }
  public get resultWorkshops(): SearchItem[] {
    return this.searchItems.filter((x) => x.object_category === SearchObjectType.WORKSHOP);
  }

  public get resultContacts(): SearchItem[] {
    return this.searchItems.filter((x) => x.object_category === SearchObjectType.CONTACT);
  }

  public get resultInterventions(): SearchItem[] {
    return this.searchItems.filter((x) => x.object_category === SearchObjectType.INTERVENTION);
  }
  public get resultContracts(): SearchItem[] {
    return this.searchItems.filter((x) => x.object_category === SearchObjectType.CONTRACT);
  }

  hideOverlayByClick() {
    this.hideOverlay.emit();
  }
}
