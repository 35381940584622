export enum OfferDiscountType {
  "PERCENT" = "PERCENT",
  "AMOUNT" = "AMOUNT",
}

export class IOfferDiscount {
  public name: string;
  public discount: number;
  public discount_total?: number;
  public new_price?: number;
  public discount_type: OfferDiscountType;
}

export class OfferDiscount implements IOfferDiscount {
  public name: string;
  public discount: number;
  public discount_total: number;
  public new_price?: number;
  public discount_type: OfferDiscountType;

  constructor(values: IOfferDiscount, currentPrice: number) {
    Object.assign(this, values);
    if (this.discount_type === null) {
      this.discount_type = OfferDiscountType.PERCENT;
    }
    if (this.discount_type === OfferDiscountType.PERCENT) {
      if (this.discount_total == 0) {
        this.discount_total = (currentPrice * this.discount) / 100;
      }
    } else {
      if (this.discount == 0) {
        this.discount = (this.discount / currentPrice) * 100;
      }
    }
    this.new_price = currentPrice - this.discount_total;
  }
}
