import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "./helpers/auth.guard";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { PublicLayoutComponent } from "./layouts/public-layout/public-layout.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
    runGuardsAndResolvers: "always",
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("./layouts/auth-layout/auth-layout.module").then((m) => m.AuthLayoutModule),
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () => import("./layouts/admin-layout/admin-layout.module").then((m) => m.AdminLayoutModule),
      },
    ],
  },
  {
    path: "",
    component: PublicLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("./layouts/public-layout/public-layout.module").then((m) => m.PublicLayoutModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: "legacy",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [],
})
export class AppRoutingModule {}
