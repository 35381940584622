<nav
  class="navbar navbar-top navbar-expand-md navbar-light bg-light"
  id="sidenav-main"
  [style]="{ 'flex-direction': 'column' }"
>
  <div class="container-fluid">
    <!-- Brand -->
    <a class="navbar-brand pt-0" routerLinkActive="active" [routerLink]="['/home']">
      <img src="./assets/img/brand/logo.png" class="navbar-brand-img" alt="..." />
    </a>

    <i class="pi pi-search mobileShow" (click)="showSearch = !showSearch" style="font-size: 2rem"></i>

    <!-- Collapse -->
    <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
      <!-- Collapse header -->
      <div class="navbar-collapse-header d-md-none">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a class="navbar-brand pt-0" routerLinkActive="active" [routerLink]="['/home']">
              <img src="./assets/img/brand/logo.png" class="navbar-brand-img" alt="..." />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button type="button" class="navbar-toggler" (click)="isCollapsed = !isCollapsed">
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>

      <!-- Navigation -->
      <ul
        class="navbar-nav d-md-flex"
        *ngIf="this.evaCompanyService.getCurrentEvaEmployee().employee_type !== 'ACCOUNTANT'"
      >
        <li class="nav-item" ngbDropdown>
          <a class="nav-link pr-0" role="button" ngbDropdownToggle>
            <div class="media align-items-center">
              <div class="media-body ml-2 d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">MON ACTIVITÉ</span>
              </div>
            </div>
          </a>
          <div id="activite" class="dropdown-menu-arrow" ngbDropdownMenu [ngClass]="menuClassName">
            <a *ngFor="let menuItem of employeeMenuItems" [routerLink]="[menuItem.path]" class="dropdown-item">
              <i class="ni {{ menuItem.icon }}"></i>
              <span>{{ menuItem.title }}</span>
            </a>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav d-md-flex" *ngIf="this.evaCompanyService.getCurrentEvaEmployee().employee_type === 'ADMIN'">
        <li class="nav-item" ngbDropdown>
          <a class="nav-link pr-0" role="button" ngbDropdownToggle>
            <div class="media align-items-center">
              <div class="media-body ml-2 d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">ADMINISTRATION</span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu-arrow" ngbDropdownMenu [ngClass]="menuClassName">
            <a *ngFor="let menuItem of adminMenuItems" [routerLink]="[menuItem.path]" class="dropdown-item">
              <i class="ni {{ menuItem.icon }}"></i>
              <span>{{ menuItem.title }}</span>
            </a>
          </div>
        </li>
      </ul>

      <ul
        class="navbar-nav d-md-flex"
        *ngIf="this.evaCompanyService.getCurrentEvaEmployee().employee_type === 'OFFICE'"
      >
        <li class="nav-item" ngbDropdown>
          <a class="nav-link pr-0" role="button" ngbDropdownToggle>
            <div class="media align-items-center">
              <div class="media-body ml-2 d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">ADMINISTRATION</span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu-arrow" ngbDropdownMenu [ngClass]="menuClassName">
            <a *ngFor="let menuItem of officeMenuItems" [routerLink]="[menuItem.path]" class="dropdown-item">
              <i class="ni {{ menuItem.icon }}"></i>
              <span>{{ menuItem.title }}</span>
            </a>
          </div>
        </li>
      </ul>

      <ul
        class="navbar-nav d-md-flex"
        *ngIf="this.evaCompanyService.getCurrentEvaEmployee().employee_type === 'ACCOUNTANT'"
      >
        <li class="nav-item" ngbDropdown>
          <a class="nav-link pr-0" role="button" ngbDropdownToggle>
            <div class="media align-items-center">
              <div class="media-body ml-2 d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">ADMINISTRATION</span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu-arrow" ngbDropdownMenu [ngClass]="menuClassName">
            <a *ngFor="let menuItem of accountantMenuItems" [routerLink]="[menuItem.path]" class="dropdown-item">
              <i class="ni {{ menuItem.icon }}"></i>
              <span>{{ menuItem.title }}</span>
            </a>
          </div>
        </li>
      </ul>

      <ul class="navbar-nav d-md-flex" *ngIf="this.authenticationService.getCurrentUser()?.user_type === 'ADMIN'">
        <li class="nav-item" ngbDropdown>
          <a class="nav-link pr-0" role="button" ngbDropdownToggle>
            <div class="media align-items-center">
              <div class="media-body ml-2 d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">ADMINISTRATION EVA</span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu-arrow" ngbDropdownMenu [ngClass]="menuClassName">
            <a *ngFor="let menuItem of evaAdminMenuItems" [routerLink]="[menuItem.path]" class="dropdown-item">
              <i class="ni {{ menuItem.icon }}"></i>
              <span>{{ menuItem.title }}</span>
            </a>
          </div>
        </li>
      </ul>

      <!-- User -->
      <ul class="navbar-nav d-md-flex" *ngIf="!isCollapsed">
        <li class="nav-item show dropdown" ngbDropdown>
          <a class="nav-link pr-0" role="button" ngbDropdownToggle>
            <div class="media align-items-center">
              <!-- <span class="avatar avatar-sm rounded-circle">
                <img
                  alt="Image placeholder"
                  src="assets/img/theme/team-1-800x800.jpg"
                />
              </span> -->
              <div class="media-body ml-2 d-lg-block">
                <span class="mb-0 text-sm font-weight-bold"
                  >{{ this.authenticationService.currentUserValue?.first_name }}
                  {{ this.authenticationService.currentUserValue?.last_name | uppercase }}
                </span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu show" ngbDropdownMenu>
            <!-- <a [routerLink]="['/user-profile']" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>Mon Profil</span>
            </a> -->
            <a class="dropdown-item" (click)="logout()">
              <i class="ni ni-user-run"></i>
              <span>Déconnexion</span>
            </a>
          </div>
        </li>
      </ul>
    </div>

    <!-- Search for DESKTOP -->

    <eva-search-bar class="desktopShow"></eva-search-bar>

    <ul style="margin-left: 15px" class="navbar-nav d-md-flex" *ngIf="this.currentEvaCompaniesAvailable.length > 0">
      <div class="row">
        <p-dropdown
          filter="true"
          [disabled]="this.currentEvaCompaniesAvailable.length === 1"
          [options]="this.currentEvaCompaniesAvailable"
          [(ngModel)]="this.currentEvaCompanyId"
          optionLabel="name"
          optionValue="eva_company_id"
          (onChange)="this.changeEvaCompany()"
        ></p-dropdown>

        <span
          *ngIf="this.evaCompanyService.hasSuperAdminRights()"
          pTooltip="Recharger la liste des entreprises"
          (click)="refreshCompanies()"
          class="btn btn-sm btn-primary pi pi-refresh"
        >
        </span>
      </div>
    </ul>

    <!-- User -->
    <ul style="margin-left: 15px" class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link pr-0" role="button" ngbDropdownToggle>
          <div class="media align-items-center">
            <!-- <span class="avatar avatar-sm rounded-circle">
              <img
                alt="Image placeholder"
                src="assets/img/theme/team-1-800x800.jpg"
              />
            </span> -->
            <div>
              <span class="mb-0 text-sm font-weight-bold"
                >{{ this.authenticationService.currentUserValue.first_name }}
                {{ this.authenticationService.currentUserValue.last_name | uppercase }}
              </span>
            </div>
          </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Bonjour!</h6>
          </div>
          <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>Mon Profil</span>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="logout()">
            <i class="ni ni-user-run"></i>
            <span>Déconnexion</span>
          </a>
        </div>
      </li>
    </ul>

    <!-- Toggler -->
    <button
      class="navbar-toggler"
      type="button"
      (click)="isCollapsed = !isCollapsed"
      aria-controls="sidenav-collapse-main"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>
  <p-progressBar
    *ngIf="showGlobalLoader && !isMobile"
    mode="indeterminate"
    [style]="{ height: '4px', width: '100% !important' }"
  ></p-progressBar>
</nav>

<p-progressBar
  class="mobileShow"
  *ngIf="showGlobalLoader && isMobile"
  mode="indeterminate"
  [style]="{ height: '4px', width: '100% !important' }"
></p-progressBar>

<!-- Search for MOBILE -->
<eva-search-bar *ngIf="showSearch" [forceFocus]="showSearch" class="mobileShow"> </eva-search-bar>
