import { Contact } from "../features/contact/domains/contact";

export enum SupplierCallToTenderStatus {
  ON_GOING = "ON_GOING",
  CANCELLED = "CANCELLED",
  ANSWERED = "ANSWERED",
  FINISHED = "FINISHED",
}

export enum SupplierCallToTenderStatusBadgeClass {
  ON_GOING = "badge badge-warning",
  CANCELLED = "badge badge-danger",
  ANSWERED = "badge badge-success",
  FINISHED = "badge badge-success",
}

export enum SupplierCallToTenderStatusLabel {
  ON_GOING = "EN ATTENTE DE REPONSE",
  CANCELLED = "REFUSÉ",
  ANSWERED = "REPONDU",
  FINISHED = "TERMINÉ",
}

export class SupplierCallToTender {
  supplier_call_to_tender_id: string;
  supplier_id: string;
  contacts_ids: string[];

  status: SupplierCallToTenderStatus;

  answer: string;
  total: number;
  answer_attachments_ids: string[];

  answered_on: Date;
  answered_by_names: string;

  cancelled_on: Date;
  cancelled_by_names: string;
  cancelled_comment: string;

  // external
  contacts = [];

  public static createInstance(jsonData: any): SupplierCallToTender {
    const sctt = Object.assign(new SupplierCallToTender(), jsonData);
    sctt.answered_on = jsonData.answered_on ? new Date(jsonData.answered_on) : null;
    sctt.cancelled_on = jsonData.cancelled_on ? new Date(jsonData.cancelled_on) : null;
    sctt.contacts = sctt.contacts?.map(Contact.createInstance);
    return sctt;
  }

  public static createInstances(jsonData: any[]): SupplierCallToTender[] {
    return jsonData.map(SupplierCallToTender.createInstance);
  }
}
