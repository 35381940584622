import { Offer, ProjectSituation } from "src/app/domains/internal";

import { Tag } from "../features/eva-company/domains/eva-company-tag";
import {
  PurchaseOrder,
  PurchaseOrderStatus,
  PurchaseOrderType,
  PurchaseOrderWorkForceType,
} from "../features/purchase-order/domains/purchase-order";
import { EmployeeActivityType, Invoice, InvoiceStatus, OfferUnitType } from "./";
import { Asset } from "./asset";
import { EmployeeWeekActivity, EmployeeWeekActivityStatus } from "./employee-week-activity";
import { OfferStatus } from "./offer";

export enum ProjectStatus {
  ON_GOING = "ON_GOING",
  TERMINATED = "TERMINATED",
  CANCELLED = "CANCELLED",
  WAITING = "WAITING",
}

export enum ProjectStatusLabel {
  ON_GOING = "En Cours",
  WAITING = "En Attente",
  TERMINATED = "Terminé",
  CANCELLED = "Abandonné",
}

export class Project {
  project_id: string;
  project_corporate_id: string;

  eva_company_id: string;
  name: string = "";
  address: string;
  address_lat: number;
  address_lng: number;
  desc: string = "";
  description: string = "";
  internal_contact: string = "";
  external_contact: string = "";
  allow_time_input: boolean = true;
  color: string;
  status: ProjectStatus;

  start_date: Date;
  end_date: Date;
  comment: string;


  allow_interventions: boolean = false;

  situations: ProjectSituation[];

  week_activities: EmployeeWeekActivity[];

  allowed_employees_id: string[] = [];

  project_managers_id: string[] = [];
  workforce_hourly_cost: number = 0.0;
  workforce_goal_margin: number = 0.0;
  workforce_days_worked: number = 0.0;
  workforce_total_days: number = 0.0;

  //semi-external data
  renew_project_name = "";

  // external Data
  offers: Offer[];
  purchase_orders: PurchaseOrder[];
  employee_week_activities: EmployeeWeekActivity[];

  projected_cost = 0;
  projected_cx = 0;
  real_cost = 0;
  real_cx = 0;
  assets: Asset[];
  tags_ids: string[];
  tags: Tag[] = [];
  received_direct_invoices: Invoice[] = [];

  get excelExport() {
    return {
      id: this.project_corporate_id,
      name: this.name,
      address: this.address,
      desc: this.desc,
      internal_contact: this.internal_contact,
      external_contact: this.external_contact,
      status: this.status,
    };
  }

  // BONS DE COMMANDE EN COURS
  get pos_on_going(): PurchaseOrder[] {
    if (!this.purchase_orders) return [];
    return this.purchase_orders.filter((x) => x.status === PurchaseOrderStatus.ON_GOING);
  }
  get pos_on_going_count(): number {
    return this.pos_on_going.length;
  }
  get pos_on_going_value(): number {
    return this.pos_on_going.reduce((tot, item) => tot + +item.confirmation_net_price, 0);
  }

  // BONS DE COMMANDE TERMINES
  get pos_finished(): PurchaseOrder[] {
    if (!this.purchase_orders) return [];
    return this.purchase_orders.filter((x) => x.status === PurchaseOrderStatus.FINISHED);
  }
  get pos_finished_count(): number {
    return this.pos_finished.length;
  }
  get pos_finished_value(): number {
    return this.pos_finished.reduce((tot, item) => tot + +item.confirmation_net_price, 0);
  }

  // OFFRES EN COURS
  get offers_on_going(): Offer[] {
    if (!this.offers) return [];
    return this.offers.filter((x) => x.status === OfferStatus.ON_GOING);
  }
  get offers_on_going_count(): number {
    return this.offers_on_going.length;
  }
  get offers_on_going_value(): number {
    return this.offers_on_going.reduce((tot, item) => tot + +item.totalNet(), 0);
  }

  // OFFRES VALIDEES
  get offers_validated(): Offer[] {
    if (!this.offers) return [];
    return this.offers.filter((x) => x.status === OfferStatus.VALIDATED);
  }
  get offers_validated_count(): number {
    return this.offers_validated.length;
  }
  get offers_validated_value(): number {
    return this.offers_validated.reduce((tot, item) => tot + +item.totalNet(), 0);
  }

  // FACTURES EN COURS
  get invoices_on_going(): Invoice[] {
    let invoices: Invoice[] = [];
    if (!this.offers) return invoices;
    for (let o of this.offers) {
      if (o.invoices) {
        invoices = invoices.concat(
          o.invoices.filter((x) => [InvoiceStatus.VALIDATED, InvoiceStatus.SENT, InvoiceStatus.PAID].includes(x.status))
        );
      }
    }
    return invoices;
  }
  get invoices_on_going_rate(): number {
    return (this.invoices_on_going_value / this.offers_validated_value) * 100 || 0;
  }
  get invoices_on_going_value(): number {
    return this.invoices_on_going.reduce((tot, item) => tot + +item.netTotalPrice, 0);
  }

  // FACTURES EN ATTENTE DE PAIEMENT
  get invoices_waiting_payment(): number {
    return this.invoices_on_going_value - this.invoices_paid_value;
  }

  // FACTURES PAYEES
  get invoices_paid(): Invoice[] {
    let invoices: Invoice[] = [];
    if (!this.offers) return invoices;
    for (let o of this.offers) {
      if (o.invoices) {
        invoices = invoices.concat(o.invoices.filter((x) => x.status === InvoiceStatus.PAID));
      }
    }
    return invoices;
  }
  get invoices_paid_rate(): number {
    return (this.invoices_paid_value / this.offers_validated_value) * 100 || 0;
  }
  get invoices_paid_value(): number {
    return this.invoices_paid.reduce((tot, item) => tot + +item.netTotalPrice, 0);
  }

  // COEF
  get margin_base_global(): number {
    let margin = this.margin_base_net();
    return margin ? margin : 0;
  }
  get margin_goal_global(): number {
    let margin = this.margin_goal_net();
    return margin ? margin : 0;
  }
  public get margin_global_projected(): number {
    let total = this.totalNet();

    let materialProjected = this.validPurchaseOrders.reduce((tot, item) => tot + item.confirmation_gross_price, 0);
    let workforceConsumed = this.getConsumedWorkforce();

    return total / (materialProjected + workforceConsumed);
  }
  get billing_coverage(): number {
    if (this.real_cost === 0) return 0;
    return (this.invoices_on_going_value / this.real_cost) * 100;
  }

  get billing_coverage_style(): string {
    if (this.billing_coverage >= 120) return "success";
    if (this.billing_coverage >= 100) return "warning";
    return "danger";
  }

  get validated_direct_invoices(): Invoice[] {
    return this.received_direct_invoices.filter((x) => x.status !== InvoiceStatus.CANCELLED);
  }

  constructor() {
    this.allowed_employees_id = [];
    this.situations = [];
    this.address_lat = 0.0;
    this.address_lng = 0.0;
    this.status = ProjectStatus.ON_GOING;
  }

  public static createInstance(jsonData: Project): Project {
    const p = Object.assign(new Project(), jsonData);

    if (jsonData.offers) {
      p.offers = jsonData.offers.map(Offer.createInstance);
    }
    if (jsonData.tags) {
      p.tags = jsonData.tags.map(Tag.createInstance);
    }

    if (jsonData.assets) {
      p.assets = jsonData.assets.map(Asset.createInstance);
    }

    if (jsonData.employee_week_activities) {
      p.employee_week_activities = jsonData.employee_week_activities.map(EmployeeWeekActivity.createInstance);
    }

    if (jsonData.purchase_orders) {
      p.purchase_orders = jsonData.purchase_orders.map(PurchaseOrder.createInstance);
    }

    p.start_date = jsonData.start_date ? new Date(jsonData.start_date) : null;
    p.end_date = jsonData.end_date ? new Date(jsonData.end_date) : null;

    return p;
  }

  public static createInstances(jsonData: any[]): Project[] {
    return jsonData.map(Project.createInstance);
  }

  get validWeekActivities(): EmployeeWeekActivity[] {
    if (!this.employee_week_activities) return [];
    return this.employee_week_activities.filter((x) => x.status === EmployeeWeekActivityStatus.ACCEPTED);
  }

  get validatedOffers(): Offer[] {
    if (!this.offers) return [];
    return this.offers.filter((x) => x.status === OfferStatus.VALIDATED);
  }

  get validPurchaseOrders(): PurchaseOrder[] {
    if (!this.purchase_orders) return [];
    return this.purchase_orders.filter((x) => x.status !== PurchaseOrderStatus.CANCELLED);
  }

  get validInvoices(): Invoice[] {
    let invoices: Invoice[] = [];
    for (let p of this.validPurchaseOrders) {
      if (p.invoices) {
        invoices = invoices.concat(p.invoices.filter((x) => x.status !== InvoiceStatus.CANCELLED));
      }
    }
    return invoices;
  }

  public totalCostPrice(filter = "*"): number {
    let total = 0.0;
    for (const o of this.validatedOffers) {
      total += o.totalCostPrice(filter);
    }
    return total;
  }

  totalSellingAuctionRate(filter = "*") {
    return Math.abs((this.totalSellingPriceWithAuction(filter) / this.totalSellingPrice(filter)) * 100 - 100) || 0;
  }

  totalSellingPriceWithAuction(filter = "*") {
    let total = 0;
    for (const o of this.validatedOffers) {
      total += o.offer_item_groups.reduce((tot, item) => tot + +item.totalSellingPriceWithAuction(filter), 0);
    }
    return total;
  }

  public totalSellingPrice(filter = "*"): number {
    let total = 0.0;
    for (const o of this.validatedOffers) {
      total += o.totalSellingPrice(filter);
    }
    return total;
  }

  public totalNet(filter = "*"): number {
    let total = 0;
    for (const o of this.validatedOffers) {
      total += o.offer_item_groups.reduce((tot, item) => tot + +item.totalSellingPriceWithAuction(filter), 0);
    }
    return total;
  }

  public total_cost_price_with_discount_goal(filter = "*"): number {
    let total = 0;
    for (const o of this.validatedOffers) {
      total += o.total_cost_price_with_discount_goal(filter);
    }
    return total;
  }

  public margin_base_net(filter = "*"): number {
    return this.totalNet(filter) / this.totalCostPrice(filter);
  }

  public margin_goal_net(filter = "*"): number {
    return this.totalNet(filter) / this.total_cost_price_with_discount_goal(filter);
  }

  public getVatTotal(filter = "*"): number {
    return this.totalNet(filter) * 0.077;
  }

  public totalNetWithVat(filter = "*"): number {
    return this.totalNet(filter) + this.getVatTotal(filter);
  }

  public totalDiscounts(filter = "*"): number {
    let total = 0.0;
    for (const o of this.validatedOffers) {
      total += o.totalDiscounts(filter);
    }
    return total;
  }

  public totalBillingGross(filter = "*"): number {
    let total = 0.0;
    for (const o of this.validatedOffers) {
      total += o.total_billing_amount(filter);
    }
    return total;
  }

  public totalBillingPercent(filter = "*"): number {
    const billingNet = this.totalBillingNet(filter);
    const sellingNet = this.totalNet(filter);
    if (sellingNet === 0) return 0;
    return (billingNet / sellingNet) * 100 || 0;
  }

  public totalBillingNet(filter = "*"): number {
    let total = 0.0;
    for (const o of this.validatedOffers) {
      total += o.total_billing_amount_net(filter);
    }
    return total;
  }

  public totalBillingNetCurrentSituation(filter = "*"): number {
    let total = this.totalBillingNet(filter);

    for (const s of this.situations) {
      total -= s.totalBillingNet();
    }

    return total;
  }

  public getFormErrors(): string[] {
    const errors = [];
    if (this.isEmpty(this.name)) errors.push("Le nom ne peut être vide");
    if (this.isEmpty(this.address)) errors.push("L'adresse ne peut être vide");
    if (this.isEmpty(this.internal_contact)) errors.push("Le contact interne ne peut être vide");
    if (this.isEmpty(this.external_contact)) errors.push("Le contact externe (client) ne peut être vide");
    return errors;
  }

  public isEmpty(val: string): boolean {
    if (val == null) return true;
    if (val.trim() === "") return true;
    return false;
  }

  get displayName() {
    return this.project_corporate_id + " - " + this.name;
  }

  public getConsumedMaterial() {
    let total = 0.0;

    if (this.validPurchaseOrders) {
      for (let p of this.validPurchaseOrders) {
        if (p.purchase_order_type === PurchaseOrderType.MATERIAL) {
          total += p.consumedAmount;
        }
      }
    }

    for (let i of this.validated_direct_invoices) {
      total += i.gross_total_price;
    }

    return total;
  }

  public getConsumedWorkforce() {
    let total = 0.0;

    if (this.employee_week_activities) {
      for (let wa of this.employee_week_activities.filter((x) =>
        [EmployeeWeekActivityStatus.ACCEPTED, EmployeeWeekActivityStatus.WAITING_VALIDATION].includes(x.status)
      )) {
        for (let a of wa.activities.filter((x) => x.project_id === this.project_id)) {
          total += a.activity_cost;
        }
      }
    }
    if (this.validPurchaseOrders) {
      for (let p of this.validPurchaseOrders) {
        if (
          p.purchase_order_type === PurchaseOrderType.WORKFORCE &&
          p.purchase_order_workforce_type === PurchaseOrderWorkForceType.SUBCONTRACTOR
        ) {
          total += p.consumedAmount;
        }
      }
    }

    return total;
  }

  get hoursWorked() {
    let total = 0.0;

    if (this.employee_week_activities) {
      for (let wa of this.employee_week_activities) {
        for (let a of wa.activities.filter(
          (x) => x.project_id === this.project_id && x.activity_type === EmployeeActivityType.WORKING
        )) {
          total += a.hours_count;
        }
      }
    }

    return total;
  }

  public getConsumedRate(filter = "*") {
    let totalConsumed = this.getConsumed(filter);
    let total = this.totalNet(filter);
    return (totalConsumed / total) * 100 || 0;
  }

  public getConsumed(filter = "*") {
    let total = 0.0;

    if (filter === "*" || filter === OfferUnitType.MATERIAL) {
      total += this.getConsumedMaterial();
    }

    if (filter === "*" || filter === OfferUnitType.WORKFORCE) {
      total += this.getConsumedWorkforce();
    }

    return total;
  }

  public getConsumedProjectedMaterial() {
    let total = 0.0;

    if (this.validPurchaseOrders) {
      for (let p of this.validPurchaseOrders) {
        if (p.purchase_order_type === PurchaseOrderType.MATERIAL) {
          total += p.projectedAmount;
        }
      }
    }

    for (let i of this.validated_direct_invoices) {
      total += i.gross_total_price;
    }

    return total;
  }

  public getConsumedProjectedWorkforce() {
    let total = 0.0;

    if (this.employee_week_activities) {
      for (let wa of this.employee_week_activities.filter((x) => x.status !== EmployeeWeekActivityStatus.REJECTED)) {
        for (let a of wa.activities.filter((x) => x.project_id === this.project_id)) {
          total += a.activity_cost;
        }
      }
    }

    if (this.validPurchaseOrders) {
      for (let p of this.validPurchaseOrders) {
        if (
          p.purchase_order_type === PurchaseOrderType.WORKFORCE &&
          p.purchase_order_workforce_type === PurchaseOrderWorkForceType.SUBCONTRACTOR
        ) {
          total += p.projectedAmount;
        }
      }
    }

    return total;
  }

  public getConsumedProjectedRate(filter = "*") {
    let totalConsumed = this.getConsumedProjected(filter);
    let total = this.totalNet(filter);
    return (totalConsumed / total) * 100 || 0;
  }

  public getConsumedProjected(filter = "*") {
    let total = 0.0;

    if (filter === "*" || filter === OfferUnitType.MATERIAL) {
      total += this.getConsumedProjectedMaterial();
    }

    if (filter === "*" || filter === OfferUnitType.WORKFORCE) {
      total += this.getConsumedProjectedWorkforce();
    }

    return total;
  }

  public getInstantMargin(filter = "*") {
    const consumed = this.getConsumed(filter);
    return consumed !== 0 ? this.totalNet(filter) / consumed : 0.0;
  }
  public getProjectedMargin(filter = "*") {
    const projected = this.getConsumedProjected(filter);
    return projected !== 0 ? this.totalNet(filter) / projected : 0.0;
  }

  public getAvailableBase(filter = "*") {
    const diff = this.totalNet(filter) - this.getConsumed(filter);
    return diff >= 0 ? diff : 0.0;
  }

  public getAvailableGoal(filter = "*") {
    const diff = this.total_cost_price_with_discount_goal(filter) - this.getConsumed(filter);
    return diff >= 0 ? diff : 0.0;
  }

  public getShortTitle() {
    return this.project_corporate_id + " - " + this.name;
  }

  // FACTURE RECUES
  get posWithLateInvoices(): PurchaseOrder[] {
    return this.validPurchaseOrders.filter((x) => x.toPayLateInvoicesCount > 0);
  }
  get toPayLateInvoices(): Invoice[] {
    return this.validPurchaseOrders.flatMap((x) => x.toPayLateInvoices).filter((x) => x);
  }
  get toPayLateInvoicesCount(): number {
    return this.toPayLateInvoices.length;
  }
  get toPayLateInvoicesValue(): number {
    return this.toPayLateInvoices.reduce((tot, item) => (tot += item.netTotalPrice), 0);
  }
  get posWithNotLateInvoices(): PurchaseOrder[] {
    return this.validPurchaseOrders.filter((x) => x.toPayLateInvoicesCount === 0);
  }
  get toPayNotLateInvoices(): Invoice[] {
    return this.validPurchaseOrders.flatMap((x) => x.toPayNotLateInvoices).filter((x) => x);
  }
  get toPayNotLateInvoicesCount(): number {
    return this.toPayNotLateInvoices.length;
  }
  get toPayNotLateInvoicesValue(): number {
    return this.toPayNotLateInvoices.reduce((tot, item) => (tot += item.netTotalPrice), 0);
  }

  get workforce_total_hours() {
    let workforce_budget_goal = this.workforce_budget_goal;
    let workforce_hourly_cost = this.workforce_hourly_cost;
    return workforce_hourly_cost !== 0 ? workforce_budget_goal / workforce_hourly_cost : 0.0;
  }

  /* get workforce_total_days() {
    let workforce_total_hours = this.workforce_total_hours;
    return workforce_total_hours ? workforce_total_hours / 8.25 : 0.0;
  }

  get workforce_days_worked() {
    return this.hoursWorked / 8.25 || 0.0;
  }*/

  get workforce_days_available() {
    let base = this.workforce_total_days;
    let consumed = this.workforce_days_worked;
    return base > consumed ? base - consumed : 0;
  }

  get workforce_consumed_rate() {
    let workforce_total_days = this.workforce_total_days;
    return workforce_total_days !== 0 ? (this.workforce_days_worked / workforce_total_days) * 100 : 0.0;
  }

  get workforce_available_rate() {
    let workforce_total_days = this.workforce_total_days;
    return workforce_total_days !== 0 ? (this.workforce_days_available / workforce_total_days) * 100 : 0.0;
  }

  get workforce_budget_goal() {
    let sumWorkforce = this.totalSellingPriceWithAuction(OfferUnitType.WORKFORCE);
    let goalWorkforce = sumWorkforce;
    if (this.workforce_goal_margin) {
      goalWorkforce -= (sumWorkforce * this.workforce_goal_margin) / 100;
    }
    return goalWorkforce;
  }

  get consumedProgressBarClass() {
    let workforce_consumed_rate = this.workforce_consumed_rate;
    if (workforce_consumed_rate > 100) {
      return "danger";
    } else if (workforce_consumed_rate >= 75) {
      return "warning";
    } else {
      return "success";
    }
  }

  clone(): Project {
    let p = Object.assign(new Project(), this);
    return p;
  }
}
