import { OfferDiscount, OfferItem, OfferItemType, OfferUnitType } from "src/app/domains/internal";

export class OfferItemGroup {
  offer_item_group_id: string;
  name: string;
  group_type: string; // BASE | AMENDMENT
  default_margin: number;
  offer_groups: OfferItemGroup[];
  offer_items: OfferItem[];
  isPageBreakBefore: boolean;

  constructor(name, group_type) {
    this.offer_item_group_id = generateObjectId();
    this.name = name;
    this.group_type = group_type;
    this.default_margin = 25;
    this.offer_groups = [];
    this.offer_items = [];
  }

  public static createInstance(jsonData: OfferItemGroup): OfferItemGroup;
  public static createInstance(jsonData: OfferItemGroup, offerDiscounts: any[]): OfferItemGroup;
  public static createInstance(jsonData: OfferItemGroup, offerDiscounts?: any[]): OfferItemGroup {
    const d = Object.assign(new OfferItemGroup("", ""), jsonData);
    if (d.offer_groups) {
      d.offer_groups = OfferItemGroup.createInstances(d.offer_groups, offerDiscounts);
    }
    if (d.offer_items) {
      d.offer_items = OfferItem.createInstances(d.offer_items, offerDiscounts || []);
    }
    return d;
  }

  public static createInstances(jsonData: any[]): OfferItemGroup[];
  public static createInstances(jsonData: any[], offerDiscounts: any[]): OfferItemGroup[];
  public static createInstances(jsonData: any[], offerDiscounts?: any[]): OfferItemGroup[] {
    if (!jsonData) return [];
    return jsonData.map((val) => {
      return OfferItemGroup.createInstance(val, offerDiscounts);
    });
  }

  replaceAllIds() {
    this.offer_item_group_id = generateObjectId();
    this.offer_groups.forEach((x) => x.replaceAllIds());
    this.offer_items.forEach((x) => x.replaceIds());
  }

  getPercentGroups() {
    let previousItems: OfferItem[] = [];
    let percentsArray = [];

    for (let item of this.offer_items) {
      if (item.offer_item_type === OfferItemType.PERCENT) {
        previousItems.push(item);

        percentsArray.push([...previousItems]);

        previousItems = [];
      } else {
        previousItems.push(item);
      }
    }
    return percentsArray;
  }

  getOfferItems(filter = "*") {
    if (!this.offer_items) return [];
    return filter === "*" ? this.offer_items : this.offer_items.filter((x) => x.unit_type === filter);
  }

  getOfferItemByID(offer_item_id) {
    if (!this.offer_items) return [];
    return this.offer_items.filter((x) => x.offer_item_id === offer_item_id);
  }

  totalcost_price(filter = "*") {
    let total = 0;
    if (this.offer_groups) {
      total += this.offer_groups.reduce((tot, item) => tot + +item.totalcost_price(filter), 0);
    }
    total += this.getOfferItems(filter).reduce((tot, item) => tot + (item.shouldCount ? +item.cost_price : 0), 0);
    return total;
  }

  totalSellingPrice(filter = "*") {
    let total = 0;
    if (this.offer_groups) {
      total += this.offer_groups.reduce((tot, item) => tot + +item.totalSellingPrice(filter), 0);
    }
    total += this.getOfferItems(filter).reduce(
      (tot, item) => tot + (item.shouldCount ? +item.sellingPriceWithDiscount : 0),
      0
    );
    return total;
  }
  totalSellingPriceWithoutDiscount(filter = "*") {
    let total = 0;
    if (this.offer_groups) {
      total += this.offer_groups.reduce((tot, item) => tot + +item.totalSellingPriceWithoutDiscount(filter), 0);
    }
    total += this.getOfferItems(filter).reduce((tot, item) => tot + (item.shouldCount ? +item.selling_price : 0), 0);
    return total;
  }

  totalUnitQuantities(filter = "*") {
    return this.getOfferItems(filter).reduce((tot, item) => tot + (item.shouldCount ? +item.unit_quantity : 0), 0);
  }

  avgunit_price(filter = "*") {
    return this.totalcost_price(filter) / this.totalUnitQuantities(filter) || 0;
  }

  totalMarginSellingPrice(filter = "*") {
    return Math.abs((this.totalSellingPrice(filter) / this.totalcost_price(filter)) * 100 - 100) || 0;
  }

  totalSellingProfit(filter = "*") {
    let total = 0;
    if (this.offer_groups) {
      total += this.offer_groups.reduce((tot, item) => tot + +item.totalSellingProfit(filter), 0);
    }
    total += this.getOfferItems(filter).reduce((tot, item) => tot + (item.shouldCount ? +item.selling_profit : 0), 0);
    return total;
  }

  totalSellingAuctionRate(filter = "*") {
    return Math.abs((this.totalSellingPriceWithAuction(filter) / this.totalSellingPrice(filter)) * 100 - 100) || 0;
  }

  totalSellingAuction(filter = "*") {
    let total = 0;
    if (this.offer_groups) {
      total += this.offer_groups.reduce((tot, item) => tot + +item.totalSellingAuction(filter), 0);
    }
    total += this.getOfferItems(filter).reduce((tot, item) => tot + (item.shouldCount ? +item.selling_auction : 0), 0);
    return total;
  }

  totalSellingPriceWithAuction(filter = "*") {
    let total = 0;
    if (this.offer_groups) {
      total += this.offer_groups.reduce((tot, item) => tot + +item.totalSellingPriceWithAuction(filter), 0);
    }
    total += this.getOfferItems(filter).reduce(
      (tot, item) => tot + (item.shouldCount ? +item.selling_price_with_auction : 0),
      0
    );
    return total;
  }

  totalSupplierDiscountRate(filter = "*") {
    return Math.abs((this.totalcost_priceWithDiscount(filter) / this.totalcost_price(filter)) * 100 - 100) || 0;
  }

  totalSupplierDiscount(filter = "*") {
    let total = 0;
    if (this.offer_groups) {
      total += this.offer_groups.reduce((tot, item) => tot + +item.totalSupplierDiscount(filter), 0);
    }
    total += this.getOfferItems(filter).reduce(
      (tot, item) => tot + (item.shouldCount ? +item.supplier_discount_goal_total : 0),
      0
    );
    return total;
  }

  totalcost_priceWithDiscount(filter = "*") {
    let total = 0;
    if (this.offer_groups) {
      total += this.offer_groups.reduce((tot, item) => tot + +item.totalcost_priceWithDiscount(filter), 0);
    }
    total += this.getOfferItems(filter).reduce((tot, item) => tot + (item.shouldCount ? +item.buy_price_goal : 0), 0);
    return total;
  }

  total_billing_amount(situationIndex, filter = "*") {
    let total = 0;
    if (this.offer_groups) {
      total += this.offer_groups.reduce((tot, item) => tot + +item.total_billing_amount(situationIndex, filter), 0);
    }
    total += this.getOfferItems(filter).reduce(
      (tot, item) => tot + (item.shouldCount ? +item.billing_amount(situationIndex) : 0),
      0
    );
    return total;
  }

  total_billing_percent(situationIndex, filter = "*") {
    const total_selling = this.totalSellingPrice(filter);
    const total_billing = this.total_billing_amount(situationIndex, filter);
    if (total_selling == 0) return 0;

    return (total_billing / total_selling) * 100;
  }

  updateSupplierDiscountGoal(supplier_id: string, offer_unit_type: OfferUnitType, val: number) {
    for (let offer_group of this.offer_groups) {
      offer_group.updateSupplierDiscountGoal(supplier_id, offer_unit_type, val);
    }
    for (let offer_item of this.getOfferItems("*").filter(
      (x) => x.supplier_id === supplier_id && x.unit_type === offer_unit_type
    )) {
      offer_item.setSupplierDiscountGoal(val);
    }
  }

  updateGroupBillingPercent(value, situationIndex) {
    for (let offer_group of this.offer_groups) {
      offer_group.updateGroupBillingPercent(value, situationIndex);
    }
    for (let offer_item of this.offer_items) {
      offer_item.set_billing_percent_situations(value, situationIndex);
    }
  }

  setOfferDiscounts(offerDiscounts: OfferDiscount[]) {
    for (const group of this.offer_groups) {
      group.setOfferDiscounts(offerDiscounts);
    }
    for (const item of this.offer_items) {
      item.setOfferDiscounts(offerDiscounts);
    }
  }

  get_suppliers() {
    let suppliers = [];
    for (let offer_group of this.offer_groups) {
      suppliers = suppliers.concat(offer_group.get_suppliers());
    }
    for (let offer_item of this.offer_items) {
      suppliers.push(offer_item.supplier_id);
    }
    return suppliers;
  }
}

function generateObjectId(): string {
  const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
  const randomHex = Array.from({ length: 16 }, () => Math.floor(Math.random() * 16).toString(16)).join("");

  return timestamp + randomHex;
}
