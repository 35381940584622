export class MailSignature {
  mail_signature_id: string = "";
  signature_name: string = "";
  signature_content: string = "";
  constructor() {
    this.signature_name = "";
    this.signature_content = "";
  }

  public static createInstance(jsonData: any): MailSignature {
    return Object.assign(new MailSignature(), jsonData);
  }

  public static createInstances(jsonData: any[]): MailSignature[] {
    return jsonData.map(MailSignature.createInstance);
  }
}

export class EmployeeMailSignatures {
  employee_id: string;
  signatures: MailSignature[] = [];
  selected_signature_id: string;

  constructor() {}

  public static createInstance(jsonData: any): EmployeeMailSignatures {
    const e = Object.assign(new EmployeeMailSignatures(), jsonData);
    if (jsonData.signatures) {
      e.signatures = MailSignature.createInstances(jsonData.signatures);
    }

    return e;
  }
}
