import { round } from "mathjs";
import { Offer } from "src/app/domains/internal";

import { Customer } from "../features/customer/domains/customer";

export class CustomerOffers {
  customer: Customer = new Customer();
  offers: Offer[] = [];
  expanded = false;

  get totalNet(): number {
    return this.offers.reduce((acc, offer) => {
      return acc + offer.totalNet();
    }, 0);
  }

  get totalBilled(): number {
    return this.offers.reduce((acc, offer) => {
      return acc + offer.totalBilled;
    }, 0);
  }

  get totalBilledRate(): number {
    let totalBilled = this.totalBilled;
    let total = this.offers.reduce((acc, offer) => {
      return acc + offer.totalNet();
    }, 0);
    return round((totalBilled / total) * 100, 2);
  }

  get toBill(): number {
    let totalNet = this.totalNet;
    let totalBilled = this.totalBilled;
    let toBill = totalNet - totalBilled;
    return toBill > 0 ? toBill : 0;
  }

  get billedProgressBarClass() {
    let totalBilledRate = this.totalBilledRate;
    if (totalBilledRate > 100) {
      return "danger";
    } else if (totalBilledRate < 100) {
      return "warning";
    } else {
      return "success";
    }
  }

  get totalPaid(): number {
    return this.offers.reduce((acc, offer) => {
      return acc + offer.totalPaid;
    }, 0);
  }

  get totalPaidRate(): number {
    let totalPaid = this.totalPaid;
    let total = this.offers.reduce((acc, offer) => {
      return acc + offer.totalNet();
    }, 0);
    return round((totalPaid / total) * 100, 2);
  }

  get toPay(): number {
    let totalNet = this.totalNet;
    let totalPaid = this.totalPaid;
    let toPay = totalNet - totalPaid;
    return toPay > 0 ? toPay : 0;
  }

  get paidProgressBarClass() {
    let totalPaidRate = this.totalPaidRate;
    if (totalPaidRate > 100) {
      return "danger";
    } else if (totalPaidRate < 100) {
      return "warning";
    } else {
      return "success";
    }
  }

  constructor() {
    this.customer = new Customer();
    this.offers = [];
  }
}
