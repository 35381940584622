import { Asset, InvoiceStatus, OfferItemGroup } from "src/app/domains";

import { Contract } from "../../customer/domains/contract";
import { FieldValue } from "../../customer/domains/field_value";
import { InterventionAction } from "./intervention_action";
import { InterventionMaterial } from "./intervention_material";

export enum InterventionStatus {
  ONGOING = "ONGOING",
  CANCELLED = "CANCELLED",
  TERMINATED = "TERMINATED",
}
export enum InterventionStatusLabel {
  ONGOING = "En cours",
  CANCELLED = "Abandonnée",
  TERMINATED = "Terminée",
}

export class InterventionDate {
  time_start: Date;
  time_end: Date;
  employee_id: string;

  public static createInstance(jsonData: InterventionDate): InterventionDate {
    const d = Object.assign(new InterventionDate(), jsonData);
    if (d.time_start) {
      d.time_start = new Date(d.time_start);
    }
    if (d.time_end) {
      d.time_end = new Date(d.time_end);
    }
    return d;
  }
  public static createInstances(jsonData: any[]): InterventionDate[] {
    return jsonData.map(InterventionDate.createInstance);
  }

  clone(): InterventionDate {
    let d = Object.assign(new InterventionDate(), this);
    return d;
  }
}

export class Intervention {
  intervention_id: string = "";
  eva_company_id: string = "";
  assigned_to_ids: string[] = [];
  intervention_corporate_id: string = "";

  // EITHER :
  contract_installation_id: string = "";
  // AND/OR :
  customer_id: string = "";
  // If exceptionnal intervention, maybe :
  intervention_type_id: string = "";

  project_id: string = "";
  renew_date: Date;
  date_start: Date;
  date_end: Date;
  dates_array: InterventionDate[] = [];
  report_values: FieldValue[] = [];
  quotation: OfferItemGroup = null;
  comment: string = "";
  comment_cancelled: string = "";
  invoiced: boolean = false;
  status: InterventionStatus = InterventionStatus.ONGOING;
  workingTime: number = 0;
  travelTime: number = 0;
  travelDistance: number = 0;
  report_title: string = "";
  passages_action: InterventionAction[] = [];
  actions: InterventionAction[] = [];
  materials: InterventionMaterial[] = [];

  exceptional_start_date: Date;

  tags_ids: string[] = [];

  // External data

  assets: Asset[];
  invoice_status: InvoiceStatus = null;

  contract_corporate_id: string = "";
  contract_installation_name: string = "";
  customer_name: string = "";

  intervention_type = null;
  status_label = "";

  get isExceptional(): boolean {
    return this.exceptional_start_date != null && this.exceptional_start_date != undefined;
  }

  public static createInstance(jsonData: Intervention): Intervention {
    const d = Object.assign(new Intervention(), jsonData);
    if (d.date_start) {
      d.date_start = new Date(d.date_start);
    }
    if (d.date_end) {
      d.date_end = new Date(d.date_end);
    }
    if (d.dates_array) {
      d.dates_array = InterventionDate.createInstances(d.dates_array);
    }
    if (d.renew_date) {
      d.renew_date = new Date(d.renew_date);
    }
    if (d.exceptional_start_date) {
      d.exceptional_start_date = new Date(d.exceptional_start_date);
    }
    if (d.report_values) {
      d.report_values = FieldValue.createInstances(d.report_values);
    }
    if (d.quotation) {
      d.quotation = OfferItemGroup.createInstance(d.quotation);
    }
    if (d.passages_action) {
      d.passages_action = InterventionAction.createInstances(d.passages_action);
    }
    if (d.actions) {
      d.actions = InterventionAction.createInstances(d.actions);
    }
    if (d.materials) {
      d.materials = InterventionMaterial.createInstances(d.materials);
    }
    return d;
  }

  public static createInstances(jsonData: any[]): Intervention[] {
    return jsonData.map(Intervention.createInstance);
  }

  clone(): Intervention {
    let dates_array = this.dates_array.map((d) => d.clone());
    let report_values = FieldValue.multiClone(this.report_values);
    let quotation = this.quotation ? OfferItemGroup.createInstance(this.quotation) : null;
    let d = Object.assign(new Intervention(), this);
    d.dates_array = dates_array;
    d.report_values = report_values;
    d.quotation = quotation;
    return d;
  }

  getDatesId(employee_id): InterventionDate[] {
    return this.dates_array.filter((d) => d.employee_id == employee_id);
  }

  getContract(contracts: Contract[]): Contract {
    let contract = contracts.find((c) => {
      let found = false;
      c.installations.forEach((i) => {
        if (i.contract_installation_id == this.contract_installation_id) {
          found = true;
        }
      });
      return found;
    });
    if (contract) {
      return contract;
    }
    return null;
  }
}
