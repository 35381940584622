import { de } from "date-fns/locale";
import { round } from "mathjs";
import { Invoice, OfferDiscount, OfferItemGroup, OfferSupplierDiscountGoal, Project } from "src/app/domains/internal";

import { Contact } from "../features/contact/domains/contact";
import { Customer } from "../features/customer/domains/customer";
import { OfferPdfConfig } from "../features/offer-v2/domains/OfferPdfConfig";
import { getTodayUtc } from "../shared/helpers/date.helpers";
import { roundToNearestCents } from "../shared/helpers/number.helper";
import { Employee, OfferDiscountType } from "./";
import { Asset } from "./asset";
import { CallToTender } from "./call-to-tender";
import { InvoiceStatus } from "./invoice";
import { OfferMilestone } from "./offer-milestone";
import { OfferSend } from "./offer-send";

export enum OfferStatus {
  ON_GOING = "ON_GOING",
  VALIDATED = "VALIDATED",
  CANCELLED = "CANCELLED",
  DRAFT = "DRAFT",
  TEMPLATE = "TEMPLATE",
  TEMPLATE_INACTIVE = "TEMPLATE_INACTIVE",
  ALL = "ALL",
}

export enum OfferStatusLabel {
  ON_GOING = "En Cours",
  VALIDATED = "Validée",
  CANCELLED = "Abandonnée",
  DRAFT = "Brouillon",
  TEMPLATE = "Template",
  TEMPLATE_INACTIVE = "Template Inactif",
}

export class Offer {
  offer_id: string;

  project_id: string;
  customer_id: string;
  customer_address_id: string;
  display_contact_name: boolean;
  auto_save: boolean;
  employee_id: string;
  eva_company_id: string;

  offer_corporate_id: string;
  temp_project_name: string;
  temp_project_desc: string;
  temp_project_address: string;
  temp_project_address_lng: number;
  temp_project_address_lat: number;
  temp_project_internal_contact: string;
  temp_project_external_contact: string;

  template_desc: string;
  intro: string;
  intro_rtf: string;
  outro: string;
  offer_finish_deadline: Date;
  start_workdate: Date;
  end_workdate: Date;
  technical_office: string;
  technical_contact: string;
  project_manager: string;
  warranty_rate: number;
  comment: string;

  trust_level = 1;

  pdf_config: OfferPdfConfig;

  vat_rate: number;
  public setVatRate(vatRate: number) {
    this.vat_rate = vatRate;
  }

  round_total_pt = 0.05;
  public setRoundTotalPt(round_total_pt: number) {
    this.round_total_pt = round_total_pt;
  }

  currency = "CHF";
  public setCurrency(currency: string) {
    if (["CHF", "EUR"].includes(currency)) {
      this.currency = currency;
    } else {
      this.currency = "CHF";
    }
  }

  offer_item_groups: OfferItemGroup[];
  milestones: OfferMilestone[];

  offer_discounts: OfferDiscount[];
  public setOfferDiscounts(offerDiscounts: OfferDiscount[]) {
    this.offer_discounts = offerDiscounts;
    for (const group of this.offer_item_groups) {
      group.setOfferDiscounts(offerDiscounts);
    }
  }

  offer_supplier_discount_goals: OfferSupplierDiscountGoal[];

  call_to_tenders: CallToTender[];

  invoices_ids: string[];
  contacts_ids: string[];
  assets_ids: string[];
  tags_ids: string[];

  status: OfferStatus;

  created_by: string;
  created_on: Date;

  updated_by: string;
  updated_on: Date;

  sent_on: Date;
  sent_by: string;
  send_type: string;
  customer_response_deadline: Date;
  sent_histories: OfferSend[];

  template_allow_prod_ids: string[];

  // used on the list to optimize loading by not computing all total gross
  static_total_net = 0;

  // External Data
  employee: Employee;
  project: Project;
  customer: Customer;
  contacts: Contact[] = [];
  invoices: Invoice[];
  assets: Asset[];
  created_by_employee: Employee;
  updated_by_employee: Employee;
  sent_by_employee: Employee;

  get allInvoices(): Invoice[] {
    return this.invoices ? this.invoices : [];
  }

  get validInvoices(): Invoice[] {
    return this.allInvoices.filter((x) => x.status !== InvoiceStatus.CANCELLED);
  }

  get validatedInvoices(): Invoice[] {
    return this.allInvoices.filter((x) =>
      [InvoiceStatus.VALIDATED, InvoiceStatus.SENT, InvoiceStatus.PAID].includes(x.status)
    );
  }

  get sentInvoices(): Invoice[] {
    return this.allInvoices.filter((x) => [InvoiceStatus.SENT, InvoiceStatus.PAID].includes(x.status));
  }

  get paidInvoices(): Invoice[] {
    return this.allInvoices.filter((x) => x.status === InvoiceStatus.PAID);
  }

  get hasDraftInvoice(): Boolean {
    return this.allInvoices.filter((x) => x.status === InvoiceStatus.DRAFT).length > 0;
  }

  get offerProjectCorporateId() {
    return this.project ? this.project.project_corporate_id : "";
  }
  get offerProjectName() {
    return this.project ? this.project.name : this.temp_project_name;
  }
  get offerProjectAddress() {
    return this.project ? this.project.address : this.temp_project_address;
  }
  get offerProjectInternalContact() {
    return this.project ? this.project.internal_contact : this.temp_project_internal_contact;
  }
  get offerProjectExternalContact() {
    return this.project ? this.project.external_contact : this.temp_project_external_contact;
  }

  get percentBilled(): number {
    return (this.totalBilled / this.offerTotalNet) * 100 || 0;
  }

  get desc(): string {
    return this.pdf_config?.title;
  }

  get totalBilled(): number {
    return this.validatedInvoices.reduce((acc, invoice) => {
      return acc + invoice.gross_total_price;
    }, 0);
  }

  get totalPaid(): number {
    return this.paidInvoices.reduce((acc, invoice) => {
      return acc + invoice.gross_total_price;
    }, 0);
  }

  static get offerStatusLabel() {
    return {
      [OfferStatus.DRAFT]: "Brouillon",
      [OfferStatus.ON_GOING]: "En cours",
      [OfferStatus.CANCELLED]: "Abandonnée",
      [OfferStatus.VALIDATED]: "Validée",
    };
  }

  get offerStatusLabel() {
    return Offer.offerStatusLabel[this.status];
  }

  static get templateStatusLabel() {
    return {
      [OfferStatus.TEMPLATE]: "Template Actif",
      [OfferStatus.TEMPLATE_INACTIVE]: "Template Inactif",
    };
  }

  excelExport(external_data = false) {
    return {
      offer_id: this.offer_corporate_id,
      chantier_num: this.offerProjectCorporateId,
      chantier_name: this.offerProjectName,
      chantier_address: this.offerProjectAddress,
      chantier_internal_contact: this.offerProjectInternalContact,
      chantier_external_contact: this.offerProjectExternalContact,
      start_workdate: this.start_workdate,
      end_workdate: this.end_workdate,
      offer_finish_deadline: this.offer_finish_deadline,
      offer_total_net: this.offerTotalNet,
      offer_status: this.status,
      sent_on: this.sent_on,
    };
  }

  get late(): boolean {
    return new Date() > this.offer_finish_deadline;
  }

  constructor() {
    this.start_workdate = getTodayUtc();
    this.end_workdate = getTodayUtc();
    this.offer_finish_deadline = getTodayUtc();
    this.offer_discounts = [];
    this.offer_item_groups = [];
    this.milestones = [];
    this.project = new Project();
    this.customer = new Customer();
    this.customer_id = "";
    this.invoices = [];
    this.call_to_tenders = [];
    this.assets = [];
    this.status = OfferStatus.ON_GOING;
    this.display_contact_name = true;
    this.template_allow_prod_ids = [];
    this.pdf_config = new OfferPdfConfig();
    this.intro = `Madame, Monsieur,

Nous avons le plaisir de vous transmettre notre meilleure offre concernant l'affaire précitée en marge à savoir : `;
    this.outro = `Non compris :

Tout ce qui n'est pas clairement décrit dans cette offre

Validité de l'offre 4 semaines `;
  }

  public totalCostPrice(filter = "*") {
    if (!this.offer_item_groups) return 0;
    return this.offer_item_groups.reduce((tot, act) => tot + act.totalcost_price(filter), 0);
  }

  public totalSellingPrice(filter = "*") {
    if (!this.offer_item_groups) return 0;
    return this.offer_item_groups.reduce((tot, act) => tot + act.totalSellingPrice(filter), 0);
  }

  get offerTotalNet(): number {
    return this.totalNet();
  }

  public totalNet(deduct_warranty = false, filter = "*"): any {
    if (deduct_warranty) {
      return this.totalSellingPrice(filter) - this.totalDiscounts(filter) - this.warranty_total(filter) || 0;
    } else {
      return this.totalSellingPrice(filter) - this.totalDiscounts(filter) || 0;
    }
  }

  // public getVatDictionary(deduct_warranty = false, filter = "*") {
  //   if (!this.offer_item_groups) return {};

  //   let vatDictionary: { [key: string]: number } = {};

  //   this.offer_item_groups.forEach((group) => {
  //     let groupVats = group.calculateGroupNetTotal(deduct_warranty, filter);

  //     for (const [rate, amount] of Object.entries(groupVats)) {
  //       if (!vatDictionary[rate]) {
  //         vatDictionary[rate] = 0;
  //       }
  //       vatDictionary[rate] += amount;
  //     }
  //   });

  //   return vatDictionary;
  // }

  public getVatTotal(deduct_warranty = false, filter = "*") {
    return round(this.totalNet(deduct_warranty, filter) * (this.vat_rate / 100), 2);
  }

  public totalNetWithVat(deduct_warranty = false, filter = "*") {
    const total = this.totalNet(deduct_warranty, filter) + this.getVatTotal(deduct_warranty, filter);
    return roundToNearestCents(total, this.round_total_pt);
  }

  public totalDiscounts(filter = "*") {
    if (!this.offer_discounts) return 0;

    let sellingPrice = this.totalSellingPrice(filter);
    let newPrice = sellingPrice;
    for (const raw_offer_discount of this.offer_discounts) {
      const d = new OfferDiscount(
        {
          discount_type: raw_offer_discount.discount_type
            ? raw_offer_discount.discount_type
            : OfferDiscountType.PERCENT,
          name: raw_offer_discount.name,
          discount: raw_offer_discount.discount,
          discount_total: raw_offer_discount.discount_total,
        },
        newPrice
      );
      newPrice -= d.discount_total;
    }
    return sellingPrice - newPrice;
  }

  get_billing_discount(situation_index) {
    let sum_discount = 0;
    for (let item of this.get_offer_discounts_situation(situation_index)) {
      sum_discount += item.discount_billing;
    }

    return sum_discount;
  }

  get_offer_discounts_situation(situation_index) {
    let offer_discounts = [];
    let total_billing = this.total_billing_amount(situation_index);
    for (let d of offer_discounts) {
      d.discount_billing = (total_billing * d.discount) / 100;
      d.new_price_billing = total_billing - d.discount_billing;
      total_billing = d.new_price_billing;
      offer_discounts.push(d);
    }

    return offer_discounts;
  }

  get_discount_rate() {
    let total_gross = this.totalSellingPrice();
    if (total_gross) {
      return (1 - this.totalNet() / total_gross) * 100;
    } else {
      return 0;
    }
  }

  get_billing_with_discount(situation_index) {
    return this.total_billing_amount(situation_index) - this.get_billing_discount(situation_index);
  }

  totalSellingAuctionRate(filter = "*") {
    return Math.abs((this.totalSellingPriceWithAuction(filter) / this.totalSellingPrice(filter)) * 100 - 100) || 0;
  }

  totalSellingAuction(filter = "*") {
    let total = 0;
    if (this.offer_item_groups) {
      total += this.offer_item_groups.reduce((tot, item) => tot + +item.totalSellingAuction(filter), 0);
    }
    return total;
  }

  totalSellingPriceWithAuction(filter = "*") {
    let total = 0;
    if (this.offer_item_groups) {
      total += this.offer_item_groups.reduce((tot, item) => tot + +item.totalSellingPriceWithAuction(filter), 0);
    }
    return total;
  }

  total_situation_billing_amount(situationIndex) {
    let total = 0;
    for (let deposit of this.previousDeposits(situationIndex)) {
      total += deposit.gross_total_price;
    }
    let currentDeposit = this.invoices[situationIndex];
    if (currentDeposit) {
      total += currentDeposit.gross_total_price;
    }
    return total >= 0 ? total : 0;
  }

  total_situation_billing_percent(situationIndex) {
    const total_selling = this.totalSellingPriceWithAuction();
    const total_billing = this.total_situation_billing_amount(situationIndex);
    return (total_billing / total_selling) * 100 || 0;
  }

  total_billing_amount(situationIndex, filter = "*") {
    let total = 0;
    if (this.offer_item_groups) {
      total += this.offer_item_groups.reduce(
        (tot, item) => tot + +item.total_billing_amount(situationIndex, filter),
        0
      );
    }
    return total >= 0 ? total : 0;
  }

  total_billing_amount_net(situationIndex, deduct_warranty = false, deduct_deposit = false, filter = "*") {
    let total = this.total_billing_amount(situationIndex, filter);
    for (const offerDiscount of this.offer_discounts) {
      const discount = (total * offerDiscount.discount) / 100;
      total = total - discount;
    }
    if (deduct_warranty) {
      total -= this.warranty_billing(situationIndex, filter);
    }
    if (deduct_deposit) {
      for (const i of this.previousDeposits(situationIndex)) {
        total -= i.gross_total_price;
      }
    }
    return total;
  }

  total_billing_vat(situationIndex, deduct_warranty = false, deduct_deposit = false, filter = "*") {
    return round(
      this.total_billing_amount_net(situationIndex, deduct_warranty, deduct_deposit, filter) * (this.vat_rate / 100),
      2
    );
  }

  total_billing_amount_net_with_vat(situationIndex, deduct_warranty = false, deduct_deposit = false, filter = "*") {
    return roundToNearestCents(
      this.total_billing_amount_net(situationIndex, deduct_warranty, deduct_deposit, filter) +
        this.total_billing_vat(situationIndex, deduct_warranty, deduct_deposit, filter),
      this.round_total_pt
    );
  }

  total_billing_percent(situationIndex) {
    const total_selling = this.totalSellingPrice();
    const total_billing = this.total_billing_amount(situationIndex);
    return (total_billing / total_selling) * 100 || 0;
  }

  total_cost_price_with_discount_goal(filter = "*") {
    let total = 0;
    if (this.offer_item_groups) {
      total += this.offer_item_groups.reduce((tot, item) => tot + +item.totalcost_priceWithDiscount(filter), 0);
    }
    return total;
  }

  margin_base_net(deduct_warranty = false, filter = "*") {
    return this.totalNet(deduct_warranty, filter) / this.totalCostPrice(filter);
  }

  margin_goal_net(deduct_warranty = false, filter = "*") {
    return this.totalNet(deduct_warranty, filter) / this.total_cost_price_with_discount_goal(filter);
  }

  warranty_total(filter = "*") {
    return (this.totalNet(false, filter) * this.warranty_rate) / 100;
  }

  warranty_billing(situationIndex, filter = "*") {
    return (this.total_billing_amount_net(situationIndex, false, false, filter) * this.warranty_rate) / 100;
  }

  previousDeposits(situationIndex): Invoice[] {
    let invoices = [];
    for (let i = 0; i < situationIndex; i++) {
      const invoice = this.invoices[i];
      if (invoice.status !== InvoiceStatus.CANCELLED) {
        invoices.push(invoice);
      }
    }
    return invoices;
  }

  public static createInstances(jsonData: any[]): Offer[] {
    return jsonData.map(Offer.createInstance);
  }

  public static createInstance(jsonData: any): Offer {
    const o = Object.assign(new Offer(), jsonData);
    if (jsonData.assets) {
      o.assets = Asset.createInstances(jsonData.assets);
    }
    if (jsonData.contacts) {
      o.contacts = Contact.createInstances(jsonData.contacts);
    }
    o.invoices = o.invoices?.map(Invoice.createInstance);
    o.call_to_tenders = o.call_to_tenders?.map(CallToTender.createInstance);
    if (o.offer_finish_deadline) {
      o.offer_finish_deadline = new Date(jsonData.offer_finish_deadline);
    }
    if (o.start_workdate) {
      o.start_workdate = new Date(jsonData.start_workdate);
    }
    if (o.end_workdate) {
      o.end_workdate = new Date(jsonData.end_workdate);
    }
    if (o.pdf_config) {
      o.pdf_config = OfferPdfConfig.createInstance(jsonData.pdf_config);
    }

    o.offer_discounts = [];
    o.offer_item_groups = OfferItemGroup.createInstances(jsonData.offer_item_groups, o.offer_discounts);

    let newPrice = o.totalSellingPrice("*");
    if (jsonData.offer_discounts) {
      for (const raw_offer_discount of jsonData.offer_discounts) {
        const d = new OfferDiscount(
          {
            discount_type: raw_offer_discount.discount_type
              ? raw_offer_discount.discount_type
              : OfferDiscountType.PERCENT,
            name: raw_offer_discount.name,
            discount: raw_offer_discount.discount,
            discount_total: raw_offer_discount.discount_total,
          },
          newPrice
        );
        newPrice = newPrice - d.discount_total;
        o.offer_discounts.push(d);
      }
    }

    o.setOfferDiscounts(o.offer_discounts);

    o.created_on = jsonData.created_on ? new Date(jsonData.created_on) : null;
    o.updated_on = jsonData.updated_on ? new Date(jsonData.updated_on) : null;
    o.sent_on = jsonData.sent_on ? new Date(jsonData.sent_on) : null;
    o.employee = jsonData.employee ? Employee.createInstance(jsonData.employee) : null;
    o.created_by_employee = jsonData.created_by_employee ? Employee.createInstance(jsonData.created_by_employee) : null;
    o.updated_by_employee = jsonData.updated_by_employee ? Employee.createInstance(jsonData.updated_by_employee) : null;
    o.sent_by_employee = jsonData.sent_by_employee ? Employee.createInstance(jsonData.sent_by_employee) : null;
    o.static_total_net = jsonData.static_total_net;
    o.sent_histories = jsonData.sent_histories ? OfferSend.createInstances(o.sent_histories) : [];

    return o;
  }

  public static getDateWithoutTime(input): Date {
    if (!input) return null;
    let dt = new Date(input);
    dt.setHours(0);
    dt.setMinutes(0);
    dt.setSeconds(0);
    dt.setMilliseconds(0);
    return dt;
  }

  public get_suppliers() {
    let suppliers = [];
    for (const group of this.offer_item_groups) {
      suppliers = suppliers.concat(group.get_suppliers());
    }
    return Array.from(new Set(suppliers));
  }
}
