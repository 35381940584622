import { ObjectId } from "src/app/shared/helpers/objectid.helper";

export class InterventionAction {
  intervention_action_id: string = ObjectId()();
  label: string = "Nouvelle action";
  checked: boolean = false;

  public static createInstance(jsonData: InterventionAction): InterventionAction {
    const d = Object.assign(new InterventionAction(), jsonData);
    return d;
  }

  public static createInstances(jsonData: any[]): InterventionAction[] {
    return jsonData.map(InterventionAction.createInstance);
  }
  clone(resetObjId = false): InterventionAction {
    let d = Object.assign(new InterventionAction(), this);
    if (resetObjId) {
      d.intervention_action_id = ObjectId()();
    }
    return d;
  }
}
