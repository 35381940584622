export class MealBreak {
  meal_break_id: any;
  start_date: Date;
  amount: number;

  public static createInstance(jsonData: any): MealBreak {
    const i = Object.assign(new MealBreak(), jsonData);
    if (i.start_date) {
      i.start_date = new Date(i.start_date);
    }

    return i;
  }

  public static createInstances(jsonData: any[]): MealBreak[] {
    return jsonData.map(MealBreak.createInstance);
  }
}
