import * as moment from "moment";

export function dateWithoutTime(d: Date): Date {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
}

export function convertLocalDateToUTCIgnoringTimezone(date: Date): Date {
  if (!date) return date;
  const timestamp = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  );
  return new Date(timestamp);
}

export function convertUTCToLocalDateIgnoringTimezone(utcDate: Date): Date {
  return new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate(),
    utcDate.getUTCHours(),
    utcDate.getUTCMinutes(),
    utcDate.getUTCSeconds(),
    utcDate.getUTCMilliseconds()
  );
}

export function getWeekStartDate(year: number, week: number): Date {
  return moment().year(year).isoWeek(week).startOf("isoWeek").toDate();
}

export function addMonthToDate(inputDate: Date, monthsToAdd: number): Date {
  return moment(inputDate).add(monthsToAdd, "months").toDate();
}

export function getTodayUtc() {
  let today = new Date();
  return new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0));
}

export function addWeeksToDate(inputDate: Date, weeksToAdd: number): Date {
  return moment(inputDate).add(weeksToAdd, "weeks").toDate();
}

export function getDateFromObjectId(objectId: string): Date {
  const timestamp = parseInt(objectId.substring(0, 8), 16);
  return new Date(timestamp * 1000);
}
