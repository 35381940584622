// services
import { EvaCompanyService } from "src/app/features/eva-company/services/evaCompany.service";

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";

@Injectable({ providedIn: "root" })
export class ProdGuard implements CanActivate {
  constructor(private evaCompanyService: EvaCompanyService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.evaCompanyService.hasProdRights();
  }
}
