<div class="main-content">
  <router-outlet></router-outlet>
</div>
<!-- <footer class="py-5 static">
  <div class="container">
    <div class="row align-items-center justify-content-xl-between">
      <div class="col">
        <div class="copyright text-center text-xl-center text-muted">
          &copy; EVA 2020
        </div>
      </div>
    </div>
  </div>
</footer> -->
