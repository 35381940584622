export class PaymentCondition {
  id: string;
  days: number;
  discount_rate: number;

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.days = json.days;
      this.discount_rate = json.discount_rate;
    }
    if (!this.id) {
      // hack : generate random id for table management
      this.id = generateObjectId();
    }
  }

  public static createInstance(jsonData: any): PaymentCondition {
    return Object.assign(new PaymentCondition(), jsonData);
  }

  public static createInstances(jsonData: any[]): PaymentCondition[] {
    return jsonData.map(PaymentCondition.createInstance);
  }
}

function generateObjectId(): string {
  const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
  const randomHex = Array.from({ length: 16 }, () => Math.floor(Math.random() * 16).toString(16)).join("");

  return timestamp + randomHex;
}

export const initPaymentConditions: PaymentCondition[] = [
  new PaymentCondition({
    days: 30,
    discount_rate: 0,
  }),
];
