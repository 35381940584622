import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppStateService {
  private passwordExpiredSource = new BehaviorSubject<boolean>(false);
  passwordExpired$ = this.passwordExpiredSource.asObservable();

  setPasswordExpired(expired: boolean) {
    this.passwordExpiredSource.next(expired);
  }
}
