import { Observable, of } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
// models
import { EmployeeMailSignatures } from "src/app/domains/employee_mail_signature";
import { getFromSessionStorage, setInSessionStorage } from "src/app/shared/helpers/storage.helpers";

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../../environments/environment";
import { Employee } from "../domains/employee";
import { Salary } from "../domains/salary";

@Injectable({
  providedIn: "root",
})
export class EmployeesService {
  url = environment.apiUrl + "/employees";
  urlSalary = environment.apiUrl + "/salaries";
  private _currentEmployee: Employee = null;

  constructor(private http: HttpClient) {}

  public getAllEmployees(): Observable<Employee[]> {
    return this.http.get<Employee[]>(this.url).pipe(map(Employee.createInstances));
  }

  public getEmployeeSignatures(): Observable<EmployeeMailSignatures> {
    return this.http
      .get<EmployeeMailSignatures>(this.url + "/mail-signatures")
      .pipe(map(EmployeeMailSignatures.createInstance));
  }

  public editEmployeeSignatures(employeeMailSignatures: EmployeeMailSignatures): Observable<EmployeeMailSignatures> {
    return this.http
      .put<EmployeeMailSignatures>(this.url + "/mail-signatures", employeeMailSignatures)
      .pipe(map(EmployeeMailSignatures.createInstance));
  }

  public getAllEmployeesLight(): Observable<Employee[]> {
    return this.http.get<Employee[]>(this.url + "/all/light").pipe(map(Employee.createInstances));
  }

  public getAllEnabledEmployees(return_type: "LIGHT" | "FULL" = "LIGHT"): Observable<Employee[]> {
    return this.http
      .get<Employee[]>(this.url + "/enabled", {
        params: { return_type: return_type },
      })
      .pipe(map(Employee.createInstances));
  }

  public getEmployee(id: string): Observable<Employee> {
    return this.http.get<Employee>(this.url + "/" + id).pipe(map(Employee.createInstance));
  }

  public getMyProfile(id: string): Observable<Employee> {
    return this.http.get<Employee>(this.url + "/" + id + "/my-profile").pipe(map(Employee.createInstance));
  }

  public getEmployeeFull(id: string): Observable<Employee> {
    return this.http.get<Employee>(this.url + "/full/" + id).pipe(map(Employee.createInstance));
  }

  public getAvatar(id: string): Observable<any> {
    return this.http.get(this.url + "/" + id + "/avatar").pipe(shareReplay(1));
  }

  public async currentEmployeeAsync(): Promise<Employee> {
    if (this._currentEmployee) return this._currentEmployee;
    this._currentEmployee = await this.getCurrentEmployee().toPromise();
    return this._currentEmployee;
  }

  public getCurrentEmployee(fromCache = true): Observable<Employee> {
    const storedEmployee = getFromSessionStorage("employeeMe");

    if (fromCache && storedEmployee && this.isRecent(storedEmployee.timestamp)) {
      // Return the stored employee if the timestamp is within the last hour
      return of(Employee.createInstance(storedEmployee.data));
    } else {
      // Fetch a new employee from the API and update session storage
      return this.http.get<Employee>(this.url + "/me").pipe(
        map((employee) => {
          const employeeData = {
            timestamp: new Date().getTime(),
            data: employee,
          };
          setInSessionStorage(employeeData, "employeeMe");
          return Employee.createInstance(employee);
        })
      );
    }
  }

  private isRecent(timestamp: number): boolean {
    const now = new Date().getTime();
    const oneHour = 1000 * 60 * 60;
    return now - timestamp < oneHour;
  }

  public addEmployee(employee: Employee): Observable<Employee> {
    return this.http.post<Employee>(this.url, employee).pipe(map(Employee.createInstance));
  }

  public sendNewEmployeeMail(employee: Employee): Observable<Employee> {
    return this.http
      .post<Employee>(this.url + "/" + employee.employee_id + "/send-mail-creation", employee)
      .pipe(map(Employee.createInstance));
  }

  public editEmployee(
    employee: Employee,
    external_data = false,
    year_start = 0,
    year_end = 0,
    start_month = 0,
    end_month = 0
  ): Observable<Employee> {
    return this.http
      .put<Employee>(
        this.url +
          "/" +
          employee.employee_id +
          "?external_data=" +
          external_data.toString() +
          "&year_start=" +
          year_start.toString() +
          "&year_end=" +
          year_end.toString() +
          "&start_month=" +
          start_month.toString() +
          "&end_month=" +
          end_month.toString(),
        employee
      )
      .pipe(map(Employee.createInstance));
  }
  public editMyProfile(employee: Employee): Observable<Employee> {
    return this.http
      .put<Employee>(this.url + "/" + employee.employee_id + "/my-profile", employee)
      .pipe(map(Employee.createInstance));
  }

  public disableEmployee(
    employee_id: string,
    external_data = false,
    delete_future_activities = false
  ): Observable<Employee> {
    return this.http
      .put<Employee>(
        this.url +
          "/" +
          employee_id +
          "/disable" +
          "?external_data=" +
          external_data.toString() +
          "&delete_future_activities=" +
          delete_future_activities.toString(),
        {}
      )
      .pipe(map(Employee.createInstance));
  }

  public updateWorkingActivities(employee_id: string): Observable<Employee> {
    return this.http
      .put<Employee>(this.url + "/" + employee_id + "/update-working-activities", {})
      .pipe(map(Employee.createInstance));
  }

  public updateSendNotificationPlanning(employee_id: string, send_notif_planning: Boolean): Observable<any> {
    return this.http.patch<any>(this.url + "/" + employee_id + "/send_notif_planning", {
      send_notif_planning,
    });
  }

  retrocativeUpdateHourCost(employee_id, position): Observable<Employee> {
    return this.http
      .put<Employee>(this.url + "/" + employee_id + "/retrocative-update-hour-cost", position)
      .pipe(map(Employee.createInstance));
  }

  //Salaries

  public addSalary(salary: Salary): Observable<Salary> {
    return this.http.post<Salary>(this.urlSalary, salary).pipe(map(Salary.createInstance));
  }

  public createYearSalary(year: number): Observable<Salary[]> {
    return this.http.post<Salary[]>(this.urlSalary + "/generate/" + year, year).pipe(map(Salary.createInstances));
  }

  public editSalary(salary: Salary): Observable<Salary> {
    return this.http.put<Salary>(this.urlSalary + "/" + salary.salary_id, salary).pipe(map(Salary.createInstance));
  }

  public getSalary(id: string): Observable<Salary> {
    return this.http.get<Salary>(this.urlSalary + "/" + id).pipe(map(Salary.createInstance));
  }

  public getEmployeeSalaries(
    id: string,
    start_date = null,
    end_date = null,
    filter_status = null
  ): Observable<Salary[]> {
    let params = new HttpParams();
    if (start_date) {
      params = params.append("start_date", start_date);
    }
    if (end_date) {
      params = params.append("end_date", end_date);
    }
    if (filter_status) {
      params = params.append("filter_status", filter_status);
    }
    return this.http
      .get<Salary[]>(this.urlSalary + "/employee/" + id, { params: params })
      .pipe(map(Salary.createInstances));
  }

  public getYearSalaries(year: number): Observable<Salary[]> {
    return this.http.get<Salary[]>(this.urlSalary + "/year/" + year).pipe(map(Salary.createInstances));
  }

  public getAllSalaries(): Observable<Salary[]> {
    return this.http.get<Salary[]>(this.urlSalary).pipe(map(Salary.createInstances));
  }

  public getAllSalariesToPay(): Observable<Salary[]> {
    return this.http.get<Salary[]>(this.urlSalary + "/to-pay").pipe(map(Salary.createInstances));
  }

  public addYearMonthEmployeeSalaries(
    id: string,
    year: number,
    start_month: number = 0,
    end_month: number = 0
  ): Observable<Salary[]> {
    return this.http
      .post<Salary[]>(this.urlSalary + "/" + id + "/" + year, [new Salary()])
      .pipe(map(Salary.createInstances));
  }

  public changeEmail(employeeId: string, newEmail: string): Observable<any> {
    return this.http.post<any>(this.url + "/" + employeeId + "/change-email", { new_email: newEmail });
  }

  public changePassword(employeeId: string, newPassword: string): Observable<any> {
    return this.http.post<any>(this.url + "/" + employeeId + "/change-password", { new_password: newPassword });
  }
}
