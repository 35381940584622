import { IntercomModule } from "ng-intercom";
import { NgxSpinnerModule } from "ngx-spinner";
import { AccordionModule } from "primeng/accordion";
import { MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { ChartModule } from "primeng/chart";
import { ToastModule } from "primeng/toast";
import Quill from "quill";
import ImageResize from "quill-image-resize-module";

import { CommonModule, CurrencyPipe, DatePipe, registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import localeFrExtra from "@angular/common/locales/extra/fr";
import localeFr from "@angular/common/locales/fr";
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router, RouterModule } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import * as Sentry from "@sentry/angular";

import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";
import { CoreModule } from "./core/core.module";
import { AuthInterceptor } from "./helpers/auth.interceptor";
import { ErrorInterceptor } from "./helpers/error.interceptor";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { JsonDateInterceptor } from "./services/json-date-interceptor";

Quill.register("modules/imageResize", ImageResize);

registerLocaleData(localeFr, "fr-FR", localeFrExtra);

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    AppRoutingModule,
    NgxSpinnerModule,
    RouterModule,
    AccordionModule,
    ChartModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    ToastModule,
    ButtonModule,
    IntercomModule.forRoot({
      appId: environment.intercomId, // from your Intercom config
      updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
    }),
  ],
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent],
  providers: [
    { provide: DEFAULT_CURRENCY_CODE, useValue: "CHF" },
    { provide: LOCALE_ID, useValue: "fr-CH" }, //your locale
    { provide: HTTP_INTERCEPTORS, useClass: JsonDateInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: DatePipe },
    { provide: CurrencyPipe },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
