<div class="main-content">
  <!-- Top navbar -->
  <app-navbar></app-navbar>
  <!-- Pages -->
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>

<div style="position: relative">
  <ngx-spinner bdColor="rgba(0,0,0,0.8)" size="large" color="#ffffff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white">Chargement...</p>
  </ngx-spinner>
</div>
