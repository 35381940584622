import { Asset, Invoice, InvoiceStatus } from "src/app/domains";
import { EvaAddress } from "src/app/domains/address";
import { initPaymentConditions, PaymentCondition } from "src/app/domains/payment-condition";
import { Phone } from "src/app/domains/phone";

import { Contact } from "../../contact/domains/contact";
import { Tag } from "../../eva-company/domains/eva-company-tag";
import { PurchaseOrder, PurchaseOrderStatus } from "../../purchase-order/domains/purchase-order";

export enum SupplierStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export enum SupplierRelationType {
  SUPPLIER = "SUPPLIER",
  SUBCONTRACTOR = "SUBCONTRACTOR",
  ADMINISTRATION = "ADMINISTRATION",
}

export interface ISupplier {
  supplier_id: string;
  supplier_corporate_id: string;
  eva_company_id: string;
  name: string;
  address: string;
  contact: string;
  internal_notes: string;
  purchase_discount: number;
  status: SupplierStatus;
  relation: SupplierRelationType;
  contacts_ids: string[];
  credits_notes_ids: string[];
  addresses: EvaAddress[];
  tags_ids: string[];
  tags: Tag[];
  local_company_id: string;
  vat_number: string;
  supplier_address: EvaAddress;
  phone_office: string;
  mail_office: string;
  country: string;
  payment_conditions: PaymentCondition[];
  send_notif: boolean;
  notif_emails: string[];

  office_phone_details: Phone;
  metadata: any;
  assets: Asset[];

  purchase_orders: PurchaseOrder[];
  contacts: Contact[];
  invoices: Invoice[];
}

export class Supplier implements ISupplier {
  supplier_id: string;
  supplier_corporate_id: string;
  eva_company_id: string;
  name: string = "";
  address: string = "";
  contact: string = "";
  country: string = "";
  internal_notes: string = "";
  purchase_discount: number = 0.0;
  status: SupplierStatus;
  relation: SupplierRelationType = SupplierRelationType.SUPPLIER;
  contacts: Contact[] = [];
  addresses: EvaAddress[] = [];
  tags_ids: string[];
  tags: Tag[] = [];
  local_company_id: string = "";
  vat_number: string = "";
  contacts_ids: string[];
  credits_notes_ids: string[];
  supplier_address: EvaAddress = new EvaAddress();
  phone_office: string = "";
  mail_office: string = "";

  office_phone_details: Phone;
  metadata = {};
  assets: Asset[];

  payment_conditions: PaymentCondition[] = initPaymentConditions;
  send_notif: boolean = false;
  notif_emails: string[] = [];

  purchase_orders: PurchaseOrder[] = [];
  invoices: Invoice[] = [];

  constructor() {
    this.status = SupplierStatus.ENABLED;
    this.country = "CH";
  }

  excelExport(financialData = false) {
    let supplier_json = {
      id: this.supplier_corporate_id,
      name: this.name,
      address: this.address,
      contact: this.contact,
      status: this.status,
    };
    if (financialData) {
      let financial_data = {
        purchase_discount: this.paymentConditionsText,
        totalOrdersValue: this.totalOrdersValue,
        currentYearOrdersValue: this.currentYearOrdersValue,
        totalInvoicesValue: this.totalInvoicesValue,
        supplierInvoiceBalance: this.supplierInvoiceBalance,
        leftToPay: this.leftToPay,
      };
      supplier_json = {
        ...supplier_json,
        ...financial_data,
      };
    }
    return supplier_json;
  }

  public static createInstance(jsonData: ISupplier): Supplier {
    let s = Object.assign(new Supplier(), jsonData);

    if (jsonData.purchase_orders) {
      s.purchase_orders = jsonData.purchase_orders.map(PurchaseOrder.createInstance);
    }

    if (jsonData.supplier_address) {
      s.supplier_address = EvaAddress.createInstance(jsonData.supplier_address);
    }
    if (jsonData.addresses) {
      s.addresses = EvaAddress.createInstances(jsonData.addresses);
    }

    if (jsonData.invoices) {
      s.invoices = Invoice.createInstances(jsonData.invoices);
    }

    if (jsonData.tags) {
      s.tags = Tag.createInstances(jsonData.tags);
    }
    if (jsonData.contacts) {
      s.contacts = Contact.createInstances(jsonData.contacts);
    } else s.contacts = [];

    if (jsonData.office_phone_details) {
      s.office_phone_details = Phone.createInstance(jsonData.office_phone_details);
    }
    if (jsonData.payment_conditions) {
      s.payment_conditions = PaymentCondition.createInstances(jsonData.payment_conditions);
    }

    if (!s.payment_conditions || s.payment_conditions.length === 0) {
      s.payment_conditions = initPaymentConditions;
    }

    if (jsonData.assets) {
      s.assets = Asset.createInstances(jsonData.assets);
    } else s.assets = [];

    return s;
  }
  public static createInstances(jsonData: ISupplier[]): Supplier[] {
    return jsonData.map(Supplier.createInstance);
  }

  clone(): Supplier {
    let s = Object.assign(new Supplier(), this);
    s.purchase_orders = PurchaseOrder.createInstances(s.purchase_orders);
    return s;
  }

  get validInvoices(): Invoice[] {
    if (this.purchase_orders) {
      return this.purchase_orders
        .flatMap((x) => x.invoices)
        .filter((x) => x)
        .filter((x) => ![InvoiceStatus.CANCELLED, InvoiceStatus.DRAFT].includes(x.status));
    } else {
      return [];
    }
  }

  get validPurchaseOrders(): PurchaseOrder[] {
    if (this.purchase_orders) {
      return this.purchase_orders.filter((x) =>
        [PurchaseOrderStatus.ON_GOING, PurchaseOrderStatus.FINISHED].includes(x.status)
      );
    } else {
      return [];
    }
  }
  get onGoingPurchaseOrders(): PurchaseOrder[] {
    if (this.purchase_orders) {
      return this.purchase_orders.filter((x) => x.status === PurchaseOrderStatus.ON_GOING);
    } else {
      return [];
    }
  }
  get totalOrdersValue(): number {
    return this.validPurchaseOrders.reduce((tot, item) => tot + item.confirmation_gross_price, 0);
  }
  get currentYearOrdersValue(): number {
    const year = new Date().getFullYear();
    return this.validPurchaseOrders
      .filter((x) => x.created_on?.getFullYear() === year)
      .reduce((tot, item) => tot + item.confirmation_gross_price, 0);
  }

  get totalInvoicesValue(): number {
    return this.validInvoices.reduce((tot, item) => tot + item.gross_total_price, 0);
  }

  get supplierInvoiceBalance(): number {
    return this.totalOrdersValue - this.totalInvoicesValue;
  }
  get leftToPay(): number {
    let sum = 0.0;
    for (let po of this.validPurchaseOrders) {
      sum += po.leftToPay;
    }
    return sum;
  }
  get paymentConditionsText(): string {
    let text = "";
    for (let pc of this.payment_conditions) {
      text += pc.days + "j : " + (pc.discount_rate == 0 ? "net" : pc.discount_rate + "%") + "; ";
    }
    return text;
  }
}
