export enum EvaLang {
  FR = "fr",
  EN = "en",
  DE = "de",
  IT = "it",
  ES = "es",
  PT = "pt",
  RO = "ro",
  VI = "vi",
}

export enum EvaLangLabel {
  FR = "Français",
  EN = "Anglais",
  DE = "Allemand",
  IT = "Italien",
  ES = "Espagnol",
  PT = "Portugais",
  RO = "Roumain",
  VI = "Vietnamien",
}

export const LangOptions = [
  { value: EvaLang.FR, label: EvaLangLabel.FR },
  { value: EvaLang.EN, label: EvaLangLabel.EN },
  { value: EvaLang.DE, label: EvaLangLabel.DE },
  { value: EvaLang.IT, label: EvaLangLabel.IT },
  { value: EvaLang.ES, label: EvaLangLabel.ES },
  { value: EvaLang.PT, label: EvaLangLabel.PT },
  { value: EvaLang.RO, label: EvaLangLabel.RO },
  { value: EvaLang.VI, label: EvaLangLabel.VI },
];
