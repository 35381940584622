import { SupplierCallToTender } from "src/app/domains/supplier-call-to-tender";

export enum CallToTenderStatus {
  DRAFT = "DRAFT",
  CANCELLED = "CANCELLED",
  SUBMITTED = "SUBMITTED",
  FINISHED = "FINISHED",
}

export enum CallToTenderStatusBadgeClass {
  DRAFT = "badge badge-warning",
  CANCELLED = "badge badge-danger",
  SUBMITTED = "badge badge-success",
  FINISHED = "badge badge-success",
}

export enum CallToTenderStatusLabel {
  DRAFT = "BROUILLON",
  CANCELLED = "ABANDONNÉ",
  SUBMITTED = "ENVOYÉ",
  FINISHED = "TERMINÉ",
}

export class CallToTender {
  call_to_tender_id: string;
  call_to_tender_corporate_id: string;
  title: string;
  message: string;
  attachments_ids: string[];
  suppliers_calls: SupplierCallToTender[];
  supplier_answer_deadline: Date;
  status: CallToTenderStatus;

  created_on: Date;
  created_by: string;

  updated_on: Date;
  updated_by: string;

  submitted_on: Date;
  submitted_by: string;

  cancelled_on: Date;
  cancelled_by: string;

  cancel_comment_public: string;
  cancel_comment_internal: string;

  finished_on: Date;
  finished_by: string;

  finish_comment_public: string;
  finish_comment_internal: string;

  constructor() {
    this.suppliers_calls = [new SupplierCallToTender()];
  }

  public static createInstance(jsonData: any): CallToTender {
    const ctt = Object.assign(new CallToTender(), jsonData);
    ctt.suppliers_calls = ctt.suppliers_calls?.map(SupplierCallToTender.createInstance);
    ctt.created_on = jsonData.created_on ? new Date(jsonData.created_on) : null;
    ctt.updated_on = jsonData.updated_on ? new Date(jsonData.updated_on) : null;
    ctt.submitted_on = jsonData.submitted_on ? new Date(jsonData.submitted_on) : null;
    ctt.cancelled_on = jsonData.cancelled_on ? new Date(jsonData.cancelled_on) : null;
    ctt.supplier_answer_deadline = jsonData.supplier_answer_deadline
      ? new Date(jsonData.supplier_answer_deadline)
      : null;
    return ctt;
  }
}
