import { PeriodClosed } from "./period-closed";

export class HrYearlyConfig {
  hr_yearly_config_id: string = "";
  eva_company_id: string = "";
  year: number;
  period_closed: PeriodClosed[] = [];

  public static createInstance(jsonData: HrYearlyConfig): HrYearlyConfig {
    const c = Object.assign(new HrYearlyConfig(), jsonData);

    if (jsonData.period_closed) {
      c.period_closed = PeriodClosed.createInstances(jsonData.period_closed);
    }

    return c;
  }

  public static createInstances(jsonData: any[]): HrYearlyConfig[] {
    return jsonData.map(HrYearlyConfig.createInstance);
  }

  clone(): HrYearlyConfig {
    let c = Object.assign(new HrYearlyConfig(), this);
    c.period_closed = PeriodClosed.createInstances(c.period_closed);
    return c;
  }
}
