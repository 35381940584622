import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { AuthenticationService } from "../services/authentication.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        // need to relog on 403
        if (err.status == 403 && request.url.includes("employees/me")) {
          this.authenticationService.logout();
          location.reload();
        }

        return throwError(() => err.error);
      })
    );
  }
}
