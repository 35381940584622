import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Employee, EvaCompany, User } from "src/app/domains/internal";
import { environment } from "src/environments/environment";
import { EvaCompanyService } from "src/app/features/eva-company/services/evaCompany.service";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User> = null;
  private newUserMail: any;

  public jwt: string;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem("evaUser")));
    
    this.currentUser = this.currentUserSubject.asObservable();

    this.jwt = localStorage.getItem("evaJwt");
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  getCurrentUser(): User {
    if ("evaUser" in localStorage) {
      return JSON.parse(localStorage.getItem("evaUser"));
    } else {
      this.router.navigate(["/login"]);
      return null;
    }
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/auth/v2/login`, {
        email: username,
        password,
      })
  }

  onBoardingFirstlogin(evaCompany: EvaCompany, employee: Employee) {
    return this.http
      .post<any>(`${environment.apiUrl}/auth/login/on_boarding`, {
        evaCompany: evaCompany,
        employee: employee,
      })
      .pipe(
        map((res) => {
          localStorage.setItem("evaJwt", res.token);
          this.jwt = res.token;
          const user = User.createInstance(res.user);
          localStorage.setItem("evaUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          this.clearStorage("currentEvaCompany");
          this.clearStorage("currentEvaCompaniesAvailable");
          this.router.navigate(["/login"]);
        })
      );
  }

  askSignUp(email) {
    return this.http.post(`${environment.apiUrl}/auth/signup`, {
      email: email,
    });
  }
  searchSwissCompanies(name) {
    return this.http.post(`${environment.apiUrl}/auth/search_swiss_company`, {
      name: name,
    });
  }

  requestPasswordReset(email: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/ask_reset_password`, { email });
  }

  getSwissCompanyDetails(uid: string) {
    return this.http.get(`${environment.apiUrl}/auth/search_swiss_company/${uid}`);
  }
  searchFrenchCompanies(name) {
    return this.http.post(`${environment.apiUrl}/auth/search_french_company`, {
      denominationUniteLegale: name,
    });
  }

  validateToken(email, validationCode) {
    return this.http
      .post(`${environment.apiUrl}/auth/email_token/validate`, {
        validationCode: validationCode,
        email: email,
      })
      .pipe(
        map((res: any) => {
          localStorage.setItem("evaJwt", res.token);
          this.jwt = res.token;
        })
      );
  }

  validateLoginOtp(email: string, otp: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/login/validate_otp`, {
      email: email,
      validationCode: otp
    }).pipe(
      tap((res: any) => {
        if (res.access_token && res.refresh_token) {
          localStorage.setItem("evaJwt", res.access_token);
          localStorage.setItem("evaRefreshJwt", res.refresh_token);
          this.jwt = res.access_token;
          const user = User.createInstance(res.user);
          localStorage.setItem("evaUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          this.clearStorage("currentEvaCompany");
          this.clearStorage("currentEvaCompaniesAvailable");
        }
      })
    );
  }

  createUser(user: User): Observable<User> {
    return this.http.post<User>(`${environment.apiUrl}/auth/register_user`, user).pipe(map(User.createInstance));
  }

  logout(reason: string = "") {
    localStorage.clear();
    sessionStorage.clear();
    if (reason === "passwordExpired") {
      localStorage.setItem("LOGOUT_PASSWORD_EXPIRED", "true");
    }
    this.currentUserSubject.next(null);
  }

  clearStorage(key: string) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }

  setUserMail(data: any) {
    this.newUserMail = data;
  }

  getUserMail() {
    return this.newUserMail;
  }
  refreshToken() {
    return this.http.post<any>(`${environment.apiUrl}/auth/refresh`, {}).pipe(
      map((res) => {
        localStorage.setItem("evaJwt", res.token);
        return res.token;
      })
    );
  }
}
