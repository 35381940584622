import { ObjectId } from "src/app/shared/helpers/objectid.helper";

export class InterventionMaterial {
  intervention_material_id: string = ObjectId()();
  name: string = "Nouveau matériel";
  characteristics: string = "";

  public static createInstance(jsonData: InterventionMaterial): InterventionMaterial {
    const d = Object.assign(new InterventionMaterial(), jsonData);
    return d;
  }

  public static createInstances(jsonData: any[]): InterventionMaterial[] {
    return jsonData.map(InterventionMaterial.createInstance);
  }
  clone(): InterventionMaterial {
    let d = Object.assign(new InterventionMaterial(), this);
    return d;
  }
}
