export class User {
  user_id: string;
  password: string;
  first_name: string;
  last_name: string;
  user_type: string;
  employee_type: string;
  phone: string;
  avatar: string;
  created_on: Date;
  created_by: string;
  updated_on: Date;
  updated_by: string;

  constructor() {}

  public static createInstance({
    user_id,
    password,
    user_type,
    first_name,
    last_name,
    employee_type,
    phone,
    created_on,
  }): User {
    const u = new User();
    u.user_id = user_id;
    u.password = password;
    u.user_type = user_type;
    u.first_name = first_name;
    u.last_name = last_name;
    u.employee_type = employee_type;
    u.phone = phone;
    u.created_on = created_on;
    return u;
  }

  get display_name(): string {
    return this.first_name + " " + this.last_name;
  }
}
