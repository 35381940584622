<div (click)="hideOverlayByClick()">
  <br />
  <div *ngIf="loading && searchItems.length === 0" class="loading-icon">CHARGEMENT....</div>
  <div *ngIf="!loading && searchItems.length === 0" class="loading-icon">
    Pas de résultat. Veuillez réessayer avec d'autres mots clés.
  </div>
  <!-- OFFER-->
  <div *ngIf="resultOffers.length > 0">
    <h5>
      <span> <i class="ni ni-archive-2 text-primary"></i></span><span> Offres</span>
    </h5>
    <div *ngFor="let resultOffer of resultOffers">
      <a [routerLink]="[resultOffer.object_link]">{{ resultOffer.object_label }}</a>
    </div>
  </div>

  <!-- INTERVENTION-->
  <div *ngIf="resultInterventions.length > 0">
    <h5>
      <span> <i class="ni ni-briefcase-24 text-primary"></i></span><span> Interventions</span>
    </h5>
    <div *ngFor="let resultIntervention of resultInterventions">
      <a [routerLink]="[resultIntervention.object_link]">{{ resultIntervention.object_label }}</a>
    </div>
  </div>
  <!-- CONTRACT-->
  <div *ngIf="resultContracts.length > 0">
    <h5>
      <span> <i class="ni ni-briefcase-24 text-primary"></i></span><span> Contrats</span>
    </h5>
    <div *ngFor="let resultContract of resultContracts">
      <a [routerLink]="[resultContract.object_link]">{{ resultContract.object_label }} </a>
    </div>
  </div>

  <!-- INSTALLATION-->

  <!-- Doesnt work that way anymore since the object isnt stored -->

  <!-- <div *ngIf="resultContracts.length > 0">
    <h5>
      <span> <i class="ni ni-briefcase-24 text-primary"></i></span><span> Installations</span>
    </h5>
    <div *ngFor="let resultContract of resultContracts">
      <div *ngFor="let installation of resultContract.contract.installations">
        <a
          *ngIf="installation.status === 'ENABLED'"
          [routerLink]="[
            resultContract.object_link
          ]"
          >{{ installation.object_label }}
        </a>
      </div>
    </div>
  </div> -->

  <!-- CUSTOMER -->

  <div *ngIf="resultCustomers.length > 0">
    <h5><i class="ni ni-building text-primary"></i><span> Clients</span></h5>
    <div *ngFor="let resultCustomer of resultCustomers">
      <a [routerLink]="[resultCustomer.object_link]"> {{ resultCustomer.object_label }}</a>
    </div>
  </div>

  <!-- PURCHASE_ORDER -->

  <div *ngIf="resultPurchaseOrders.length > 0">
    <h5><i class="ni ni-planet text-blue"></i><span> Bons de commande</span></h5>
    <div *ngFor="let resultPurchaseOrder of resultPurchaseOrders">
      <a [routerLink]="[resultPurchaseOrder.object_link]">
        {{ resultPurchaseOrder.object_label }}
      </a>
    </div>
  </div>

  <!-- PROJECT -->

  <div *ngIf="resultProjects.length > 0">
    <h5><i class="ni ni-settings text-primary"></i><span> Chantiers</span></h5>
    <div *ngFor="let resultProject of resultProjects">
      <a [routerLink]="[resultProject.object_link]">
        {{ resultProject.object_label }}
      </a>
    </div>
  </div>

  <!-- INVOICE RECEIVED-->

  <div *ngIf="resultInvoicesReceived.length > 0">
    <h5><i class="ni ni-bullet-list-67 text-primary"></i><span> Factures reçues</span></h5>
    <div *ngFor="let resultInvoiceReceived of resultInvoicesReceived">
      <a [routerLink]="[resultInvoiceReceived.object_link]">
        {{ resultInvoiceReceived.object_label }}
      </a>
    </div>
  </div>

  <!-- INVOICE ISSUED-->

  <div *ngIf="resultInvoicesIssued.length > 0">
    <h5><i class="ni ni-bullet-list-67 text-primary"></i><span> Factures émises</span></h5>
    <div *ngFor="let resultInvoiceIssued of resultInvoicesIssued">
      <a [routerLink]="[resultInvoiceIssued.object_link]">
        {{ resultInvoiceIssued.object_label }}
      </a>
    </div>
  </div>

  <!-- SUPPLIER -->

  <div *ngIf="resultSuppliers.length > 0">
    <h5><i class="ni ni-basket text-primary"></i><span> Fournisseur</span></h5>
    <div *ngFor="let resultSupplier of resultSuppliers">
      <a [routerLink]="[resultSupplier.object_link]">
        {{ resultSupplier.object_label }}
      </a>
    </div>
  </div>

  <!-- EMPLOYEE -->

  <div *ngIf="resultEmployees.length > 0">
    <h5><i class="ni ni-circle-08 text-pink"></i><span> Collaborateur</span></h5>
    <div *ngFor="let resultEmployee of resultEmployees">
      <a [routerLink]="[resultEmployee.object_link]">
        {{ resultEmployee.object_label }}
      </a>
    </div>
  </div>
  <!-- CONTACT -->

  <div *ngIf="resultContacts.length > 0">
    <h5><i class="ni ni-circle-08 text-pink"></i><span> Contact</span></h5>
    <div *ngFor="let resultContact of resultContacts">
      <a [routerLink]="[resultContact.object_link]">
        {{ resultContact.object_label }}
      </a>
    </div>
  </div>

  <!-- ATELIER -->
  <div *ngIf="resultWorkshops.length > 0">
    <h5><i class="ni ni-circle-08 text-pink"></i><span> Ateliers</span></h5>
    <div *ngFor="let resultWorkshop of resultWorkshops">
      <a [routerLink]="[resultWorkshop.object_link]">
        {{ resultWorkshop.object_label }}
      </a>
    </div>
  </div>
</div>
