export enum WorkshopStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export class Workshop {
  workshop_id: string;
  eva_company_id: string;
  workshop_corporate_id: string;
  name: string;
  address: string;
  contact: string;
  info: string;
  status: WorkshopStatus;

  constructor() {
    this.status = WorkshopStatus.ENABLED;
  }

  public static createInstance(jsonData: Workshop): Workshop {
    const w = Object.assign(new Workshop(), jsonData);
    return w;
  }

  public static createInstances(jsonData: Workshop[]): Workshop[] {
    return jsonData.map(Workshop.createInstance);
  }
}
