import { Employee, Project } from "src/app/domains/internal";

import { Contract } from "../features/customer/domains/contract";
import { Intervention } from "../features/interventions/domains/intervention";

export enum EmployeeActivityType {
  WORKING = "WORKING",
  ABSENCE = "ABSENCE",
  MEAL_BREAK = "MEAL_BREAK",
  SCHEDULE = "SCHEDULE",
}
export enum EmployeeActivityTypeLabel {
  WORKING = "Réalisé",
  SCHEDULE = "Planifié",
  ABSENCE = "Absence",
  MEAL_BREAK = "Pause Repas",
}
export enum AbsenceType {
  OTHER = "OTHER",
  SICKNESS = "SICKNESS",
  VACATION = "VACATION",
  PUBLIC_HOLIDAY = "PUBLIC_HOLIDAY",
  ACCIDENT = "ACCIDENT",
  VACATION_OVERTIME = "VACATION_OVERTIME",
  JUSTIFIED = "JUSTIFIED",
  NOT_PAID = "NOT_PAID",
  TRAINING = "TRAINING",
}
export enum AbsenceTypeLabel {
  OTHER = "Autre",
  SICKNESS = "Maladie",
  VACATION = "Congés",
  PUBLIC_HOLIDAY = "Férié",
  ACCIDENT = "Accident",
  VACATION_OVERTIME = "Congés HS",
  JUSTIFIED = "Justifiées",
  NOT_PAID = "Non payés",
  TRAINING = "Formations",
}

export class EmployeeActivity {
  employee_activity_id: string;
  activity_type: EmployeeActivityType;
  start_date: Date;
  end_date: Date;
  project_id: string;
  intervention_id: string = null;
  hour_cost: number;
  absence_type: string;
  comment: string;
  absence_attachment: string;
  meal_payback_request = true;
  meal_payback_amount = 21;
  is_external: boolean;
  is_selected: boolean = false;

  manager_comment = "";
  manager_id = "";
  manager_edit_on = new Date();
  manager_attachments_ids = [];

  get hours_count(): number {
    return (this.end_date.getTime() - this.start_date.getTime()) / 1000 / 3600;
  }

  get activity_cost(): number {
    if (this.activity_type === EmployeeActivityType.WORKING || this.activity_type === EmployeeActivityType.SCHEDULE) {
      return this.hour_cost * this.hours_count;
    } else if (this.activity_type === EmployeeActivityType.MEAL_BREAK) {
      return this.meal_payback_request ? this.meal_payback_amount : 0;
    } else {
      return 0;
    }
  }

  // external
  project: Project = null;
  intervention: Intervention = null;
  contract: Contract = null;
  manager_attachments = [];
  manager: Employee;
  employee_id: string;

  public static createInstance(jsonData: EmployeeActivity): EmployeeActivity {
    const e = Object.assign(new EmployeeActivity(), jsonData);
    if (jsonData.project) {
      e.project = Project.createInstance(jsonData.project);
    }
    e.start_date = new Date(jsonData.start_date);
    e.end_date = new Date(jsonData.end_date);
    if (jsonData.manager) {
      e.manager = Employee.createInstance(jsonData.manager);
    }
    if (jsonData.intervention) {
      e.intervention = Intervention.createInstance(jsonData.intervention);
    }
    if (jsonData.contract) {
      e.contract = Contract.createInstance(jsonData.contract);
    }
    return e;
  }

  public static createNewActivity(startDate: Date): EmployeeActivity {
    const e = new EmployeeActivity();
    e.activity_type = EmployeeActivityType.WORKING;
    e.start_date = new Date(startDate);
    e.end_date = new Date(startDate);
    return e;
  }

  public static createSchedule(
    startDate: Date,
    endDate: Date,
    projectId: string,
    manager_comment,
    manager_id
  ): EmployeeActivity {
    const e = new EmployeeActivity();
    e.activity_type = EmployeeActivityType.SCHEDULE;
    e.start_date = new Date(startDate);
    e.end_date = new Date(endDate);
    e.project_id = projectId;
    e.manager_comment = manager_comment;
    e.manager_id = manager_id;
    return e;
  }
  public static createMealBreak(
    startDate: Date,
    endDate: Date,
    projectId: string,
    manager_comment,
    manager_id,
    meal_payback_request
  ): EmployeeActivity {
    const e = new EmployeeActivity();
    e.activity_type = EmployeeActivityType.MEAL_BREAK;
    e.start_date = new Date(startDate);
    e.end_date = new Date(endDate);
    e.project_id = projectId;
    e.manager_comment = manager_comment;
    e.manager_id = manager_id;
    e.meal_payback_request = meal_payback_request;
    return e;
  }
  public static createWorking(
    startDate: Date,
    endDate: Date,
    projectId: string,
    manager_comment,
    manager_id
  ): EmployeeActivity {
    const e = new EmployeeActivity();
    e.activity_type = EmployeeActivityType.WORKING;
    e.start_date = new Date(startDate);
    e.end_date = new Date(endDate);
    e.project_id = projectId;
    e.manager_comment = manager_comment;
    e.manager_id = manager_id;

    return e;
  }
  public static createAbsence(
    startDate: Date,
    endDate: Date,
    absenceType,
    manager_comment,
    manager_id
  ): EmployeeActivity {
    const e = new EmployeeActivity();
    e.activity_type = EmployeeActivityType.ABSENCE;
    e.absence_type = absenceType;
    e.start_date = new Date(startDate);
    e.end_date = new Date(endDate);
    e.manager_comment = manager_comment;
    e.manager_id = manager_id;
    return e;
  }
}
