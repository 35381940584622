import { DocumentEvaCompanyApparence, HTMLEntry } from "../../eva-company/domains/eva-company";

export class InvoicePdfConfig {
  contact_id = null;
  header_location = "";
  header_date = null;
  show_info_employee = true;
  show_info_project = true;
  show_info_offer = true;
  show_info_customer = true;
  footerHTML: string;
  apparence: DocumentEvaCompanyApparence = new DocumentEvaCompanyApparence();
  isOriginalApparence: boolean = true;
  displaySignature: boolean = false;
  displayQuotationAsAnnex: boolean = false;
  annexes: HTMLEntry[] = [];
  title = "";
  headerHTML = "";
  outro_offerHTML = "";
  closing_textHTML = "";

  constructor() {
    this.contact_id = null;
    this.header_location = "";
    this.header_date = null;
    this.show_info_employee = true;
    this.show_info_project = true;
    this.show_info_offer = true;
    this.show_info_customer = true;
    this.isOriginalApparence = true;
    this.displaySignature = false;
    this.displayQuotationAsAnnex = false;
    this.title = "";
    this.headerHTML = "";
    this.outro_offerHTML = "";
    this.closing_textHTML = "";
    this.apparence = new DocumentEvaCompanyApparence();
  }

  public static createInstance(jsonData: any): InvoicePdfConfig {
    const instance = new InvoicePdfConfig();

    for (const key in jsonData) {
      if (jsonData[key] !== null && jsonData[key] !== undefined) {
        instance[key] = jsonData[key];
      }
    }

    if (instance.header_date) {
      instance.header_date = new Date(instance.header_date);
    }
    return instance;
  }
}
