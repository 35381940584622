<form class="navbar-search navbar-search-light form-inline mr-3 ml-lg-auto form-container">
  <div class="input-group input-group-alternative search-bar">
    <div class="input-group-prepend">
      <span class="input-group-text"><i class="pi pi-search"></i></span>
    </div>
    <input
      #inputsearch
      autocomplete="one-time-code"
      class="form-control input-field"
      placeholder="Rechercher..."
      type="text"
      [(ngModel)]="query"
      name="query"
    />
  </div>

  <eva-search-result
    class="overlay"
    *ngIf="showResultOverlay"
    [searchItems]="searchItems"
    [loading]="loading"
    (hideOverlay)="onHideOverlay()"
  ></eva-search-result>
</form>
