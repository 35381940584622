import { OfferDiscount } from "src/app/domains";

import { ArticleStatus, InternalArticleVariant } from "../features/catalog/domains/article";
import { OfferGanttSchedule } from "./offer-gantt-schedule";

export enum OfferUnitType {
  MATERIAL = "MATERIAL",
  WORKFORCE = "WORKFORCE",
  OTHER = "OTHER",
  TEXT = "TEXT",
  PACK = "PACK",
}
export enum OfferUnitTypeLabel {
  MATERIAL = "Matériel",
  WORKFORCE = "Main d'oeuvre",
  OTHER = "Divers",
  TEXT = "Texte",
  PACK = "Pack",
}
export enum OfferUnitTypeDefaultUnit {
  MATERIAL = "pce",
  WORKFORCE = "j/e",
  OTHER = "ens",
}
export enum OfferItemType {
  NORMAL = "NORMAL",
  VARIANT = "VARIANT",
  TEXT = "TEXT",
  PERCENT = "PERCENT",
}
export enum OfferItemTypeLabel {
  NORMAL = "Normal",
  VARIANT = "Avec variantes",
  TEXT = "Texte",
  PERCENT = "Plus-value",
}

export class OfferItemVariant {
  static LockedFieldEnum = {
    MARGIN: "MARGIN",
    SELLING: "SELLING",
    COST: "COST",
  } as const;

  offer_item_variant_id = generateObjectId();
  name = "";
  unit = "pce";
  quantity = 1;
  cost_unit_price = 0;
  selling_unit_price = 0;

  // Not in DB

  locked_field: keyof typeof OfferItemVariant.LockedFieldEnum = OfferItemVariant.LockedFieldEnum.MARGIN;
  margin = 25;

  get selling_price(): number {
    return this.selling_unit_price * this.quantity;
  }
  get cost_price(): number {
    return this.cost_unit_price * this.quantity;
  }

  public static createInstance(jsonData: OfferItemVariant): OfferItemVariant {
    const d = Object.assign(new OfferItemVariant(), jsonData);
    d.margin = d.calculateMargin();
    return d;
  }

  public static createInstances(jsonData: any[]): OfferItemVariant[] {
    if (!jsonData) return [];
    return jsonData.map(OfferItemVariant.createInstance);
  }

  private calculateMargin(): number {
    return this.cost_unit_price ? roundFixed((this.selling_unit_price / this.cost_unit_price - 1) * 100, 2) : 0;
  }

  public onCostUnitPriceChange(offerItem: OfferItem = null) {
    if (this.locked_field !== OfferItemVariant.LockedFieldEnum.MARGIN) {
      this.margin = this.calculateMargin();
    } else {
      this.selling_unit_price = roundFixed(this.cost_unit_price * (1 + this.margin / 100), 2);
    }
    if (offerItem) {
      let cumulVariantTotalCostPrice: number = 0;
      let cumulVariantTotalSellingPrice: number = 0;

      for (let variant of offerItem.variants) {
        cumulVariantTotalCostPrice += variant.cost_price;
        cumulVariantTotalSellingPrice += variant.selling_price;
      }

      offerItem.setCostPrice(cumulVariantTotalCostPrice);
      offerItem.setSellingPrice(cumulVariantTotalSellingPrice);
    }
  }

  public onSellingUnitPriceChange(offerItem: OfferItem = null) {
    if (this.locked_field !== OfferItemVariant.LockedFieldEnum.MARGIN) {
      this.margin = this.calculateMargin();
    } else {
      this.cost_unit_price = roundFixed(this.selling_unit_price / (1 + this.margin / 100), 2);
    }
    if (offerItem) {
      let cumulVariantTotalCostPrice: number = 0;
      let cumulVariantTotalSellingPrice: number = 0;

      for (let variant of offerItem.variants) {
        cumulVariantTotalCostPrice += variant.cost_price;
        cumulVariantTotalSellingPrice += variant.selling_price;
      }

      offerItem.setCostPrice(cumulVariantTotalCostPrice);
      offerItem.setSellingPrice(cumulVariantTotalSellingPrice);
    }
  }

  public onMarginChange(offerItem: OfferItem = null) {
    if (this.locked_field !== OfferItemVariant.LockedFieldEnum.SELLING) {
      this.selling_unit_price = roundFixed(this.cost_unit_price * (1 + this.margin / 100), 2);
    } else {
      this.cost_unit_price = roundFixed(this.selling_unit_price / (1 + this.margin / 100), 2);
    }

    if (offerItem) {
      let cumulVariantTotalCostPrice: number = 0;
      let cumulVariantTotalSellingPrice: number = 0;

      for (let variant of offerItem.variants) {
        cumulVariantTotalCostPrice += variant.cost_price;
        cumulVariantTotalSellingPrice += variant.selling_price;
      }

      offerItem.setCostPrice(cumulVariantTotalCostPrice);
      offerItem.setSellingPrice(cumulVariantTotalSellingPrice);
    }
  }
}

export class OfferItemArticle {
  article_id = ""; //NOT saved in DB
  catalog_id = "";
  supplier_id = "";
  vat = 0;
  unit = "";
  article_type: OfferUnitType;
  status: ArticleStatus;
  long_text = "";
  short_text = "";
  article_number = "pce";
  cost_price = 0;
  selling_price = 0;
  margin = 0;

  public static createInstance(jsonData: OfferItemArticle): OfferItemArticle {
    const d = Object.assign(new OfferItemArticle(), jsonData);
    return d;
  }

  public static createInstances(jsonData: any[]): OfferItemArticle[] {
    if (!jsonData) return [];
    return jsonData.map(OfferItemArticle.createInstance);
  }
}

export interface IOfferItem {
  offer_item_id: string;
  article_id: string;
  article_number: string;
  isPageBreakBefore: boolean;
  isCostPriceLocked: boolean;
  isSellingPriceLocked: boolean;
  isInvalid: boolean;
  name: string;
  offer_item_type: OfferItemType;
  supplier_id: string;
  unit_quantity: number;
  percentage: number;
  unit_type: string;
  unit: string;
  unit_price: number;
  margin: number;
  auction_discount: number;
  supplier_discount_goal: number;
  billing_percent_situations: number[];

  desc_html: string;
  is_ttc: boolean;
  taxes_rate_percent: number;
  apply_discount: boolean;
  discount_value: number;

  gantt_schedules: OfferGanttSchedule[];
  progress: number;
  employees_ids: [];

  is_optional: boolean;
  // NOT REGISTER IN DB
  is_selected: boolean;

  variants: OfferItemVariant[];

  articles: OfferItemArticle[];
}

export class OfferItem implements IOfferItem {
  offer_item_id: string;
  name: string;
  offer_item_type: OfferItemType;
  desc_html: string;
  supplier_id: string;
  unit_type: OfferUnitType;
  unit: string;
  article_id: string;
  article_number: string;
  isPageBreakBefore: boolean = false;
  isCostPriceLocked: boolean = false;
  isSellingPriceLocked: boolean = false;
  percentage: number = 0;
  isInvalid: boolean = false;
  // NOT REGISTER IN DB
  is_selected: boolean = false;

  is_ttc: boolean = false;
  taxes_rate_percent: number;
  get taxes_rate(): number {
    return this.taxes_rate_percent / 100 + 1;
  }

  unit_quantity: number;
  setUnitQuantity(unit_quantity: number) {
    this.unit_quantity = unit_quantity;
    this.computeValues();
  }

  unit_price: number;
  setUnitPrice(unit_price: number) {
    this.unit_price = unit_price;
    this.computeValues();
  }

  margin: number;
  setMargin(margin: number, recalculate: boolean = true, applyToSellingPrice: boolean = true) {
    if (!margin) {
      margin = 0;
    }
    this.margin = roundFixed(margin, 12);
    if (!applyToSellingPrice) {
      this.setUnitPrice(this.selling_price_unit / (1 + this.margin / 100));
    }
    if (recalculate) this.computeValues("margin");
  }

  apply_discount: boolean = false;
  discount_percent: number;
  setDiscountPercent(discount_percent: number) {
    this.discount_percent = discount_percent;
    this.computeDiscountValue();
  }

  discount_value: number = 0;
  setDiscountValue(discount_value: number) {
    this.discount_value = discount_value;
    this.computeDiscountPercent();
  }

  auction_discount: number;
  supplier_discount_goal: number;
  setSupplierDiscountGoal(supplier_discount_goal: number) {
    this.supplier_discount_goal = supplier_discount_goal;
    this.computeSupplierDiscountGoalTotal();
    this.computeSupplierDiscountGoalRate();
    this.computeBuyPriceGoal();
  }
  billing_percent_situations: number[];
  gantt_schedules: OfferGanttSchedule[];
  progress: number;
  employees_ids: [];

  // external
  offer_discounts: OfferDiscount[];

  is_optional: boolean = false;

  get shouldCount(): boolean {
    return !this.is_optional && this.offer_item_type !== OfferItemType.TEXT;
  }
  get is_variant(): boolean {
    return this.offer_item_type === OfferItemType.VARIANT;
  }
  get is_text(): boolean {
    return this.offer_item_type === OfferItemType.TEXT;
  }
  get is_percent(): boolean {
    return this.offer_item_type === OfferItemType.PERCENT;
  }
  variants: OfferItemVariant[];

  articles: OfferItemArticle[];

  constructor(
    name = "",
    supplier_id = "",
    unit_type = OfferUnitType.MATERIAL,
    unit = "pce",
    unit_quantity = 1,
    unit_price = 0,
    margin = 25,
    auction_discount = 0,
    supplier_discount_goal = 25,
    progress = 0
  ) {
    this.offer_item_id = generateObjectId();
    this.desc_html = "";
    this.name = name;
    this.offer_item_type = OfferItemType.NORMAL;
    this.supplier_id = supplier_id;
    this.unit_type = unit_type;
    this.unit = unit;
    this.unit_quantity = unit_quantity;
    this.unit_price = unit_price;
    this.margin = margin;
    this.auction_discount = auction_discount;
    this.supplier_discount_goal = supplier_discount_goal;
    this.progress = progress;

    this.billing_percent_situations = [];

    this.offer_discounts = [];

    this.variants = [];
    this.articles = [];
  }

  public static createInstance(raw_offer_item: IOfferItem, offerDiscounts: OfferDiscount[] = []): OfferItem {
    const i = new OfferItem();
    i.offer_item_id = raw_offer_item.offer_item_id;
    i.name = raw_offer_item.name;
    i.article_id = raw_offer_item.article_id;
    i.article_number = raw_offer_item.article_number;
    i.isPageBreakBefore = raw_offer_item.isPageBreakBefore;
    i.isSellingPriceLocked = raw_offer_item.isSellingPriceLocked;
    i.isInvalid = raw_offer_item.isInvalid;
    i.isCostPriceLocked = raw_offer_item.isCostPriceLocked;
    i.offer_item_type = raw_offer_item.offer_item_type ? raw_offer_item.offer_item_type : OfferItemType.NORMAL;
    i.desc_html = raw_offer_item.desc_html ? raw_offer_item.desc_html : raw_offer_item.name;
    i.supplier_id = raw_offer_item.supplier_id;
    i.unit_quantity = roundFixed(raw_offer_item.unit_quantity, 6);
    i.percentage = roundFixed(raw_offer_item.percentage, 6);
    i.unit = raw_offer_item.unit;
    i.unit_type = OfferUnitType[raw_offer_item.unit_type];
    i.unit_price = roundFixed(raw_offer_item.unit_price, 6);
    i.progress = raw_offer_item.progress;
    i.margin = raw_offer_item.margin;
    i.auction_discount = raw_offer_item.auction_discount;
    i.supplier_discount_goal = raw_offer_item.supplier_discount_goal;
    i.offer_discounts = offerDiscounts;
    i.is_ttc = raw_offer_item.is_ttc;
    i.taxes_rate_percent = raw_offer_item.taxes_rate_percent;
    i.apply_discount = raw_offer_item.apply_discount;
    i.discount_value = raw_offer_item.discount_value;
    i.is_optional = raw_offer_item.is_optional;
    i.variants = OfferItemVariant.createInstances(raw_offer_item.variants);
    i.articles = OfferItemArticle.createInstances(raw_offer_item.articles);

    if (!raw_offer_item.billing_percent_situations || raw_offer_item.billing_percent_situations.length == 0) {
      i.billing_percent_situations = [];
    } else {
      i.billing_percent_situations = raw_offer_item.billing_percent_situations;
    }
    if (!raw_offer_item.gantt_schedules || raw_offer_item.gantt_schedules.length == 0) {
      i.gantt_schedules = [];
    } else {
      i.gantt_schedules = raw_offer_item.gantt_schedules;
    }
    if (!raw_offer_item.employees_ids || raw_offer_item.employees_ids.length == 0) {
      i.employees_ids = [];
    } else {
      i.employees_ids = raw_offer_item.employees_ids;
    }
    i.computeValues();
    return i;
  }

  public static createInstances(jsonData: any[], offerDiscounts = []): OfferItem[] {
    return jsonData.map((val) => {
      return OfferItem.createInstance(val, offerDiscounts);
    });
  }
  replaceIds() {
    this.offer_item_id = generateObjectId();
  }
  computeValues(currentSetter = "") {
    if (this.is_variant) {
      this.computeVariantValues();
    } else {
      if (currentSetter !== "selling_price") this.computeSellingPrice();
      if (currentSetter !== "selling_price_unit") this.computeSellingPriceUnit();
      if (currentSetter !== "cost_price") this.computeCostPrice();
      if (currentSetter !== "selling_profit") this.computeSellingProfit();

      if (currentSetter !== "selling_price_with_auction") this.computeSellingPriceWithAuction();
      if (currentSetter !== "selling_auction_rate") this.computeSellingAuctionRate();
      if (currentSetter !== "selling_auction") this.computeSellingAuction();

      if (currentSetter !== "supplier_discount_goal_total") this.computeSupplierDiscountGoalTotal();
      if (currentSetter !== "buy_price_goal") this.computeBuyPriceGoal();
      if (currentSetter !== "supplier_discount_goal_rate") this.computeSupplierDiscountGoalRate();

      if (currentSetter !== "discount_percent") this.computeDiscountPercent();
      // if (currentSetter !== "discount_value") this.computeDiscountValue();
    }
  }

  computeVariantValues() {
    this.selling_price = this.variants.reduce((tot, item) => tot + item.selling_price, 0);
    this.cost_price = this.variants.reduce((tot, item) => tot + item.cost_price, 0);
    this.unit_quantity = this.variants.reduce((tot, item) => tot + item.quantity, 0);
    this.selling_profit = this.variants.reduce((tot, item) => tot + item.selling_price - item.cost_price, 0);

    this.selling_price_with_auction = this.selling_price;
    if (this.offer_discounts) {
      this.offer_discounts.forEach((offer_discount) => {
        this.selling_price_with_auction *= 1 - offer_discount.discount / 100;
      });
    }

    this.selling_auction = this.selling_price - this.selling_price_with_auction;
    this.supplier_discount_goal_total = (this.selling_price_with_auction * this.supplier_discount_goal) / 100;
    this.buy_price_goal = this.selling_price_with_auction - this.supplier_discount_goal_total || 0;
  }
  // computed values

  // SELLING PRICE
  public selling_profit: number = 0;
  computeSellingProfit() {
    this.selling_profit = roundFixed((+this.margin / 100) * +this.cost_price, 6);
  }
  public selling_price: number = 0;
  setSellingPrice(new_selling_price: number, recalculate = true) {
    this.selling_price = roundFixed(new_selling_price, 6);
    if (recalculate) {
      // this.setSellingPriceUnit(this.selling_price / (this.unit_quantity != 0 ? this.unit_quantity : 1), false);
      if (this.cost_price !== 0) {
        this.setMargin(((this.selling_price - this.cost_price) / this.cost_price) * 100, false);
      }
      this.computeValues("selling_price");
    }
  }

  computeSellingPrice() {
    if (this.unit_price === 0 && this.cost_price === 0 && this.selling_price_unit !== 0 && this.unit_quantity !== 0) {
      this.unit_price =
        this.margin === 0
          ? this.selling_price_unit
          : this.selling_price_unit / (1 + this.margin / 100) / this.unit_quantity;
    }
    this.selling_price = roundFixed(+this.unit_price * this.unit_quantity * (this.margin / 100 + 1), 6);
  }

  public selling_price_unit: number = 0;
  setSellingPriceUnit(new_selling_price: number, recalculate = true) {
    this.selling_price_unit = roundFixed(new_selling_price, 6);
    //this.unit_price = this.selling_price_unit / (1 + this.margin / 100);

    if (recalculate) {
      this.setMargin(((this.selling_price_unit - this.unit_price) / this.unit_price) * 100, false);

      this.computeValues("selling_price_unit");
    }
  }
  setSellingPriceUnitInQuotation(new_selling_price: number, recalculate = true) {
    this.selling_price_unit = roundFixed(new_selling_price, 6);
    this.unit_price = this.selling_price_unit / (1 + this.margin / 100);

    if (recalculate) {
      this.setMargin(((this.selling_price_unit - this.unit_price) / this.unit_price) * 100, false);

      this.computeValues("selling_price_unit");
    }
  }

  computeSellingPriceUnit() {
    if (this.unit_quantity === 0) {
      this.selling_price_unit = roundFixed(+this.selling_price, 6);
    } else {
      this.selling_price_unit = roundFixed(+this.selling_price / this.unit_quantity, 6);
    }
  }
  // COST PRICE
  public cost_price: number = 0;
  setCostPrice(new_cost_price: number) {
    this.cost_price = roundFixed(new_cost_price, 6);
    this.unit_price = roundFixed(this.cost_price / this.unit_quantity, 6);
    if (this.cost_price === 0) return;
    if (this.selling_price !== 0) {
      this.setMargin(((this.selling_price - this.cost_price) / this.cost_price) * 100, false);
    }
  }
  computeCostPrice() {
    if (this.unit_price === 0 && this.cost_price === 0 && this.selling_price !== 0) {
      this.unit_price =
        (this.margin === 0 ? this.selling_price : this.selling_price / (1 + this.margin / 100)) / this.unit_quantity;
    }
    this.cost_price = roundFixed(this.unit_price * this.unit_quantity, 6);
  }

  // SELLING DISCOUNT
  public selling_price_with_auction: number = 0;
  computeSellingPriceWithAuction() {
    let newPrice = this.selling_price;

    if (this.offer_discounts) {
      for (const offer_discount of this.offer_discounts) {
        let discount_total = (newPrice * offer_discount.discount) / 100;
        newPrice = newPrice - discount_total;
      }
    }
    this.selling_price_with_auction = newPrice;
  }

  public selling_auction_rate: number = 0;
  computeSellingAuctionRate() {
    this.selling_auction_rate = Math.abs((this.selling_price_with_auction / this.selling_price) * 100 - 100) || 0;
  }

  public selling_auction: number = 0;
  computeSellingAuction() {
    this.selling_auction = this.selling_price - this.selling_price_with_auction;
  }

  // BUYING GOAL
  public supplier_discount_goal_total: number = 0;
  computeSupplierDiscountGoalTotal() {
    this.supplier_discount_goal_total = (this.selling_price_with_auction * this.supplier_discount_goal) / 100;
  }
  public buy_price_goal: number = 0;
  computeBuyPriceGoal() {
    this.buy_price_goal = this.selling_price_with_auction - this.supplier_discount_goal_total || 0;
  }
  public supplier_discount_goal_rate: number = 0;
  computeSupplierDiscountGoalRate() {
    this.supplier_discount_goal_rate = Math.abs((this.selling_price_with_auction / this.cost_price) * 100 - 100) || 0;
  }

  // BILLING
  billing_amount(situationIndex) {
    return this.sellingPriceWithDiscount * (this.get_billing_percent_situations(situationIndex) / 100);
  }

  get_billing_percent_situations(situationIndex): number {
    return this.billing_percent_situations[situationIndex] ? this.billing_percent_situations[situationIndex] : 0;
  }

  set_billing_percent_situations(value, situationIndex) {
    if (situationIndex > this.billing_percent_situations.length) {
      for (let i = this.billing_percent_situations.length; i < situationIndex; i++) {
        this.billing_percent_situations.push(0);
      }
    }
    this.billing_percent_situations[situationIndex] = value;
  }

  computeDiscountPercent() {
    this.discount_percent = roundFixed(Math.abs((this.discount_value / this.selling_price) * 100) || 0, 6);
    if (!isFinite(this.discount_percent)) {
      this.discount_percent = 0;
    }
  }
  computeDiscountValue() {
    this.discount_value = roundFixed((this.discount_percent * this.selling_price) / 100 || 0, 6);
  }

  // OFFER DISCOUNTS
  setOfferDiscounts(discounts: OfferDiscount[]) {
    this.offer_discounts = discounts;
    this.computeValues();
  }

  get sellingPriceTTC() {
    // Prix de vente avec toutes les taxes comprises (TTC)
    return this.selling_price * (!this.is_ttc ? this.taxes_rate : 1);
  }
  get sellingPriceWithDiscount() {
    // Prix de vente avec éventuelle réduction appliquée
    return this.selling_price - (this.apply_discount ? this.discount_value : 0);
  }
  get sellingPriceTTCWithDiscount() {
    // Prix de vente final, incluant à la fois les taxes et les réductions
    return this.sellingPriceWithDiscount * (!this.is_ttc ? this.taxes_rate : 1);
  }
}

function generateObjectId(): string {
  const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
  const randomHex = Array.from({ length: 16 }, () => Math.floor(Math.random() * 16).toString(16)).join("");

  return timestamp + randomHex;
}
function roundFixed(num: number, decimalPlaces: number): number {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(num * factor) / factor;
}
function round(arg0: number, arg1: number): number {
  throw new Error("Function not implemented.");
}
