import { Asset } from "src/app/domains";

import { Employee } from "../../employees/domains/employee";
import { PurchaseOrderCreateDeliveryInput } from "../dtos/purchase-order-create-delivery.input";
import { PurchaseOrderUpdateDeliveryInput } from "../dtos/purchase-order-update-delivery.input";

export enum PurchaseOrderDeliveryType {
  PROJECT_SITE = "PROJECT_SITE",
  WORKSHOP = "WORKSHOP",
  SHOP = "SHOP",
  CUSTOM = "CUSTOM",
}

export enum PurchaseOrderDeliveryTypeLabel {
  PROJECT_SITE = "Sur chantier",
  WORKSHOP = "Atelier",
  SHOP = "Pris au Magasin",
  CUSTOM = "Autre",
}

export class PurchaseOrderDelivery {
  purchase_order_delivery_id = "";
  delivery_date: Date = new Date();
  employee_id = "";
  delivery_percent = 0.0;
  comment = "";
  attachments_ids: string[] = [];

  // external data
  employee: Employee = null;
  attachments: Asset[] = [];

  public static createInstance(jsonData: PurchaseOrderDelivery | any): PurchaseOrderDelivery {
    const p = Object.assign(new PurchaseOrderDelivery(), jsonData);

    if (jsonData.delivery_date) {
      p.delivery_date = new Date(jsonData.delivery_date);
    }

    if (jsonData.attachments) {
      p.attachments = Asset.createInstances(jsonData.attachments);
    }

    if (jsonData.employee) {
      p.employee = Employee.createInstance(jsonData.employee);
    }
    return p;
  }

  public static createInstances(jsonData: any[]): PurchaseOrderDelivery[] {
    return jsonData.map(PurchaseOrderDelivery.createInstance);
  }

  public clone(): PurchaseOrderDelivery {
    return PurchaseOrderDelivery.createInstance(this);
  }

  public asCreateDeliveryInput(): PurchaseOrderCreateDeliveryInput {
    return new PurchaseOrderCreateDeliveryInput({
      delivery_date: this.delivery_date,
      employee_id: this.employee_id,
      delivery_percent: this.delivery_percent,
      comment: this.comment,
      attachments_ids: this.attachments_ids,
    });
  }

  public asUpdateDeliveryInput(): PurchaseOrderUpdateDeliveryInput {
    return new PurchaseOrderUpdateDeliveryInput({
      purchase_order_delivery_id: this.purchase_order_delivery_id,
      delivery_date: this.delivery_date,
      employee_id: this.employee_id,
      delivery_percent: this.delivery_percent,
      comment: this.comment,
      attachments_ids: this.attachments_ids,
    });
  }
}
