export enum PeriodClosedType {
  PUBLIC_HOLIDAY = "PUBLIC_HOLIDAY",
  VACATION = "VACATION",
}

export enum PeriodClosedTypeLabel {
  PUBLIC_HOLIDAY = "Férié",
  VACATION = "Congé",
}

export class PeriodClosed {
  period_closed_id = "";
  start_date: Date = new Date();
  end_date: Date = new Date();
  name: string = "";
  type: string = "";

  public static createInstance(jsonData: PeriodClosed): PeriodClosed {
    const d = Object.assign(new PeriodClosed(), jsonData);
    d.start_date = new Date(jsonData.start_date);
    d.end_date = new Date(jsonData.end_date);

    return d;
  }

  public static createInstances(jsonData: any[]): PeriodClosed[] {
    return jsonData.map(PeriodClosed.createInstance);
  }

  clone(): PeriodClosed {
    let d = Object.assign(new PeriodClosed(), this);
    return d;
  }
}
