import { ObjectId } from "src/app/shared/helpers/objectid.helper";

export class InterventionPassage {
  intervention_passage_id: string = ObjectId()();
  label: string = "Nouveau passage";
  start_date: string = "01.01"; // DD/MM
  end_date: string = "31.12"; // DD/MM
  occurrences_per_interval: number = 1;
  interval_months: number = 1;
  last_intervention_date: Date = new Date(2000, 0, 1);

  public static createInstance(jsonData: InterventionPassage): InterventionPassage {
    const d = Object.assign(new InterventionPassage(), jsonData);
    if (d.last_intervention_date) {
      d.last_intervention_date = new Date(d.last_intervention_date);
    }
    return d;
  }

  public static createInstances(jsonData: any[]): InterventionPassage[] {
    return jsonData.map(InterventionPassage.createInstance);
  }

  clone(resetObjId = false): InterventionPassage {
    let d = Object.assign(new InterventionPassage(), this);
    if (resetObjId) {
      d.intervention_passage_id = ObjectId()();
    }
    return d;
  }
  public static multiClone(data: InterventionPassage[], resetObjId = false): InterventionPassage[] {
    return data.map((d) => d.clone(resetObjId));
  }

  public calculateNextWorkdayDate(): Date {
    let currentDate = new Date(this.last_intervention_date);

    while (true) {
      let nextDate: Date;

      // Calculate the next date
      if (this.interval_months > 1 || this.occurrences_per_interval === 1) {
        // Add months to date
        const year = currentDate.getFullYear() + Math.floor((currentDate.getMonth() + this.interval_months) / 12);
        const month = (currentDate.getMonth() + this.interval_months) % 12;
        const daysInMonth = [
          31,
          year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? 29 : 28,
          31,
          30,
          31,
          30,
          31,
          31,
          30,
          31,
          30,
          31,
        ];
        const day = Math.min(currentDate.getDate(), daysInMonth[month]);
        nextDate = new Date(year, month, day);
      } else {
        // Add days to date
        const daysPerOccurrence = 28 / this.occurrences_per_interval;
        nextDate = new Date(currentDate.getTime() + daysPerOccurrence * 86400000);
      }

      // Calculate the next workday
      const weekday = nextDate.getDay();
      if (weekday === 6) {
        // Saturday
        nextDate.setDate(nextDate.getDate() + 2);
      } else if (weekday === 0) {
        // Sunday
        nextDate.setDate(nextDate.getDate() + 1);
      }

      // Check if the date is in range
      const [startDay, startMonth] = this.start_date.split(".").map(Number);
      const [endDay, endMonth] = this.end_date.split(".").map(Number);
      const startDateTime = new Date(nextDate.getFullYear(), startMonth - 1, startDay);
      const endDateTime = new Date(nextDate.getFullYear(), endMonth - 1, endDay);
      const isInRange =
        endDateTime < startDateTime
          ? nextDate >= startDateTime || nextDate <= endDateTime
          : nextDate >= startDateTime && nextDate <= endDateTime;

      if (isInRange) {
        return nextDate;
      }

      currentDate = nextDate;
    }
  }
}
