import { Asset } from "./asset";
import { Employee } from "./internal";

export class OfferSend {
  offer_send_id: string = "";
  object: string = "";
  send_to: string[] = [];
  send_cc: string[] = [];
  send_bcc: string[] = [];
  message: string = "";
  attachments_ids: string[] = [];
  customer_response_deadline: Date = null;
  sent_on: Date = null;
  sent_by: string = "";

  // external
  attachments: Asset[] = [];
  sent_by_employee: Employee = null;

  get title(): string {
    return "Envoyé le " + this.sent_on.toLocaleString() + " par " + this.sent_by_employee?.display_name;
  }

  public static createInstances(jsonData: any[]): OfferSend[] {
    return jsonData.map(OfferSend.createInstance);
  }

  public static createInstance(jsonData: OfferSend): OfferSend {
    const o = Object.assign(new OfferSend(), jsonData);

    o.customer_response_deadline = jsonData.customer_response_deadline
      ? new Date(jsonData.customer_response_deadline)
      : null;
    o.sent_on = jsonData.sent_on ? new Date(jsonData.sent_on) : null;
    o.attachments = jsonData.attachments ? Asset.createInstances(jsonData.attachments) : [];
    o.sent_by_employee = jsonData.sent_by_employee ? Employee.createInstance(jsonData.sent_by_employee) : null;
    return o;
  }
}
