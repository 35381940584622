import { PrimeNGConfig } from "primeng/api";
import { fromEvent, map, merge, of, Subscription } from "rxjs";
import { User } from "src/app/domains/internal";
import { environment } from "src/environments/environment";

import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { GoogleAnalyticsService } from "./core/services/google-analytics.service";
import { AuthenticationService } from "./services/authentication.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  currentUser: User;

  networkStatus: boolean = true;
  networkStatus$: Subscription = Subscription.EMPTY;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private config: PrimeNGConfig,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {
    // load clarity script and force identity
    this.loadClarityScript(() => {
      this.setClarityIdentity();
    });

    // on user connected
    this.authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x;
      // update identity with user_info
      this.setClarityIdentity();
    });

    // on new route
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // refresh identity on clarity for better tracking
        this.setClarityIdentity();
      }
    });

    if (environment.production) {
      this.googleAnalyticsService.initialize();
    }
  }

  setClarityIdentity(page: string = "") {
    if (!environment.clarityId) return;
    // @ts-ignore
    if (window.clarity) {
      // @ts-ignore
      // window.clarity("identify", "custom-id", "custom-session-id", "custom-page-id", "friendly-name")
      window.clarity("identify", this.currentUser?.user_id, "", page, this.currentUser?.display_name);
    } else {
      // force load clarity script and force identity
      this.loadClarityScript(() => {
        // @ts-ignore
        window.clarity("identify", this.currentUser?.user_id, "", page, this.currentUser?.display_name);
      });
    }
  }

  ngOnInit(): void {
    this.checkNetworkStatus();
    this.config.setTranslation({
      dateFormat: "dd.mm.yy",
      firstDayOfWeek: 1,
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
      monthNames: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
      monthNamesShort: ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Juil", "Aoû", "Sep", "Oct", "Nov", "Dec"],
    });
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }

  // CHECK NETWORK STATUS
  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(of(null), fromEvent(window, "online"), fromEvent(window, "offline"))
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        this.networkStatus = status;
      });
  }

  // FORCE LOGOUT
  logout() {
    this.authenticationService.logout();
    this.router.navigate(["#/login"]);
  }

  // Function to load script
  loadClarityScript(callback: () => void) {
    const clarityId = environment.clarityId;
    if (clarityId) {
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        t.onload = callback; // Ajout d'un gestionnaire d'événement pour le chargement
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", clarityId);
    }
  }
}
