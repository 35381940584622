import { Asset, OfferItemGroup } from "src/app/domains";
import { EvaAddress } from "src/app/domains/address";

import { InterventionAction } from "../../interventions/domains/intervention_action";
import { InterventionMaterial } from "../../interventions/domains/intervention_material";
import { InterventionPassage } from "../../interventions/domains/intervention_passage";
import { FieldValue } from "./field_value";

export enum ContractInstallationStatusLabel {
  ENABLED = "Actif",
  DISABLED = "Inactif",
}

export enum ContractInstallationStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export class ContractInstallation {
  contract_installation_id: string = "";
  name: string = "";
  status: ContractInstallationStatus = ContractInstallationStatus.ENABLED;
  intervention_type_id: string = "";
  default_report_values: FieldValue[] = [];
  default_quotation: OfferItemGroup;
  use_intervention_type_quotation = true;
  use_customer_address = true;
  address: EvaAddress = null;

  public get inlineAddress(): string {
    return this.address ? this.address.inlineAddress : "";
  }

  assets: Asset[] = [];

  default_actions: InterventionAction[] = [];
  editable_actions = "";
  has_passages = false;
  requires_company_to_plan = false;
  passages: InterventionPassage[] = [];
  materials: InterventionMaterial[] = [];

  // External

  contract_id: string = "";
  interventions = [];

  public static createInstance(jsonData: ContractInstallation): ContractInstallation {
    const d = Object.assign(new ContractInstallation(), jsonData);
    if (d.default_report_values) {
      d.default_report_values = FieldValue.createInstances(d.default_report_values);
    }
    if (d.default_quotation) {
      d.default_quotation = OfferItemGroup.createInstance(d.default_quotation);
    }
    if (d.assets) {
      d.assets = Asset.createInstances(d.assets);
    }
    if (d.address) {
      d.address = EvaAddress.createInstance(d.address);
    }
    if (d.default_actions) {
      d.default_actions = InterventionAction.createInstances(d.default_actions);
    }
    if (d.passages) {
      d.passages = InterventionPassage.createInstances(d.passages);
    }
    if (d.materials) {
      d.materials = InterventionMaterial.createInstances(d.materials);
    }

    return d;
  }

  public static createInstances(jsonData: any[]): ContractInstallation[] {
    return jsonData.map(ContractInstallation.createInstance);
  }

  clone(resetObjIds = false): ContractInstallation {
    let d = Object.assign(new ContractInstallation(), this);
    d.passages = this.passages.map((p) => p.clone(resetObjIds));
    d.default_actions = this.default_actions.map((a) => a.clone(resetObjIds));
    return d;
  }
}
