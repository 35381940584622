import { Asset } from "src/app/domains";
import { ExpenseVat } from "./expense-vat";

export enum ExpenseType {
  DEPLACEMENT = "DEPLACEMENT",
  HEBERGEMENT = "HEBERGEMENT",
  RESTAURATION = "RESTAURATION",
  FOURNITURES_ADMIN = "FOURNITURES_ADMIN",
  FOURNITURES_OUTILS = "FOURNITURES_OUTILS",
  NOURRITURE = "NOURRITURE",
  FRAIS_KILOMETRIQUES = "FRAIS_KILOMETRIQUES",
  CADEAU = "CADEAU",
  CARBURANT = "CARBURANT",
}

export enum ExpenseStatus {
  ON_GOING = "ON_GOING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

export class Expense {
  expense_id: string;
  project_id: string;
  is_gift: boolean;
  gift_recipient: string;
  gift_other: string;
  customer_id: string;
  name: string;
  desc: string;
  expense_type: ExpenseType;
  payment_date: Date;
  amount_pt: number;
  payment_source: string;
  amount_vats: ExpenseVat[];
  amount_bt: number;
  updated_on: Date;
  asset_id: string;
  asset_name: string;
  assetb64: Asset;
  status: ExpenseStatus;

  constructor() {
    this.name = "";
    this.desc = "";
    this.amount_vats = [new ExpenseVat()];
    this.payment_date = new Date();
  }

  public static createInstance(jsonData: Expense): Expense {
    const e = Object.assign(new Expense(), jsonData);
    e.payment_date = jsonData.payment_date ? new Date(jsonData.payment_date) : null;

    if (jsonData.amount_vats) {
      e.amount_vats = ExpenseVat.createInstances(jsonData.amount_vats);
    }

    return e;
  }

  public static createInstances(jsonData: any[]): Expense[] {
    return jsonData.map(Expense.createInstance);
  }

  clone(): Expense {
    let s = Object.assign(new Expense(), this);
    return s;
  }
}
