<footer class="footer">
  <div class="copyright text-center text-xl-left text-muted" (click)="hideShowVersion()">
    <span>&copy; {{ today | date : "yyyy" }}</span>
    <a href="#" class="font-weight-bold ml-1" target="_blank">EVA</a>
    <button
      *ngIf="newVersionAvailable"
      type="button"
      icon="pi pi-refresh"
      pButton
      (click)="promptUserToUpdate()"
      pTooltip="Une nouvelle version de EVA est disponible ! Cliquez ici pour mettre à jour, sinon patientez et EVA se mettre automatiquement à jour."
      label="Mettre à jour"
      class="p-button-primary p-button-sm"
    ></button>
  </div>
  <div *ngIf="showAppVersion" class="copyright text-center text-xl-left text-muted">
    <span class="ml-2">version : {{ appVersion }}</span>
  </div>
</footer>

<p-toast position="bottom-center" key="c" (onClose)="onRejectUpdate()" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
      <div class="p-text-center">
        <i class="pi pi-refresh" style="font-size: 3rem"></i>
        <h4>Mise à jour disponible !</h4>
        <p>Souhaitez-vous l'installer immédiatement ?</p>
        <p>
          Attention, vous perdrez votre travail non sauvegardé. Si vous souhaitez faire la mise à jour ultérieurement,
          rafraichissez la page ou relancer votre application.
        </p>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-6">
          <button type="button" pButton (click)="onConfirmUpdate()" label="Oui" class="p-button-success"></button>
        </div>
        <div class="p-col-6">
          <button type="button" pButton (click)="onRejectUpdate()" label="Non" class="p-button-secondary"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
