import { round } from "mathjs";
import { Asset, Employee, Invoice, InvoiceStatus, Project, Supplier } from "src/app/domains";
import { CreditNote, CreditNoteObjectType } from "src/app/domains/credit-note";
import { isStringEmpty } from "src/app/shared/helpers/string.helper";

import { Tag } from "../../eva-company/domains/eva-company-tag";
import { PurchaseOrderDelivery, PurchaseOrderDeliveryType } from "./purchase-order-delivery";

export enum PurchaseOrderStatus {
  DRAFT = "DRAFT",
  ON_GOING = "ON_GOING",
  CANCELLED = "CANCELLED",
  FINISHED = "FINISHED",
}
export enum PurchaseOrderStatusLabel {
  DRAFT = "Brouillon",
  ON_GOING = "En cours",
  CANCELLED = "Abandonné",
  FINISHED = "Clôturé",
}
export enum PurchaseOrderDetailsStatus {
  WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
  WAITING_DELIVERY = "WAITING_DELIVERY",
  WAITING_PAYMENT = "WAITING_PAYMENT",
}
export enum PurchaseOrderDetailsStatusLabel {
  WAITING_CONFIRMATION = "Attente num de conf",
  WAITING_DELIVERY = "Attente livraison",
  WAITING_PAYMENT = "Attente paiement",
}
export enum PurchaseOrderType {
  MATERIAL = "MATERIAL",
  WORKFORCE = "WORKFORCE",
  ALL = "ALL",
}
export enum PurchaseOrderWorkForceType {
  EMPLOYEE = "EMPLOYEE",
  SUBCONTRACTOR = "SUBCONTRACTOR",
  NOT_CONCERNED = "NOT_CONCERNED",
}
export enum PurchaseOrderTypeLabel {
  MATERIAL = "Matériel",
  WORKFORCE = "Main d'oeuvre",
}
export enum PurchaseOrderWorkForceTypeLabel {
  EMPLOYEE = "Collaborateur",
  SUBCONTRACTOR = "Sous-traitant",
}

export class PurchaseOrder {
  purchase_order_id: string;
  purchase_order_corporate_id: string;
  eva_company_id: string;
  project_id: string;
  supplier_id: string;
  external_employee_id: string;
  supplier_address_id: string;

  purchase_order_type: PurchaseOrderType; // MATERIAL OR WORKFORCE
  purchase_order_workforce_type: PurchaseOrderWorkForceType; // EMPLOYEE OR SUBCONTRACTOR

  order_date: Date;
  wanted_delivery_date: Date;

  delivery_type: PurchaseOrderDeliveryType;
  delivery_address: string;
  delivery_workshop_id: string;
  delivery_contact: string;
  delivery_employee_id: string;

  order_content: string;
  internal_notes: string;
  order_attachments_ids: string[];

  confirmation_number: string;
  confirmation_gross_price: number;
  confirmation_gross_transport_cost: number;
  confirmation_vat: number;
  confirmation_vat_amount: number;
  confirmation_total_pt: number;
  confirmation_asset_id: string;
  confirmation_asset_name: string;
  confirmation_send_notif: boolean;
  confirmation_updated_by: string;
  confirmation_updated_on: Date;

  deliveries: PurchaseOrderDelivery[];
  delivered: boolean;
  deliveries_updated_by: string;
  deliveries_updated_on: Date;

  created_by: string;
  created_on: Date;

  updated_by: string;
  updated_on: Date;
  sent_on: Date;
  sent_by: string;
  send_type: string;

  cancelled_by: string;
  cancelled_on: Date;
  cancel_reason: string;

  confirmed_on: Date;
  confirmed_by: string;

  status: PurchaseOrderStatus;
  detailStatus: string;
  tags_ids: string[];
  tags: Tag[] = [];

  // external data to load
  project: Project = new Project();
  supplier: Supplier = new Supplier();
  delivery_employee: Employee = new Employee();
  created_by_employee: Employee = new Employee();
  updated_by_employee: Employee = new Employee();
  external_employee: Employee = new Employee();
  cancelled_by_employee: Employee = new Employee();
  confirmed_by_employee: Employee = new Employee();
  confirmation_updated_by_employee: Employee = new Employee();
  deliveries_updated_by_employee: Employee = new Employee();
  invoices: Invoice[] = [];
  credits_notes: CreditNote[] = [];
  order_attachments: Asset[] = [];

  constructor() {
    this.status = PurchaseOrderStatus.DRAFT;
    this.purchase_order_type = PurchaseOrderType.MATERIAL;
    this.purchase_order_workforce_type = PurchaseOrderWorkForceType.SUBCONTRACTOR;
    this.delivered = false;
    this.confirmation_gross_price = 0.0;
    this.confirmation_gross_transport_cost = 0.0;
    this.confirmation_vat = 0.0;
    this.confirmation_vat_amount = 0.0;
    this.confirmation_total_pt = 0.0;
    this.confirmation_send_notif = true;
    this.delivery_type = PurchaseOrderDeliveryType.PROJECT_SITE;
    this.wanted_delivery_date = new Date();
    this.order_date = new Date();
    this.order_attachments = [];
    this.order_attachments_ids = [];
    this.deliveries = [];
  }

  public static createInstances(jsonData: any[]): PurchaseOrder[] {
    return jsonData.map(PurchaseOrder.createInstance);
  }

  public static createInstance(json: any): PurchaseOrder {
    const p = Object.assign(new PurchaseOrder(), json);
    p.project = json.project ? Project.createInstance(json.project) : null;
    p.supplier = json.supplier ? Supplier.createInstance(json.supplier) : null;
    p.delivery_employee = json.delivery_employee ? Employee.createInstance(p.delivery_employee) : null;

    p.created_by_employee = json.created_by_employee ? Employee.createInstance(p.created_by_employee) : null;
    p.updated_by_employee = json.updated_by_employee ? Employee.createInstance(p.updated_by_employee) : null;
    p.external_employee = json.external_employee ? Employee.createInstance(p.external_employee) : null;
    p.cancelled_by_employee = json.cancelled_by_employee ? Employee.createInstance(p.cancelled_by_employee) : null;
    p.confirmed_by_employee = json.confirmed_by_employee ? Employee.createInstance(p.confirmed_by_employee) : null;
    p.confirmation_updated_by_employee = json.confirmation_updated_by_employee
      ? Employee.createInstance(p.confirmation_updated_by_employee)
      : null;
    p.deliveries_updated_by_employee = json.deliveries_updated_by_employee
      ? Employee.createInstance(p.deliveries_updated_by_employee)
      : null;
    p.deliveries = json.deliveries ? PurchaseOrderDelivery.createInstances(json.deliveries) : [];
    p.invoices = json.invoices ? Invoice.createInstances(json.invoices) : [];
    if (p.supplier) {
      for (let invoice of p.invoices) {
        invoice.supplier = p.supplier;
      }
    }
    if (p.tags) {
      p.tags = Tag.createInstances(p.tags);
    }

    p.credits_notes = json.credits_notes ? CreditNote.createInstances(json.credits_notes) : [];
    p.assets = json.assets ? Asset.createInstances(json.assets) : [];

    p.created_on = json.created_on ? new Date(json.created_on) : null;
    p.updated_on = json.updated_on ? new Date(json.updated_on) : null;
    p.cancelled = json.cancelled ? new Date(json.cancelled) : null;
    p.confirmed_on = json.confirmed_on ? new Date(json.confirmed_on) : null;
    p.confirmation_updated_on = json.confirmation_updated_on ? new Date(json.confirmation_updated_on) : null;
    p.order_date = json.order_date ? new Date(json.order_date) : null;
    p.wanted_delivery_date = json.wanted_delivery_date ? new Date(json.wanted_delivery_date) : null;

    if (p.status == PurchaseOrderStatus.ON_GOING) {
      if (p.confirmation_number === "") {
        p.detailStatus = PurchaseOrderDetailsStatus.WAITING_CONFIRMATION;
      } else if (p.percentDelivered < 100) {
        p.detailStatus = PurchaseOrderDetailsStatus.WAITING_DELIVERY;
      } else if (p.percentBilled < 100) {
        p.detailStatus = PurchaseOrderDetailsStatus.WAITING_PAYMENT;
      } else {
        p.detailStatus = null;
      }
    } else {
      p.detailStatus = null;
    }
    return p;
  }

  clone(): PurchaseOrder {
    let po = Object.assign(new PurchaseOrder(), this);
    return po;
  }

  // NOT TESTED
  excelExport(financialData = false) {
    let json = {
      id: this.purchase_order_corporate_id,
      chantier: this.project ? this.project.name : "",
      montant: this.confirmation_gross_price,
      date_du_besoin: this.wanted_delivery_date,
      responsable: this.delivery_employee ? this.delivery_employee.display_name : "",
      status: this.status,
    };
    if (financialData) {
      let financial_data = {
        "%facturé": this.percentBilled,
        total_facturé: this.totalBilled,
        "%payé": this.percentPaid,
        total_payé: this.totalPaid,
        reste_à_payer: this.leftToPay,
      };
      json = {
        ...json,
        ...financial_data,
      };
    }
    return json;
  }

  get purchase_order_type_label() {
    return PurchaseOrderTypeLabel[this.purchase_order_type];
  }

  get onGoingInvoices(): Invoice[] {
    return this.invoices ? this.invoices.filter((x) => x.status === InvoiceStatus.ON_GOING) : [];
  }
  get validatedInvoices(): Invoice[] {
    return this.invoices ? this.invoices.filter((x) => x.status !== InvoiceStatus.CANCELLED) : [];
  }
  get validatedCreditsNotes(): CreditNote[] {
    return this.credits_notes ? this.credits_notes.filter((x) => x.status !== CreditNoteObjectType.CANCELLED) : [];
  }
  get paidInvoices(): Invoice[] {
    return this.invoices ? this.invoices.filter((x) => x.status === InvoiceStatus.PAID) : [];
  }

  get invoicesCount(): number {
    return this.validatedInvoices.length;
  }

  get totalBilled(): number {
    return round(
      this.validatedInvoices.reduce((tot, item) => tot + +item.gross_total_price, 0),
      2
    );
  }

  get totalDiscount() {
    return round(
      this.validatedInvoices.reduce((tot, item) => tot + +item.getTotalDiscount(), 0),
      2
    );
  }

  get percentBilled(): number {
    if (this.confirmation_gross_price === 0) return 0;
    return round((this.invoicesTotal / this.confirmation_gross_price) * 100, 2);
  }

  get totalPaid(): number {
    return round(
      this.paidInvoices.reduce((tot, item) => tot + +item.gross_total_price, 0),
      2
    );
  }
  get percentPaid(): number {
    if (this.confirmation_gross_price === 0) return 0;
    return round((this.totalPaid / this.confirmation_gross_price) * 100, 2);
  }

  get leftToPay(): number {
    let totalPaid = this.totalPaid;
    return totalPaid < this.confirmation_gross_price ? this.confirmation_gross_price - totalPaid : 0;
  }

  get invoicesTotal(): number {
    let totalInvoices = round(
      this.validatedInvoices.reduce((tot, item) => tot + +item.gross_total_price, 0),
      2
    );

    return totalInvoices;
  }

  get netInvoicesTotal(): number {
    let totalInvoices = round(
      this.validatedInvoices.reduce((tot, item) => tot + +item.getNetTotalPrice(), 0),
      2
    );

    return totalInvoices;
  }

  get grossInvoicesTotal(): number {
    let totalInvoices = round(
      this.validatedInvoices.reduce((tot, item) => tot + +item.gross_total_price, 0),
      2
    );

    return totalInvoices;
  }

  get project_name(): string {
    return this.project ? this.project.name : "";
  }

  get project_corporate_id(): string {
    return this.project ? this.project.project_corporate_id : "";
  }

  get late(): boolean {
    return (
      this.status === PurchaseOrderStatus.ON_GOING &&
      this.percentDelivered < 100 &&
      new Date() > this.wanted_delivery_date
    );
  }

  get toPayLateInvoices(): Invoice[] {
    return this.onGoingInvoices.filter((x) => x.late).filter((x) => x);
  }
  get toPayLateInvoicesCount(): number {
    return this.toPayLateInvoices.length;
  }
  get toPayLateInvoicesValue(): number {
    return this.toPayLateInvoices.reduce((tot, item) => (tot += item.gross_total_price), 0);
  }

  get toPayNotLateInvoices(): Invoice[] {
    return this.onGoingInvoices.filter((x) => !x.late).filter((x) => x);
  }
  get toPayNotLateInvoicesCount(): number {
    return this.toPayNotLateInvoices.length;
  }
  get toPayNotLateInvoicesValue(): number {
    return this.toPayNotLateInvoices.reduce((tot, item) => (tot += item.gross_total_price), 0);
  }

  get percentDelivered(): number {
    return this.deliveries.length > 0 ? Math.max(...this.deliveries.map((x) => x.delivery_percent)) : 0.0;
  }

  get lastDeliveryDate(): Date {
    return this.deliveries.length > 0
      ? new Date(Math.max(...this.deliveries.map((x) => x.delivery_date.getTime())))
      : null;
  }

  get isConfirmationValid() {
    if (
      isStringEmpty(this.confirmation_number) ||
      this.confirmation_gross_price <= 0 ||
      isStringEmpty(this.confirmation_asset_id)
    ) {
      return false;
    }
    return true;
  }

  get consumedAmount(): number {
    if (this.status === PurchaseOrderStatus.CANCELLED) {
      return 0;
    } else {
      let total = this.totalBilled;
      return total;
    }
  }

  get projectedAmount(): number {
    let totalBilled = this.totalBilled;

    let total = 0;
    if (this.status === PurchaseOrderStatus.CANCELLED) {
      return 0;
    } else if (this.status === PurchaseOrderStatus.FINISHED) {
      total = this.totalBilled;
    } else {
      total = totalBilled > this.confirmation_gross_price ? totalBilled : this.confirmation_gross_price;
    }
    return total;
  }

  get addedCreditsNotes() {
    return this.validatedCreditsNotes.reduce((tot, item) => (tot += item.gross_price), 0);
  }

  get deductedCreditesNotes() {
    return round(
      this.validatedInvoices.reduce((tot, item) => (tot += item.credit_note_gross_price), 0),
      2
    );
  }

  get confirmation_net_price() {
    return this.confirmation_gross_price - this.addedCreditsNotes;
  }

  get gross_price() {
    let total = 0;
    if (this.percentBilled < 100) {
      total = this.confirmation_gross_price;
    } else {
      total = this.totalBilled;
    }
    return total;
  }

  get net_price() {
    let total = 0;
    if (this.percentBilled < 100) {
      total = this.confirmation_net_price;
    } else {
      total = this.totalBilled;
    }

    return total;
  }
}
