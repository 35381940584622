import { Employee } from "../../employees/domains/employee";
import { Expense } from "./expense";
import { ExpenseVat } from "./expense-vat";

export enum ExpenseReportStatus {
  ON_GOING = "ON_GOING",
  SUBMITTED = "SUBMITTED",
  CANCELLED = "CANCELLED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  PAID = "PAID",
}

export enum PaymentSourceOptions {
  CB_PERSO = "CB_PERSO",
  CB_COMPAGNY = "CB_COMPAGNY",
  COMPAGNY_FUND = "COMPAGNY_FUND",
}

export class ExpenseReport {
  expense_report_id: string;
  expense_report_corporate_id: string;
  eva_company_id: string;
  employee_id: string;
  name: string;
  desc: string;
  start_date: Date;
  end_date: Date;
  created_on: Date;
  updated_on: Date;
  currency: string;
  total_bt: number;
  total_vat: number;
  total_vats: ExpenseVat[];
  total_pt: number;
  employee_comment: string;
  expenses: Expense[];
  submitted_on: Date;
  payback_total_pt: number;
  admin_comment: string;
  accepted_on: Date;
  accepted_by: string;
  rejected_on: Date;
  rejected_by: string;
  paid_on: Date;
  paid_by: string;
  paid_comment: string;
  paid_salary_id: string;
  status: ExpenseReportStatus;

  // external data

  paid_by_employee: Employee = new Employee();
  rejected_by_employee: Employee = new Employee();

  constructor() {
    this.name = "";
    this.desc = "";
    this.currency = "CHF";
    this.start_date = new Date();
    this.end_date = new Date();
  }

  public static createInstance(jsonData: any): ExpenseReport {
    const e = Object.assign(new ExpenseReport(), jsonData);
    e.start_date = jsonData.start_date ? new Date(jsonData.start_date) : null;
    e.end_date = jsonData.end_date ? new Date(jsonData.end_date) : null;
    e.created_on = jsonData.created_on ? new Date(jsonData.created_on) : null;
    e.updated_on = jsonData.updated_on ? new Date(jsonData.updated_on) : null;
    e.submitted_on = jsonData.submitted_on ? new Date(jsonData.submitted_on) : null;
    e.paid_by_employee = jsonData.paid_by_employee ? Employee.createInstance(e.paid_by_employee) : null;
    e.rejected_by_employee = jsonData.rejected_by_employee ? Employee.createInstance(e.rejected_by_employee) : null;
    e.accepted_on = jsonData.accepted_on ? new Date(jsonData.accepted_on) : null;
    e.rejected_on = jsonData.rejected_on ? new Date(jsonData.rejected_on) : null;

    if (jsonData.total_vats) {
      e.total_vats = ExpenseVat.createInstances(jsonData.total_vats);
    }
    if (jsonData.expenses) {
      e.expenses = Expense.createInstances(jsonData.expenses);
    }

    return e;
  }

  public static createInstances(jsonData: any[]): ExpenseReport[] {
    return jsonData.map(ExpenseReport.createInstance);
  }

  clone(): ExpenseReport {
    let s = Object.assign(new ExpenseReport(), this);
    return s;
  }
}
