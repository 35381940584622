import { PurchaseOrderCreateDeliveryInput } from "./purchase-order-create-delivery.input";

export class PurchaseOrderUpdateDeliveryInput extends PurchaseOrderCreateDeliveryInput {
  purchase_order_delivery_id: string;

  public constructor(init?: Partial<PurchaseOrderUpdateDeliveryInput>) {
    super(init);
    Object.assign(this, init);
  }
}
