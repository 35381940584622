import { EvaLang } from "src/app/core/domains/eva-lang.enum";
import { Asset, EmployeeWeekActivity, Offer } from "src/app/domains";
import { EmployeeAudit } from "src/app/domains/audit";
import { Phone } from "src/app/domains/phone";

import { Tag } from "../../eva-company/domains/eva-company-tag";
import { PurchaseOrder } from "../../purchase-order/domains/purchase-order";
import { EmployeePositionHistory } from "./employee_position_history";
import { Salary } from "./salary";

export enum EmployeeStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export enum EmployeeType {
  PROD = "PROD",
  ADMIN = "ADMIN",
  OFFICE = "OFFICE",
  ACCOUNTANT = "ACCOUNTANT",
}

export enum EmployeeTypeLabel {
  PROD = "Production",
  ADMIN = "Administration",
  OFFICE = "Bureau technique",
  ACCOUNTANT = "Comptabilité",
}

export class Employee {
  employee_id: string = "";
  eva_company_id: string = "";
  first_name: string = "";
  last_name: string = "";
  email: string = "";
  birthday: Date;
  phone: string = "";
  phone_personnal: string = "";
  phone_perso_details: Phone;
  phone_pro_details: Phone;
  address: string = "";
  address_lat: number = 0;
  address_lng: number = 0;
  working_time_submit_mandatory: boolean = true;
  employee_position_histories: EmployeePositionHistory[] = [];
  employee_position_current: EmployeePositionHistory = new EmployeePositionHistory();
  updated_at: number = 0;
  is_employed: boolean = true;
  is_superadmin: boolean = false;
  send_notif_planning: boolean = false;
  avatar: string = "";
  user_hash: string = "";
  user_id: string = "";
  tags_ids: string[];
  tags: Tag[] = [];
  lang: EvaLang = EvaLang.FR;

  status: string = EmployeeStatus.ENABLED;

  salaries: Salary[] = [];
  purchase_orders: PurchaseOrder[] = [];
  offers: Offer[] = [];
  assets: Asset[] = [];
  audits: EmployeeAudit[] = [];
  activity: EmployeeAudit[] = [];
  employees_week_activity: EmployeeWeekActivity[] = [];

  get isAdmin(): boolean {
    return this.employee_position_current?.employee_type === EmployeeType.ADMIN;
  }

  get isOffice(): boolean {
    return [EmployeeType.OFFICE, EmployeeType.ADMIN].includes(this.employee_position_current?.employee_type);
  }

  constructor() {}

  public static createInstance(jsonData: any): Employee {
    if (jsonData.hasOwnProperty("display_name")) {
      delete jsonData.display_name; //display_name is now only a getter, delete in case it still is in the local storage
    }
    const e = Object.assign(new Employee(), jsonData);
    if (jsonData.employee_position_histories) {
      e.employee_position_histories = EmployeePositionHistory.createInstances(jsonData.employee_position_histories);
    }
    if (jsonData.employee_position_current) {
      e.employee_position_current = EmployeePositionHistory.createInstance(jsonData.employee_position_current);
    }
    if (jsonData.birthday) {
      e.birthday = new Date(jsonData.birthday);
    }
    if (jsonData.salaries) {
      e.salaries = Salary.createInstances(jsonData.salaries);
    }
    if (jsonData.purchase_orders) {
      e.purchase_orders = PurchaseOrder.createInstances(jsonData.purchase_orders);
    }
    if (jsonData.offers) {
      e.offers = Offer.createInstances(jsonData.offers);
    }
    if (jsonData.assets) {
      e.assets = Asset.createInstances(jsonData.assets);
    }
    if (jsonData.audits) {
      e.audits = EmployeeAudit.createInstances(jsonData.audits);
    }
    if (jsonData.activity) {
      e.activity = EmployeeAudit.createInstances(jsonData.activity);
    }
    if (jsonData.employees_week_activity) {
      e.employees_week_activity = EmployeeWeekActivity.createInstances(jsonData.employees_week_activity);
    }
    if (jsonData.email) {
      e.email = jsonData.email.toLowerCase();
    }
    if (jsonData.tags) {
      e.tags = Tag.createInstances(jsonData.tags);
    }
    if (jsonData.phone_perso_details) {
      e.phone_perso_details = Phone.createInstance(jsonData.phone_perso_details);
    }
    if (jsonData.phone_pro_details) {
      e.phone_pro_details = Phone.createInstance(jsonData.phone_pro_details);
    }

    return e;
  }

  public static createInstances(jsonData: any[]): Employee[] {
    return jsonData.map(Employee.createInstance);
  }

  excelExportEmployee(financialData = false) {
    let baseJSON = {
      id: this.employee_id,
      firstname: this.first_name,
      lastname: this.last_name,
      birthday: this.birthday,
      type: this.employee_position_current.employee_type,
      email: this.email,
      phone: this.phone,
      phone_personnal: this.phone_personnal,
      address: this.address,
      status: this.status,
      position_start_date: this.employee_position_current.position_start_date,
      position_end_date: this.employee_position_current.position_end_date,
      manager_id: this.employee_position_current.manager_id,
    };
    if (financialData) {
      baseJSON["hour_cost"] = this.employee_position_current?.hour_cost;
      baseJSON["month_cost"] = this.employee_position_current?.month_cost;
      baseJSON["weekly_hours"] = this.employee_position_current?.weekly_hours;
      baseJSON["monthly_hours"] = this.employee_position_current?.monthly_hours;
    }
    return baseJSON;
  }

  clone(): Employee {
    let e = Object.assign(new Employee(), this);
    e.employee_position_histories = EmployeePositionHistory.createInstances(e.employee_position_histories);
    e.employee_position_current = EmployeePositionHistory.createInstance(e.employee_position_current);
    e.salaries = Salary.createInstances(e.salaries);
    e.purchase_orders = PurchaseOrder.createInstances(e.purchase_orders);
    e.offers = Offer.createInstances(e.offers);
    e.assets = Asset.createInstances(e.assets);
    e.audits = EmployeeAudit.createInstances(e.audits);
    e.activity = EmployeeAudit.createInstances(e.activity);
    e.employees_week_activity = EmployeeWeekActivity.createInstances(e.employees_week_activity);
    return e;
  }

  get hired_since(): Date {
    let start_dates = this.employee_position_histories.map((d) => d.position_start_date);
    return new Date(Math.min.apply(null, start_dates));
  }

  static get statusLabel() {
    return {
      [EmployeeStatus.ENABLED]: "Activé",
      [EmployeeStatus.DISABLED]: "Désactivé",
    };
  }

  get position_start_date() {
    return this.employee_position_current.position_start_date;
  }

  set position_start_date(position_start_date: Date) {
    this.employee_position_current.position_start_date = position_start_date;
  }

  get last_position() {
    return this.employee_position_histories.reduce((prev, current) =>
      prev.position_end_date > current.position_end_date ? prev : current
    );
  }

  get position_end_date() {
    return this.employee_position_current.position_end_date;
  }

  set position_end_date(position_end_date: Date) {
    this.employee_position_current.position_end_date = position_end_date;
  }

  get employee_type() {
    return this.employee_position_current.employee_type;
  }

  set employee_type(employeeType: EmployeeType) {
    this.employee_position_current.employee_type = employeeType;
  }

  set manager_id(manager_id: string) {
    this.employee_position_current.manager_id = manager_id;
  }

  get manager_id() {
    return this.employee_position_current.manager_id;
  }

  set hour_cost(hour_cost: number) {
    this.employee_position_current.hour_cost = hour_cost;
  }

  get hour_cost() {
    return this.employee_position_current.hour_cost;
  }

  set monthly_hours(monthly_hours: number) {
    this.employee_position_current.monthly_hours = monthly_hours;
  }

  get monthly_hours() {
    return this.employee_position_current.monthly_hours;
  }

  set weekly_hours(weekly_hours: number) {
    this.employee_position_current.weekly_hours = weekly_hours;
  }

  get weekly_hours() {
    return this.employee_position_current?.weekly_hours;
  }

  get display_name() {
    return this.first_name.trim() + " " + this.last_name.trim();
  }

  get short_display_name() {
    return this.first_name.substring(0, 1) + " " + this.last_name.trim();
  }

  get emailSendTo(): string {
    return `${this.display_name} <${this.email}>`;
  }

  dateAreValid() {
    let allDates = this.employee_position_histories.map((x) => ({
      start: x.position_start_date,
      end: x.position_end_date,
    }));
    let dateValid = true;
    allDates.map((x, i) =>
      allDates.map((y, j) => {
        if (i != j && !((x.end < y.start && x.start < y.start) || (x.end > y.end && x.start > y.end))) {
          dateValid = false;
        }
      })
    );

    return dateValid;
  }

  findHistoryIndex(position: EmployeePositionHistory) {
    return this.employee_position_histories.findIndex(
      (x) => x.employee_position_history_id === position.employee_position_history_id
    );
  }

  getPositionFromDate(date: Date) {
    const dateOnly = date.toISOString().split("T")[0];

    return this.employee_position_histories.find((x) => {
      const startDateOnly = new Date(x.position_start_date).toISOString().split("T")[0];
      const endDateOnly = new Date(x.position_end_date).toISOString().split("T")[0];
      return startDateOnly <= dateOnly && endDateOnly >= dateOnly;
    });
  }

  salariesFromYear(year: number) {
    return this.salaries.filter((x) => x.year === year);
  }

  allSalariesYear() {
    return this.salaries.map((x) => x.year).filter((el, index, array) => array.indexOf(el) === index);
  }
}
