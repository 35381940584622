import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { NgxFileDropModule } from "ngx-file-drop";
// third parties
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
// primeng
import { AccordionModule } from "primeng/accordion";
import { BadgeModule } from "primeng/badge";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { ChartModule } from "primeng/chart";
import { CheckboxModule } from "primeng/checkbox";
import { ChipsModule } from "primeng/chips";
import { ColorPickerModule } from "primeng/colorpicker";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ContextMenuModule } from "primeng/contextmenu";
import { DataViewModule } from "primeng/dataview";
import { DialogModule } from "primeng/dialog";
import { DragDropModule } from "primeng/dragdrop";
import { DropdownModule } from "primeng/dropdown";
import { EditorModule } from "primeng/editor";
import { FileUploadModule } from "primeng/fileupload";
import { GMapModule } from "primeng/gmap";
import { ImageModule } from "primeng/image";
import { InplaceModule } from "primeng/inplace";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ListboxModule } from "primeng/listbox";
import { MenuModule } from "primeng/menu";
import { MessageModule } from "primeng/message";
import { MultiSelectModule } from "primeng/multiselect";
import { OrderListModule } from "primeng/orderlist";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { ProgressBarModule } from "primeng/progressbar";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { RadioButtonModule } from "primeng/radiobutton";
import { RatingModule } from "primeng/rating";
import { RippleModule } from "primeng/ripple";
import { SelectButtonModule } from "primeng/selectbutton";
import { SidebarModule } from "primeng/sidebar";
import { SliderModule } from "primeng/slider";
import { SplitButtonModule } from "primeng/splitbutton";
import { StepsModule } from "primeng/steps";
import { TableModule } from "primeng/table";
import { TabMenuModule } from "primeng/tabmenu";
import { TabViewModule } from "primeng/tabview";
import { TagModule } from "primeng/tag";
import { TimelineModule } from "primeng/timeline";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";
import { TooltipModule } from "primeng/tooltip";
import { TreeSelectModule } from "primeng/treeselect";
import { TreeTableModule } from "primeng/treetable";
import { VirtualScrollerModule } from "primeng/virtualscroller";

//CDK
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
// NGB
import { NgbProgressbarModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { GanttModule } from "@syncfusion/ej2-angular-gantt";
import { ScheduleModule } from "@syncfusion/ej2-angular-schedule";

// internal components
import { BudgetGlobalDetailsComponent } from "../features/budget/budget-global-details/budget-global-details.component";
import { ArticleFormComponent } from "../features/catalog/article-form/article-form.component";
import { ArticleListComponent } from "../features/catalog/article-list/article-list.component";
import { PackArticlesFormComponent } from "../features/catalog/pack-articles-form/pack-articles-form.component";
import { PackArticlesListComponent } from "../features/catalog/pack-articles-list/pack-articles-list.component";
import { ContactFormComponent } from "../features/contact/contact-details/contact-form/contact-form.component";
import { ContactLinksComponent } from "../features/contact/contact-details/contact-links/contact-links.component";
import { CustomerCreateModalComponent } from "../features/customer/customer-create-modal/customer-create-modal.component";
import { CustomerCreateComponent } from "../features/customer/customer-create/customer-create.component";
import { CustomerDetailsFormComponent } from "../features/customer/customer-details/customer-details-form/customer-details-form.component";
import { EventDetailsComponent } from "../features/event/event-details/event-details.component";
import { EventRecentComponent } from "../features/event/event-recent/event-recent.component";
import { ExpenseReportToPayComponent } from "../features/expense-report/expense-report-to-pay/expense-report-to-pay.component";
import { OfferV2PdfQuotationComponent } from "../features/offer-v2/offer-v2-pdf/offer-v2-pdf-quotation/offer-v2-pdf-quotation.component";
import { ProjectFormComponent } from "../features/project/project-form/project-form.component";
import { PurchaseOrderConfirmationComponent } from "../features/purchase-order/purchase-order-confirmation/purchase-order-confirmation.component";
import { PurchaseOrderInvoiceComponent } from "../features/purchase-order/purchase-order-invoice/purchase-order-invoice.component";
import { SupplierCreateModalComponent } from "../features/supplier/supplier-create-modal/supplier-create-modal.component";
import { SupplierCreateComponent } from "../features/supplier/supplier-create/supplier-create.component";
import { SupplierCreditNoteFormComponent } from "../features/supplier/supplier-details/supplier-credit-note-form/supplier-credit-note-form.component";
import { SupplierCreditNoteComponent } from "../features/supplier/supplier-details/supplier-credit-note/supplier-credit-note.component";
import { SupplierDetailsFormComponent } from "../features/supplier/supplier-details/supplier-details-form/supplier-details-form.component";
import { WorkingActivityAddEditFormComponent } from "../features/working-activity/working-activity-add-edit-form/working-activity-add-edit-form.component";
import { WorkingActivityWeekSummaryComponent } from "../features/working-activity/working-activity-week-summary/working-activity-week-summary.component";
import { WorkshopCreateComponent } from "../features/workshop/workshop-create/workshop-create.component";
import { WorkshopsDetailsFormComponent } from "../features/workshop/workshops-details/workshops-details-form/workshops-details-form.component";
import { CompanySettingsFormComponent } from "../layouts/auth-layout/on-boarding/company-settings-form/company-settings-form.component";
import { SignUpFormComponent } from "../layouts/auth-layout/on-boarding/sign-up-form/sign-up-form.component";
import { SignUpComponent } from "../layouts/auth-layout/on-boarding/sign-up/sign-up.component";
import { EvaAssetsListComponent } from "./components/eva-assets-list/eva-assets-list.component";
import { EvaMultiAdressFormComponent } from "./components/eva-multi-address-form/eva-multi-address-form.component";
import { EvaMultiAdressComponent } from "./components/eva-multi-address/eva-multi-address.component";
import { EvaOfferBillingComponent } from "./components/eva-offer-billing/eva-offer-billing.component";
import { EvaOfferInvoiceComponent } from "./components/eva-offer-billing/eva-offer-invoice/eva-offer-invoice.component";
import { EvaProgressBarComponent } from "./components/eva-progress-bar/eva-progress-bar.component";
import { EvaSearchBarComponent } from "./components/eva-search-bar/eva-search-bar.component";
import { EvaSearchResultComponent } from "./components/eva-search-result/eva-search-result.component";
import { EvaSendMailComponent } from "./components/eva-send-mail/eva-send-mail.component";
import { EvaTagsOnListComponent } from "./components/eva-tags-on-list/eva-tags-on-list.component";
import { EvaTagsComponent } from "./components/eva-tags/eva-tags.component";
import { TagFormComponent } from "./components/eva-tags/tag-form/tag-form.component";
import { EvaUploadComponent } from "./components/eva-upload/eva-upload.component";
import { DisableAutofillDirective } from "./directives";
import { SwissDatePipe, SwissDateTimePipe, WeekPipe } from "./pipes";
import { SwissCurrencyWithApostrophePipe } from "./pipes/swiss-currency-with-apostrophe.pipe";
import { SwissCurrencyPipe } from "./pipes/swiss-currency.pipe";
import { SwissNumberPipe } from "./pipes/swiss-number.pipe";

// core
@NgModule({
  imports: [
    // angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,

    // third party
    GooglePlaceModule,
    NgxDocViewerModule,
    NgxFileDropModule,
    NgxIntlTelInputModule,
    NgSelectModule,
    ImageCropperModule,
    GanttModule,
    ScheduleModule,
    NgxDaterangepickerMd.forRoot(),

    //primeng
    ImageModule,
    VirtualScrollerModule,
    EditorModule,
    FileUploadModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    TabMenuModule,
    StepsModule,
    TabViewModule,
    DropdownModule,
    ChipsModule,
    DialogModule,
    CheckboxModule,
    InputTextareaModule,
    ListboxModule,
    GMapModule,
    RadioButtonModule,
    TreeTableModule,
    SplitButtonModule,
    MenuModule,
    InputNumberModule,
    ConfirmDialogModule,
    TooltipModule,
    ProgressBarModule,
    SidebarModule,
    SliderModule,
    CalendarModule,
    ToggleButtonModule,
    ColorPickerModule,
    MultiSelectModule,
    TreeSelectModule,
    AccordionModule,
    MessageModule,
    ProgressSpinnerModule,
    OrderListModule,
    TimelineModule,
    ChartModule,
    PanelModule,
    CardModule,
    PaginatorModule,
    DataViewModule,
    RatingModule,
    TagModule,
    InputSwitchModule,
    SelectButtonModule,
    ConfirmPopupModule,
    ContextMenuModule,
    InputMaskModule,
    InplaceModule,
    RippleModule,
    BadgeModule,
    OverlayPanelModule,

    // NGB
    NgbProgressbarModule,

    //CDK
    DragDropModule,
  ],
  declarations: [
    WeekPipe,
    SwissDatePipe,
    SwissDateTimePipe,
    SwissCurrencyPipe,
    SwissCurrencyWithApostrophePipe,
    SwissNumberPipe,
    DisableAutofillDirective,
    ProjectFormComponent,
    WorkingActivityAddEditFormComponent,
    BudgetGlobalDetailsComponent,
    EvaProgressBarComponent,
    EvaUploadComponent,
    SupplierCreateModalComponent,
    SupplierCreateComponent,
    SupplierDetailsFormComponent,
    WorkshopCreateComponent,
    WorkshopsDetailsFormComponent,
    CustomerCreateModalComponent,
    CustomerCreateComponent,
    CustomerDetailsFormComponent,
    EvaAssetsListComponent,
    EventRecentComponent,
    EventDetailsComponent,
    EvaSearchBarComponent,
    EvaSearchResultComponent,
    ContactLinksComponent,
    ContactFormComponent,
    EvaMultiAdressComponent,
    EvaTagsComponent,
    EvaTagsOnListComponent,
    SignUpComponent,
    ArticleFormComponent,
    ArticleListComponent,

    PackArticlesListComponent,
    WorkingActivityWeekSummaryComponent,
    PackArticlesFormComponent,
    SignUpFormComponent,
    CompanySettingsFormComponent,
    EvaMultiAdressFormComponent,
    ExpenseReportToPayComponent,
    SupplierCreditNoteComponent,
    SupplierCreditNoteFormComponent,
    TagFormComponent,
    EvaOfferBillingComponent,
    EvaSendMailComponent,
    EvaOfferInvoiceComponent,
    PurchaseOrderConfirmationComponent,
    PurchaseOrderInvoiceComponent,
    OfferV2PdfQuotationComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    NgSelectModule,
    NgxFileDropModule,
    NgxIntlTelInputModule,
    NgxDocViewerModule,
    ImageCropperModule,
    GanttModule,
    ScheduleModule,

    WeekPipe,
    SwissDatePipe,
    SwissCurrencyPipe,
    SwissCurrencyWithApostrophePipe,
    SwissNumberPipe,
    SwissDateTimePipe,
    DisableAutofillDirective,
    NgxDaterangepickerMd,

    // components
    ProjectFormComponent,
    WorkingActivityAddEditFormComponent,
    BudgetGlobalDetailsComponent,
    SupplierCreateModalComponent,
    SupplierCreateComponent,
    SupplierDetailsFormComponent,
    WorkshopCreateComponent,
    WorkshopsDetailsFormComponent,
    CustomerCreateModalComponent,
    CustomerCreateComponent,
    CustomerDetailsFormComponent,
    EventRecentComponent,
    EventDetailsComponent,
    ContactLinksComponent,
    ContactFormComponent,
    ExpenseReportToPayComponent,
    SupplierCreditNoteComponent,
    SupplierCreditNoteFormComponent,
    TagFormComponent,
    PurchaseOrderConfirmationComponent,
    PurchaseOrderInvoiceComponent,
    OfferV2PdfQuotationComponent,

    // primeng
    ImageModule,
    VirtualScrollerModule,
    EditorModule,
    FileUploadModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    TabMenuModule,
    StepsModule,
    TabViewModule,
    DropdownModule,
    ChipsModule,
    ToggleButtonModule,
    DialogModule,
    CheckboxModule,
    InputTextareaModule,
    ListboxModule,
    GMapModule,
    RadioButtonModule,
    TreeTableModule,
    SplitButtonModule,
    MenuModule,
    InputNumberModule,
    ConfirmDialogModule,
    TooltipModule,
    ProgressBarModule,
    SidebarModule,
    SliderModule,
    CalendarModule,
    ColorPickerModule,
    MultiSelectModule,
    TreeSelectModule,
    AccordionModule,
    MessageModule,
    ProgressSpinnerModule,
    OrderListModule,
    TimelineModule,
    ChartModule,
    PanelModule,
    CardModule,
    PaginatorModule,
    DataViewModule,
    RatingModule,
    TagModule,
    InputSwitchModule,
    SelectButtonModule,
    ConfirmPopupModule,
    ContextMenuModule,
    InputMaskModule,
    StepsModule,
    ToastModule,
    InplaceModule,
    RippleModule,
    BadgeModule,
    OverlayPanelModule,

    // NGB
    NgbProgressbarModule,

    //CDK

    DragDropModule,

    // EVA CUSTOM
    EvaProgressBarComponent,
    EvaUploadComponent,
    EvaAssetsListComponent,
    EvaSearchBarComponent,
    EvaMultiAdressComponent,
    EvaTagsComponent,
    EvaTagsOnListComponent,
    SignUpComponent,
    ArticleFormComponent,
    ArticleListComponent,
    PackArticlesListComponent,
    WorkingActivityWeekSummaryComponent,
    PackArticlesFormComponent,
    SignUpFormComponent,
    CompanySettingsFormComponent,
    EvaMultiAdressFormComponent,
    EvaOfferBillingComponent,
    EvaSendMailComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
