import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { SearchItem } from "../domains/searchItem";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  public url = environment.apiUrl + "/search";

  constructor(private http: HttpClient) {}

  getSearch(query: string, limit_items: number = 30): Observable<SearchItem[]> {
    const params = new HttpParams().set("query", query).set("limit_items", limit_items);
    return this.http
      .get<SearchItem[]>(this.url, {
        params: params,
      })
      .pipe(map(SearchItem.createInstances));
  }
}
