export enum AddressObjectType {
  CUSTOMER = "CUSTOMER",
  SUPPLIER = "SUPPLIER",
}

export class EvaAddress {
  address_id = "";
  recipient = "";
  address_type = "";
  street = "";
  street_2 = "";
  postcode = "";
  city = "";
  region = "";
  country = "";
  lng = 0.0;
  lat = 0.0;
  is_for_offers = false;
  is_for_invoices = false;
  is_for_purchase_orders = false;

  get inlineAddress(): string {
    let inlineAddress = "";

    if (this.street) inlineAddress += this.street.trim() + ", ";
    if (this.street_2) inlineAddress += this.street_2.trim() + ", ";
    if (this.postcode) inlineAddress += this.postcode.trim() + " ";
    if (this.city) inlineAddress += this.city.trim() + ", ";
    if (this.country) inlineAddress += this.country.trim();

    if (inlineAddress.trim().endsWith(",")) {
      inlineAddress = inlineAddress.trim().substring(0, inlineAddress.trim().length - 1);
    }
    if (inlineAddress.trim().startsWith(",")) {
      inlineAddress = inlineAddress.trim().substring(1);
    }

    return inlineAddress.trim();
  }

  get inlineAddressWithRecipient(): string {
    return (this.recipient != "" ? this.recipient + ", " : "") + this.inlineAddress;
  }
  get multiLinesAddress(): string {
    let multiLinesAddress = "";

    if (this.recipient) multiLinesAddress += this.recipient.trim() + "\n";
    if (this.street) multiLinesAddress += this.street.trim() + "\n";
    if (this.street_2) multiLinesAddress += this.street_2.trim() + "\n";
    if (this.postcode) multiLinesAddress += this.postcode.trim() + " ";
    if (this.city) multiLinesAddress += this.city.trim() + "\n";
    if (this.country) multiLinesAddress += this.country.trim();

    if (!this.country) {
      multiLinesAddress = multiLinesAddress.replace(/\n$/, "");
    }
    return multiLinesAddress;
  }
  get multiLinesAddressWithoutCountry(): string {
    let multiLinesAddress = "";

    if (this.recipient) multiLinesAddress += this.recipient.trim() + "\n";
    if (this.street) multiLinesAddress += this.street.trim() + "\n";
    if (this.street_2) multiLinesAddress += this.street_2.trim() + "\n";
    if (this.postcode) multiLinesAddress += this.postcode.trim() + " ";
    if (this.city) multiLinesAddress += this.city.trim();

    return multiLinesAddress;
  }

  get htmlMultilinesAddress(): string {
    let multiLinesAddress = "";

    if (this.street) multiLinesAddress += this.street.trim() + "\n";
    if (this.street_2) multiLinesAddress += this.street_2.trim() + "\n";
    if (this.postcode) multiLinesAddress += this.postcode.trim() + " ";
    if (this.city) multiLinesAddress += this.city.trim() + "\n";
    if (this.country) multiLinesAddress += this.country.trim();

    if (!this.country) {
      multiLinesAddress = multiLinesAddress.replace(/\n$/, "");
    }
    return multiLinesAddress.replace(/\n/g, "<br/>");
  }
  get htmlMultilinesAddresswithoutCountry(): string {
    let multiLinesAddress = "";
    if (this.street) multiLinesAddress += this.street.trim() + "\n";
    if (this.street_2) multiLinesAddress += this.street_2.trim() + "\n";
    if (this.postcode) multiLinesAddress += this.postcode.trim() + " ";
    if (this.city) multiLinesAddress += this.city.trim();
    return multiLinesAddress.replace(/\n/g, "<br/>");
  }

  constructor() {
    this.is_for_offers = true;
    this.is_for_invoices = true;
    this.is_for_purchase_orders = true;
    this.address_type = "Adresse principale";
  }

  public static createInstance(jsonData: any): EvaAddress {
    return Object.assign(new EvaAddress(), jsonData);
  }

  public static createInstances(jsonData: any[]): EvaAddress[] {
    return jsonData.map(EvaAddress.createInstance);
  }
}
