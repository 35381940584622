import { map, Observable, shareReplay } from "rxjs";
import {
  Employee,
  EmployeeStatus,
  EmployeeType,
  EvaCompany,
  GlobalConfigInvoice,
  GlobalConfigOffer,
  OfferItemGroup,
  OfferSignature,
  User,
} from "src/app/domains/internal";
import { EventBusService } from "src/app/services/event-bus.service";
import { AppEvent } from "src/app/shared/enums/app-event.enum";
import { environment } from "src/environments/environment";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { EvaService } from "../../../core";
import { VAT } from "../../../domains/vat";
import { InterventionType } from "../../../features/eva-company/domains/intervention_type";
import { InterventionTypeField } from "../../../features/eva-company/domains/intervention_type_field";
import { AuthenticationService } from "../../../services/authentication.service";
import { EmployeesService } from "../../employees/services/employees.service";
import { InterventionAction } from "../../interventions/domains/intervention_action";
import { HrYearlyConfig } from "../domains/hr-yearly-config";
import { PeriodClosed } from "../domains/period-closed";
import { MealBreak } from "../eva-company-hr/meal-break";

@Injectable({ providedIn: "root" })
export class EvaCompanyService {
  url = environment.apiUrl + "/eva/companies";
  urlYearlyConfig = environment.apiUrl + "/yearly-configs";
  urlInterventions = environment.apiUrl + "/interventions";

  public currentEvaCompany: EvaCompany;
  public currentEvaCompaniesAvailable: EvaCompany[] = [];

  public currentEvaEmployee: Employee;

  constructor(
    private http: HttpClient,
    private router: Router,
    private employeeService: EmployeesService,
    private authenticationService: AuthenticationService,
    private evaService: EvaService
  ) {
    this.load();
  }

  getCurrentEvaCompany(): EvaCompany {
    if ("currentEvaCompany" in localStorage) {
      return EvaCompany.createInstance(JSON.parse(this.getFromSessionThenLocalStorage("currentEvaCompany")));
    } else {
      this.router.navigate(["/login"]);
      return new EvaCompany();
    }
  }

  getCurrentEvaCompaniesAvailable(): EvaCompany[] {
    if ("currentEvaCompaniesAvailable" in localStorage) {
      return EvaCompany.createInstances(JSON.parse(localStorage.getItem("currentEvaCompaniesAvailable")));
    } else {
      this.router.navigate(["/login"]);
      return [new EvaCompany()];
    }
  }

  getCurrentEvaEmployee(): Employee {
    if ("currentEvaEmployee" in localStorage) {
      return Employee.createInstance(JSON.parse(this.getFromSessionThenLocalStorage("currentEvaEmployee")));
    } else {
      this.router.navigate(["/login"]);
      return new Employee();
    }
  }

  create(evaCompany: EvaCompany, employee: Employee, user: User) {
    return this.http.post<any>(this.url + "/create-eva-company", {
      evaCompany: evaCompany,
      employee: employee,
      user: user,
    });
  }

  public changeCompany(evaCompanyId: string, redirectToHome = true, refreshOnly = false): void {
    this.evaService.showLoader();

    const evaCompany = this.currentEvaCompaniesAvailable.find((x) => x.eva_company_id === evaCompanyId);

    this.updateEvaCompany(evaCompany);

    this.employeeService.getCurrentEmployee(false).subscribe(
      (employee) => {
        if (employee.status === EmployeeStatus.DISABLED) {
          this.authenticationService.logout();
          this.router.navigate(["#/login"]);
        }
        this.updateEmployee(employee);
        this.evaService.showSuccess("Désormais connecté sur " + evaCompany.name);
        this.evaService.hideLoader();
        if (redirectToHome) this.redirectTo("/home");
        if (window.location.hash === "#/home" || refreshOnly) {
          window.location.reload();
        }
      },
      (error) => {
        this.evaService.showError(
          "Une erreur est survenue lors de la connexion à " + evaCompany.name,
          "changeCompany",
          error
        );
        this.evaService.hideLoader();
      }
    );
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => this.router.navigate([uri]));
  }

  load(): void {
    try {
      if ("currentEvaCompany" in sessionStorage || "currentEvaCompany" in localStorage) {
        this.currentEvaCompany = EvaCompany.createInstance(
          JSON.parse(this.getFromSessionThenLocalStorage("currentEvaCompany"))
        );
        this.currentEvaCompaniesAvailable = EvaCompany.createInstances(
          JSON.parse(localStorage.getItem("currentEvaCompaniesAvailable"))
        );
        this.currentEvaEmployee = JSON.parse(this.getFromSessionThenLocalStorage("currentEvaEmployee"));
      } else if ("evaUser" in localStorage) {
        this.getAllLight().subscribe(
          (evaCompanies) => {
            if (evaCompanies.length > 0) {
              this.updateEvaCompanies(evaCompanies);
              this.updateEvaCompany(evaCompanies[0]);
              this.employeeService.getCurrentEmployee().subscribe((employee) => {
                if (employee.status === EmployeeStatus.DISABLED) {
                  this.authenticationService.logout();
                  this.router.navigate(["#/login"]);
                }
                this.updateEmployee(employee);
                this.router.navigate(["/"]);
              });
            }
          },
          (error) => console.error(error)
        );
      }
    } catch (error) {
      this.authenticationService.logout();
    }
  }

  updateEmployee(employee: Employee) {
    this.currentEvaEmployee = employee;
    this.setSessionAndLocalStorage("currentEvaEmployee", JSON.stringify(this.currentEvaEmployee));
  }

  updateEvaCompany(evaCompany: EvaCompany) {
    this.currentEvaCompany = evaCompany;
    this.setSessionAndLocalStorage("currentEvaCompany", JSON.stringify(this.currentEvaCompany));
  }

  updateEvaCompanies(evaCompanies: EvaCompany[]) {
    this.currentEvaCompaniesAvailable = evaCompanies;
    localStorage.setItem("currentEvaCompaniesAvailable", JSON.stringify(this.currentEvaCompaniesAvailable));
  }

  getAll(): Observable<EvaCompany[]> {
    return this.http.get<EvaCompany[]>(this.url).pipe(map(EvaCompany.createInstances));
  }

  getAllLight(): Observable<EvaCompany[]> {
    return this.http.get<EvaCompany[]>(this.url + "/light").pipe(map(EvaCompany.createInstances));
  }

  get(id): Observable<EvaCompany> {
    return this.http.get<EvaCompany>(this.url + "/" + id).pipe(map(EvaCompany.createInstance));
  }

  add(evaCompany: EvaCompany): Observable<EvaCompany> {
    return this.http.post<EvaCompany>(this.url, evaCompany).pipe(map(EvaCompany.createInstance));
  }

  edit(evaCompany: EvaCompany): Observable<EvaCompany> {
    return this.http
      .put<EvaCompany>(this.url + "/" + evaCompany.eva_company_id, evaCompany)
      .pipe(map(EvaCompany.createInstance));
  }

  editDocs(evaCompany: EvaCompany): Observable<EvaCompany> {
    return this.http
      .put<EvaCompany>(this.url + "/doc-infos/" + evaCompany.eva_company_id, evaCompany)
      .pipe(map(EvaCompany.createInstance));
  }

  editPredifinedTimes(evaCompany: EvaCompany): Observable<EvaCompany> {
    return this.http
      .put<EvaCompany>(this.url + "/predifined-times/" + evaCompany.eva_company_id, evaCompany)
      .pipe(map(EvaCompany.createInstance));
  }

  resetPredifinedTimes(eva_company: EvaCompany): Observable<EvaCompany> {
    return this.http
      .put<EvaCompany>(this.url + "/reset-predifined-times", eva_company)
      .pipe(map(EvaCompany.createInstance));
  }

  updateBillingConfig(evaCompany: EvaCompany): Observable<EvaCompany> {
    return this.http
      .put<EvaCompany>(this.url + "/" + evaCompany.eva_company_id + "/billing-config", {
        billing_config: evaCompany.billing_config,
        intervention_config: evaCompany.intervention_config,
      })
      .pipe(map(EvaCompany.createInstance));
  }

  updateExpenseReportConfig(evaCompany: EvaCompany): Observable<EvaCompany> {
    return this.http
      .put<EvaCompany>(
        this.url + "/" + evaCompany.eva_company_id + "/expense-report-config",
        evaCompany.expense_report_config
      )
      .pipe(map(EvaCompany.createInstance));
  }

  editFeatures(evaCompany: EvaCompany): Observable<EvaCompany> {
    return this.http.put<EvaCompany>(this.url + "/" + evaCompany.eva_company_id + "/features", {
      features_enabled: evaCompany.features_enabled,
    });
  }

  delete(eva_company_id: string): Observable<any> {
    return this.http.delete(this.url + "/" + eva_company_id);
  }

  //Yearly config

  public getAllYearlyConfigs(): Observable<HrYearlyConfig[]> {
    return this.http.get<HrYearlyConfig[]>(this.urlYearlyConfig).pipe(map(HrYearlyConfig.createInstances));
  }
  public addYearlyConfig(yearConfig: HrYearlyConfig): Observable<HrYearlyConfig> {
    return this.http.post<HrYearlyConfig>(this.urlYearlyConfig, yearConfig).pipe(map(HrYearlyConfig.createInstance));
  }
  public addYearlyConfigWithYear(year: number): Observable<HrYearlyConfig> {
    return this.http
      .post<HrYearlyConfig>(this.urlYearlyConfig + "/year", year)
      .pipe(map(HrYearlyConfig.createInstance));
  }
  public getYearlyConfig(id: string): Observable<HrYearlyConfig> {
    return this.http.get<HrYearlyConfig>(this.urlYearlyConfig + "/" + id).pipe(map(HrYearlyConfig.createInstance));
  }
  public getYearlyConfigFromYear(year: number): Observable<HrYearlyConfig> {
    return this.http.get<HrYearlyConfig>(this.urlYearlyConfig + "/" + year).pipe(map(HrYearlyConfig.createInstance));
  }
  public updateYearlyConfig(yearConfig: HrYearlyConfig): Observable<HrYearlyConfig> {
    return this.http
      .put<HrYearlyConfig>(this.urlYearlyConfig + "/" + yearConfig.hr_yearly_config_id, yearConfig)
      .pipe(map(HrYearlyConfig.createInstance));
  }

  public addPeriodClosed(period_closed: PeriodClosed, id): Observable<HrYearlyConfig> {
    return this.http
      .post<HrYearlyConfig>(this.urlYearlyConfig + "/period-closed/" + id, period_closed)
      .pipe(map(HrYearlyConfig.createInstance));
  }
  public deletePeriodClosed(id): Observable<HrYearlyConfig> {
    return this.http
      .delete<HrYearlyConfig>(this.urlYearlyConfig + "/period-closed/" + id)
      .pipe(map(HrYearlyConfig.createInstance));
  }
  public updatePeriodClosed(period_closed: PeriodClosed, id): Observable<HrYearlyConfig> {
    return this.http
      .put<HrYearlyConfig>(this.urlYearlyConfig + "/period-closed/" + id, period_closed)
      .pipe(map(HrYearlyConfig.createInstance));
  }

  public addVat(vat: VAT): Observable<EvaCompany> {
    return this.http.post<EvaCompany>(this.url + "/vat", vat).pipe(map(EvaCompany.createInstance));
  }

  public deleteVat(id): Observable<EvaCompany> {
    return this.http.delete<EvaCompany>(this.url + "/vat/" + id).pipe(map(EvaCompany.createInstance));
  }

  public updateVat(vat: VAT): Observable<EvaCompany> {
    return this.http.put<EvaCompany>(this.url + "/vat", vat).pipe(map(EvaCompany.createInstance));
  }
  public resetDocInfos(eva_company: EvaCompany): Observable<EvaCompany> {
    return this.http.put<EvaCompany>(this.url + "/reset-docs-infos", eva_company).pipe(map(EvaCompany.createInstance));
  }

  public resetDocInfosByFilter(eva_company: EvaCompany, filter: string): Observable<EvaCompany> {
    return this.http
      .put<EvaCompany>(this.url + "/reset-docs-infos/" + filter, eva_company)
      .pipe(map(EvaCompany.createInstance));
  }

  public addMealBreak(mealBreak: MealBreak): Observable<EvaCompany> {
    return this.http.post<EvaCompany>(this.url + "/meal-break", mealBreak).pipe(map(EvaCompany.createInstance));
  }

  public deleteMealBreak(meal_break_id): Observable<EvaCompany> {
    return this.http.delete<EvaCompany>(this.url + "/meal-break/" + meal_break_id).pipe(map(EvaCompany.createInstance));
  }

  public updateMealBreak(meal_break_id, mealBreak: MealBreak): Observable<EvaCompany> {
    return this.http
      .put<EvaCompany>(this.url + "/meal-break/" + meal_break_id, mealBreak)
      .pipe(map(EvaCompany.createInstance));
  }

  public reorderVat(beforeVat, afterVat): Observable<EvaCompany> {
    return this.http
      .put<EvaCompany>(this.url + "/vat/reorder", {
        beforeVat: beforeVat,
        afterVat: afterVat,
      })
      .pipe(map(EvaCompany.createInstance));
  }

  public hasProdRights(): boolean {
    return [EmployeeType.ADMIN, EmployeeType.OFFICE, EmployeeType.PROD].includes(
      this.getCurrentEvaEmployee().employee_type
    );
  }

  public hasOfficeRights(): boolean {
    return [EmployeeType.ADMIN, EmployeeType.OFFICE].includes(this.getCurrentEvaEmployee().employee_type);
  }

  public hasAdminRights(): boolean {
    return this.getCurrentEvaEmployee().employee_type == EmployeeType.ADMIN;
  }

  public hasAccountantRights(): boolean {
    return this.getCurrentEvaEmployee().employee_type == EmployeeType.ACCOUNTANT || this.hasAdminRights();
  }

  public hasSuperAdminRights(): boolean {
    return this.getCurrentEvaEmployee().is_superadmin;
  }

  getFromSessionThenLocalStorage(key: string): any {
    let value = sessionStorage.getItem(key);
    if (value == null) {
      value = localStorage.getItem(key);
    }
    return value;
  }

  setSessionAndLocalStorage(key: string, value: any): void {
    sessionStorage.setItem(key, value);
    localStorage.setItem(key, value);
  }

  patchIsShowsOfferV2(isShowsOffersV2: boolean): Observable<EvaCompany> {
    return this.http
      .patch<EvaCompany>(this.url + "/isShowsOffersV2", { isShowsOffersV2: isShowsOffersV2 })
      .pipe(map(EvaCompany.createInstance));
  }

  patchIsShowMealBreaks(is_meal_break_allowed: boolean): Observable<EvaCompany> {
    return this.http
      .patch<EvaCompany>(this.url + "/is_meal_break_allowed", { is_meal_break_allowed: is_meal_break_allowed })
      .pipe(map(EvaCompany.createInstance));
  }

  public refreshCurrentEvaCompany(): void {
    this.get(this.currentEvaCompany.eva_company_id).subscribe((evaCompany) => {
      this.updateEvaCompany(evaCompany);
    });
  }

  public updateEmployeesOrder(employeesOrder: string[]): Observable<EvaCompany> {
    return this.http.put<EvaCompany>(this.url + "/employee-order", employeesOrder);
  }
}
