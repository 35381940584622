import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { registerLicense } from "@syncfusion/ej2-base";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: "https://fe1485291d7d43ac90096ba4d2b9ad32@o460241.ingest.sentry.io/5460147",
    environment: environment.env,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          "https://app-next.eva-it.ch",
          "https://app.eva-it.ch",
          "https://app-next-v2.eva-it.ch",
          "https://app-v2.eva-it.ch",
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    debug: false,
    release: "eva-front@" + environment.appVersion,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
  });
}

if (!environment.enableDebug) {
  console.debug = () => {};
}

// Registering Syncfusion license key
registerLicense(environment.sfLicense);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if ("serviceWorker" in navigator && environment.production) {
      navigator.serviceWorker.register("ngsw-worker.js");
    }
  })
  .catch((err) => console.error(err));
