export class VAT {
  vat_id: string = "";
  name: string = "";
  desc: string = "";
  rate: number = 0;

  public static createInstance(jsonData: any): VAT {
    const v = Object.assign(new VAT(), jsonData);

    return v;
  }

  public static createInstances(jsonData: any[]): VAT[] {
    return jsonData.map(VAT.createInstance);
  }

  clone(): VAT {
    let v = Object.assign(new VAT(), this);
    return v;
  }
}
