import { format } from "path";

import { formatDate } from "@angular/common";

export enum SalaryStatus {
  TO_PAY = "TO_PAY",
  PAID = "PAID",
  DROPPED = "DROPPED",
}

export enum SalaryStatusLabel {
  TO_PAY = "A payer",
  PAID = "Payé",
  DROPPED = "Abandonné",
}

export class Salary {
  salary_id: string = "";
  employee_id: string = "";
  year: number = new Date().getFullYear();
  month: number = new Date().getMonth() + 1;
  projected_gross_salary: number = 0;
  projected_payment_date: Date;
  real_net_salary: number = 0;
  real_cost_salary: number = 0;
  real_payment_date: Date;
  start_date: Date;
  end_date: Date;
  comment: string = "";
  attachments_id: string[] = [];
  status: SalaryStatus = SalaryStatus.TO_PAY;

  // External data
  employee_name: string = "";

  public get salaryMonthLabel() {
    let dt = new Date(this.year, this.month - 1, 1);
    return formatDate(dt, "MMMM yyyy", "fr-FR");
  }

  public get monthYearStr() {
    return (this.month < 10 ? "0" + this.month.toString() : this.month.toString()) + "/" + this.year;
  }

  public get yearMonth() {
    return this.year.toString() + (this.month < 10 ? "0" + this.month.toString() : this.month.toString());
  }

  public static createInstance(jsonData: any): Salary {
    const s = Object.assign(new Salary(), jsonData);
    if (jsonData.projected_payment_date) {
      s.projected_payment_date = new Date(jsonData.projected_payment_date);
    }
    if (jsonData.real_payment_date) {
      s.real_payment_date = new Date(jsonData.real_payment_date);
    }
    if (jsonData.start_date) {
      s.start_date = new Date(jsonData.start_date);
    }
    if (jsonData.end_date) {
      s.end_date = new Date(jsonData.end_date);
    }

    return s;
  }

  public static createInstances(jsonData: any[]): Salary[] {
    return jsonData.map(Salary.createInstance);
  }

  excelExportSalary() {
    let baseJSON = {
      id: this.salary_id,
      employee_id: this.employee_id,
      year: this.year,
      month: this.month,
      projected_gross_salary: this.projected_gross_salary,
      projected_payment_date: this.projected_payment_date,
      real_net_salary: this.real_net_salary,
      real_cost_salary: this.real_cost_salary,
      real_payment_date: this.real_payment_date,
      start_date: this.start_date,
      end_date: this.end_date,
      comment: this.comment,
      status: this.status,
    };
    return baseJSON;
  }

  clone(): Salary {
    let s = Object.assign(new Salary(), this);
    return s;
  }

  public get month_year() {
    return new Date(this.year, this.month - 1, 1);
  }
}
