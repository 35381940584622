import { saveAs } from "file-saver";
import * as moment from "moment";
import { NgxImageCompressService } from "ngx-image-compress";
import { NgxSpinnerService } from "ngx-spinner";
import { MessageService } from "primeng/api";

import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class EvaService {
  today = moment();

  showGlobalLoader = false;

  constructor(
    private messageService: MessageService,
    private imageCompress: NgxImageCompressService,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer
  ) {}

  showLoader(blockScreen: boolean = false): void {
    this.showGlobalLoader = true;
    if (blockScreen) {
      this.spinner.show();
    }
  }

  hideLoader(): void {
    this.showGlobalLoader = false;
    this.spinner.hide();
  }

  showSuccess(message: string): void {
    this.messageService.add({ severity: "success", summary: message });
  }

  showWarning(message: string): void {
    this.messageService.add({ severity: "warn", summary: message });
  }

  showError(message: string, functionKo: string, error: string, disableTimeOut = true): void {
    this.messageService.add({
      key: "pdo",
      severity: "error",
      summary: message,
      sticky: disableTimeOut,
    });

    console.error("error " + functionKo);
    console.error(error);
  }

  clearErrors(): void {
    this.messageService.clear("pdo");
  }

  getCurrentWeek(): number {
    return this.today.isoWeek();
  }

  getCurrentYear(): number {
    return this.today.year();
  }

  getWeekFromDate(inputDate: Date): number {
    return moment(inputDate).isoWeek();
  }

  getYearFromDate(inputDate: Date): number {
    return moment(inputDate).year();
  }

  addWeeksToDate(inputDate: Date, weeksToAdd: number): Date {
    return moment(inputDate).add(weeksToAdd, "weeks").toDate();
  }
  substractWeeksToDate(inputDate: Date, weeksToAdd: number): Date {
    return moment(inputDate).subtract(weeksToAdd, "weeks").toDate();
  }

  addDaysToDate(inputDate: Date, daysToAdd: number): Date {
    return moment(inputDate).add(daysToAdd, "days").toDate();
  }

  addMonthToDate(inputDate: Date, monthsToAdd: number): Date {
    return moment(inputDate).add(monthsToAdd, "months").toDate();
  }

  getWeekStartDate(year: number, week: number): Date {
    return moment().year(year).isoWeek(week).startOf("isoWeek").toDate();
  }

  getWeekEndDate(year: number, week: number): Date {
    return this.addDaysToDate(this.getWeekStartDate(year, week), 6);
  }

  getWeekDayFromDate(inputDate: Date): number {
    return moment(inputDate).weekday();
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
  }

  compressImage(image: string, maxSizeMb = 0.5, imgQuality = 75, maxWidth = 500, maxHeight = 500): Promise<string> {
    const imgSizeMb = ((image.length - "data:image/png;base64,".length) * (3 / 4)) / 1048576;

    return this.imageCompress.compressFile(image, -1, (maxSizeMb / imgSizeMb) * 100, imgQuality, maxWidth, maxHeight);
  }

  getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
