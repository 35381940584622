export class ExpenseVat {
  amount: number;
  rate: number;

  constructor() {
    this.amount = 0;
    this.rate = 0;
  }

  public static createInstance(jsonData: ExpenseVat): ExpenseVat {
    const e = Object.assign(new ExpenseVat(), jsonData);

    return e;
  }

  public static createInstances(jsonData: any[]): ExpenseVat[] {
    return jsonData.map(ExpenseVat.createInstance);
  }
}
