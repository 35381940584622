export enum AssetCategory {
  OFFER = "OFFER",
  CUSTOMER = "CUSTOMER",
  PURCHASE_ORDER = "PURCHASE_ORDER",
  PROJECT = "PROJECT",
  INVOICE = "INVOICE",
  SUPPLIER = "SUPPLIER",
  EVA_COMPANY = "EVA_COMPANY",
  EMPLOYEE = "EMPLOYEE",
  CONTACT = "CONTACT",
  EXPENSE = "EXPENSE",
  SUPPLIER_CALL = "SUPPLIER_CALL",
  CREDIT_NOTE = "CREDIT_NOTE",
  INTERVENTION = "INTERVENTION",
  INSTALLATION = "INSTALLATION",
  CONTRACT = "CONTRACT",
  FILE_EXPORT = "FILE_EXPORT",
}

export enum AssetAccessLevel {
  ALL = "ALL",
  OFFICE = "OFFICE",
  ADMIN = "ADMIN",
}

export enum AssetAccessLevelLabel {
  ALL = "Tout le monde",
  OFFICE = "Bureaux",
  ADMIN = "Administrateurs",
}

export class Asset {
  asset_id: string = "";
  eva_company_id: string = "";
  filename: string = "";
  category: AssetCategory;
  category_object_id: string = "";
  tags: string[] = [];
  access_level: AssetCategory;

  get fileExtension(): string {
    return this.filename.split(".").pop().toLowerCase();
  }

  get isImage(): boolean {
    return ["jpg", "jpeg", "png", "gif", "bmp", "jpe", "svg", "tiff"].includes(this.fileExtension);
  }

  get isDocument(): boolean {
    return [
      "txt",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "pdf",
      "pages",
      "ai",
      "psd",
      "dxf",
      "eps",
      "ps",
      "ttf",
      "xps",
      "zip",
      "rar",
    ].includes(this.fileExtension);
  }

  public static createInstance(jsonData: any): Asset {
    const a = Object.assign(new Asset(), jsonData);
    return a;
  }

  public static createInstances(jsonData: any[]): Asset[] {
    return jsonData.map(Asset.createInstance);
  }

  clone(): Asset {
    let a = Object.assign(new Asset(), this);
    return a;
  }
}
