import { debounceTime, distinctUntilChanged, filter, fromEvent, tap } from "rxjs";
import { EvaService } from "src/app/core";
import { SearchItem } from "src/app/domains/searchItem";
import { SearchService } from "src/app/services/search.service";

import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "eva-search-bar",
  templateUrl: "./eva-search-bar.component.html",
  styleUrls: ["./eva-search-bar.component.scss"],
})
export class EvaSearchBarComponent implements OnInit, OnChanges {
  @Input() forceFocus = false;
  public query = "";
  public loading = false;
  public searchItems: SearchItem[];
  public showResultOverlay = false;

  constructor(private readonly searchService: SearchService, private readonly evaService: EvaService) {}

  @ViewChild("inputsearch", { static: true }) inputsearch: ElementRef;

  ngOnInit() {
    fromEvent(this.inputsearch.nativeElement, "keyup")
      .pipe(
        filter(Boolean),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          this.onNgModelChange();
        })
      )
      .subscribe();
  }

  ngOnChanges() {
    if (this.forceFocus) {
      this.inputsearch.nativeElement.focus();
    }
  }

  public onNgModelChange() {
    this.getSearch();
    this.showOverlay();
    this.hideOverlayByQueryLength();
  }

  private getSearch() {
    if (this.query.length < 3) {
      return;
    }
    this.loading = true;
    this.searchService.getSearch(this.query.trim()).subscribe({
      next: (searchItems) => {
        this.searchItems = searchItems;

        this.loading = false;
        this.showOverlay();
      },
      error: (err) => {
        this.evaService.showError("Désolé une erreur est survenue", "getSearch", err);
      },
    });
  }

  private showOverlay() {
    if (this.searchItems) {
      this.showResultOverlay = true;
    }
  }

  hideOverlayByClick() {
    this.query = "";
    this.searchItems = null;
    this.showResultOverlay = false;
  }

  onHideOverlay() {
    this.showResultOverlay = false;
  }

  private hideOverlayByQueryLength() {
    if (this.query.length < 3) {
      this.showResultOverlay = false;
    }
  }

  @HostListener("document: click", ["$Event"])
  onClick() {
    this.showResultOverlay = false;
  }

  @HostListener("window: keydown.Escape", ["$Event"])
  handleKeyDown(event: KeyboardEvent) {
    this.showResultOverlay = false;
  }
}
