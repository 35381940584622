import { EmployeeType } from "./employee";

export class HoursPerDay {
  day: number = 0;
  hours: number = 0;
  half_day: boolean = false;

  constructor(day: number = null) {
    if (day) this.day = day;
    this.hours = 0;
    this.half_day = false;
  }

  public static createInstance(jsonData: any): HoursPerDay {
    return Object.assign(new HoursPerDay(), jsonData);
  }

  public static createInstances(jsonData: any[]): HoursPerDay[] {
    return jsonData.map(HoursPerDay.createInstance);
  }
}

export class EmployeePositionHistory {
  employee_position_history_id: string = "";
  position_start_date: Date = new Date();
  position_end_date: Date = new Date(2099, 11, 31);
  employee_type: EmployeeType = EmployeeType.PROD;
  admin_allow_hr = true;
  admin_allow_expense_reports = true;
  admin_allow_stats = true;
  office_allow_project_sensitive_data = false;
  manager_id: string = null;
  hour_cost: number = 0;
  month_cost: number = 0;
  weekly_days: number = 5;
  weekly_hours: number = 41.25;
  monthly_hours: number = 178.75;
  yearly_vacation_days: number = 20;
  daily_hours: HoursPerDay[] = [
    new HoursPerDay(0),
    new HoursPerDay(1),
    new HoursPerDay(2),
    new HoursPerDay(3),
    new HoursPerDay(4),
    new HoursPerDay(5),
    new HoursPerDay(6),
  ];
  gross_salary: number = 0;
  employer_contributions_rate: number = 0;
  variable_monthly_net_salary: number = 0;
  attachments_id: string[] = [];
  comment: string = "";

  public static createInstance(jsonData: any): EmployeePositionHistory {
    const e = Object.assign(new EmployeePositionHistory(), jsonData);
    e.position_start_date = new Date(jsonData.position_start_date);
    e.position_end_date = new Date(jsonData.position_end_date);
    e.employee_type = jsonData.employee_type;
    if (jsonData.daily_hours)
      e.daily_hours = HoursPerDay.createInstances(jsonData.daily_hours).sort((a, b) => a.day - b.day);

    return e;
  }

  public static createInstances(jsonData: any[]): EmployeePositionHistory[] {
    return jsonData.map(EmployeePositionHistory.createInstance);
  }

  getDailyHours(day: number): HoursPerDay {
    return this.daily_hours.find((h) => h.day === day);
  }

  getTotalDailyHours(): number {
    return this.daily_hours.reduce((acc, h) => acc + h.hours, 0);
  }

  isCurrentPosition() {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let end = new Date(
      this.position_end_date.getFullYear(),
      this.position_end_date.getMonth(),
      this.position_end_date.getDate()
    );
    let start = new Date(
      this.position_start_date.getFullYear(),
      this.position_start_date.getMonth(),
      this.position_start_date.getDate()
    );

    if (end >= today && start <= today) {
      return true;
    }
    return false;
  }

  excelExportEmployeePosition(financialData = false, managerName = null) {
    let baseJSON = {
      position_start_date: this.position_start_date,
      position_end_date: this.position_end_date,
      employee_type: this.employee_type,
      comment: this.comment,
    };
    if (managerName) {
      baseJSON["manager_name"] = managerName;
    } else {
      baseJSON["manager_id"] = this.manager_id;
    }
    if (financialData) {
      baseJSON["hour_cost"] = this.hour_cost;
      baseJSON["month_cost"] = this.month_cost;
      baseJSON["weekly_days"] = this.weekly_days;
      baseJSON["weekly_hours"] = this.weekly_hours;
      baseJSON["monthly_hours"] = this.monthly_hours;
      baseJSON["yearly_vacation_days"] = this.yearly_vacation_days;
      baseJSON["gross_salary"] = this.gross_salary;
      baseJSON["employer_contributions_rate"] = this.employer_contributions_rate;
      baseJSON["variable_monthly_net_salary"] = this.variable_monthly_net_salary;
    }
    return baseJSON;
  }

  clone(): EmployeePositionHistory {
    let p = Object.assign(new EmployeePositionHistory(), this);
    return p;
  }
}
