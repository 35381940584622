import { MessageService } from "primeng/api";
import { interval } from "rxjs";
import { environment } from "src/environments/environment";

import { Component, OnInit } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  today: Date = new Date();
  appVersion = environment.appVersion;
  showAppVersion = false;
  newVersionAvailable = false;

  constructor(private updates: SwUpdate, private messageService: MessageService) {
    if (updates.isEnabled) {
      // force check now
      updates.checkForUpdate();
      // and check updates every 10 minutes
      interval(10 * 60 * 1000).subscribe(() => updates.checkForUpdate());
    }

    // register for updates and prompt user with a popup to update or no
    this.updates.versionUpdates.subscribe((event) => {
      if (event.type === "VERSION_READY") {
        this.newVersionAvailable = true;
      }
    });
  }

  hideShowVersion() {
    this.updates.checkForUpdate();
    this.showAppVersion = !this.showAppVersion;
  }

  // APP UPDATE
  promptUserToUpdate(): void {
    this.messageService.clear("c");
    this.messageService.add({ key: "c", sticky: true, severity: "info" });
  }

  onConfirmUpdate() {
    this.messageService.clear("c");
    this.updates.activateUpdate().then(() => document.location.reload());
  }

  onRejectUpdate() {
    this.messageService.clear("c");
  }
}
