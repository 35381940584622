export enum TagObjectType {
  CUSTOMER = "CUSTOMER",
  SUPPLIER = "SUPPLIER",
  INVOICE = "INVOICE",
  OFFER = "OFFER",
  PROJECT = "PROJECT",
  EMPLOYEE = "EMPLOYEE",
  CONTACT = "CONTACT",
  PURCHASE_ORDER = "PURCHASE_ORDER",
  ARTICLE = "ARTICLE",
  CONTRACT_INVOICE = "CONTRACT_INVOICE",
  INTERVENTION = "INTERVENTION",
}

export class Tag {
  public tag_id = "";
  public eva_company_id = "";
  public name = "";
  public color = "";

  constructor() {
    this.color = "#6894d4";
  }

  public static createInstance(jsonData: any): Tag {
    return Object.assign(new Tag(), jsonData);
  }

  public static createInstances(jsonData: any[]): Tag[] {
    return jsonData.map(Tag.createInstance);
  }

  public getFormErrors(tag: Tag): string[] {
    let errors = [];

    if (tag.name.trim() === "") {
      errors.push("Le nom du tag ne doit pas être vide.");
    }

    return errors;
  }
}
