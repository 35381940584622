import { Observable, Subject, throwError } from "rxjs";
import { catchError, filter, finalize, switchMap, take, tap } from "rxjs/operators";
import { AppStateService } from "src/app/services/app-state.service";

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { EvaService } from "../core";
import { EvaCompanyService } from "../features/eva-company/services/evaCompany.service";
import { AuthenticationService } from "../services/authentication.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new Subject<any>();

  constructor(
    private authService: AuthenticationService,
    private evaService: EvaService,
    private appStateService: AppStateService,
    private evaCompanyService: EvaCompanyService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const evaJwt = this.getFromSessionThenLocalStorage("evaJwt");
    const evaRefreshJwt = this.getFromSessionThenLocalStorage("evaRefreshJwt");
    const eva_company = JSON.parse(this.getFromSessionThenLocalStorage("currentEvaCompany"));

    if (evaJwt) {
      let cloned: HttpRequest<any> = null;
      if (!req.url.includes("auth/refresh")) {
        cloned = this.addTokenHeader(req, evaJwt);
      } else {
        cloned = this.addTokenHeader(req, evaRefreshJwt);
      }

      if (eva_company) {
        cloned = cloned.clone({
          headers: cloned.headers.set("eva-company-id", eva_company.eva_company_id),
        });
      }

      return next.handle(cloned).pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            // Check if the company id in the response is different from the one in the session
            // If so, change the company and reload the page
            const apiCompanyId = event?.body?.eva_company_id;
            if (apiCompanyId && apiCompanyId !== eva_company.eva_company_id) {
              if (
                // If object is employee and super admin, do not change company
                !event?.body?.is_superadmin &&
                // If front URL has superadmin in it, do not change company
                !req.url.includes("superadmin") &&
                // If api URL has employee/me in it, do not change company
                !event.url.includes("employee/me") &&
                // If api URL has duplicate in it, do not change company
                !event.url.includes("duplicate")
              ) {
                this.evaCompanyService.changeCompany(apiCompanyId, false, true);
              }
            }
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 401:
                if (req.url.includes("auth/refresh")) {
                  this.evaService.showWarning("Votre session a expiré. Veuillez vous reconnecter.");
                  this.authService.logout();
                } else {
                  return this.handle401Error(cloned, next);
                }
                break;
              case 421: // Password expired
                return this.handlePasswordExpired();
              case 400:
                if (req.url.includes("employees/me")) {
                  this.evaService.showWarning("Votre session a expiré. Veuillez vous reconnecter.");
                  this.authService.logout();
                }
                return throwError(error);
              default:
                return throwError(error);
            }
          }
        })
      );
    } else {
      return next.handle(req);
    }
  }

  private handlePasswordExpired(): Observable<HttpEvent<any>> {
    this.appStateService.setPasswordExpired(true);
    this.authService.logout("passwordExpired");
    return throwError(new HttpErrorResponse({ status: 421, statusText: "Password expired" }));
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((newToken) => {
          this.refreshTokenSubject.next(newToken);
          return next.handle(this.addTokenHeader(req, newToken));
        }),
        catchError((e) => {
          this.refreshTokenInProgress = false;
          return throwError("Error refreshing token");
        }),
        finalize(() => (this.refreshTokenInProgress = false))
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((result) => result !== null),
        take(1),
        switchMap((newToken) => next.handle(this.addTokenHeader(req, newToken)))
      );
    }
  }

  private addTokenHeader(req: HttpRequest<any>, evaJwt: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${evaJwt}`,
      },
    });
  }

  private getFromSessionThenLocalStorage(key: string): any {
    return sessionStorage.getItem(key) || localStorage.getItem(key);
  }
}
