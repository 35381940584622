import { Article } from "../features/catalog/domains/article";
import { Contact } from "../features/contact/domains/contact";
import { Contract } from "../features/customer/domains/contract";
import { Customer } from "../features/customer/domains/customer";
import { Intervention } from "../features/interventions/domains/intervention";
import { PurchaseOrder } from "../features/purchase-order/domains/purchase-order";
import { Workshop } from "../features/workshop/domains/workshop";
import { Employee, Supplier } from "./internal";
import { Invoice } from "./invoice";
import { Offer } from "./offer";
import { Project } from "./project";

export enum SearchObjectType {
  OFFER = "OFFER",
  CUSTOMER = "CUSTOMER",
  PURCHASE_ORDER = "PURCHASE_ORDER",
  PROJECT = "PROJECT",
  INVOICE_RECEIVED = "INVOICE_RECEIVED",
  INVOICE_ISSUED = "INVOICE_ISSUED",
  SUPPLIER = "SUPPLIER",
  EMPLOYEE = "EMPLOYEE",
  WORKSHOP = "WORKSHOP",
  CONTACT = "CONTACT",
  INTERVENTION = "INTERVENTION",
  CONTRACT = "CONTRACT",
  INTERNAL_ARTICLE = "INTERNAL_ARTICLE",
}

export class SearchItem {
  object_category: SearchObjectType;
  object_id: string;
  object_label: string;
  object_link: string;

  public static createInstance(jsonData: SearchItem): SearchItem {
    const s = Object.assign(new SearchItem(), jsonData);

    return s;
  }

  public static createInstances(jsonData: SearchItem[]): SearchItem[] {
    return jsonData.map(SearchItem.createInstance);
  }
}
