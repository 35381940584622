import { round } from "mathjs";
import { Offer, OfferItemGroup, Project, Supplier } from "src/app/domains/internal";

import { Tag } from "../features/eva-company/domains/eva-company-tag";
import { Intervention } from "../features/interventions/domains/intervention";
import { InvoicePdfConfig } from "../features/offer-v2/domains/InvoicePdfConfig";
import { PurchaseOrder } from "../features/purchase-order/domains/purchase-order";
import { addMonthToDate, getTodayUtc } from "../shared/helpers/date.helpers";
import { IBAN_CODE_LENGTHS, roundAmount, roundToNearestCents } from "../shared/helpers/number.helper";
import { CreditNote } from "./credit-note";

export enum InvoiceBillingType {
  DEPOSIT = "DEPOSIT",
  TOTAL = "TOTAL",
  WARRANTY = "WARRANTY",
}
export enum InvoiceEntry {
  FREE_BILLING = "FREE_BILLING",
  ACCORDING_TO_OFFER = "ACCORDING_TO_OFFER",
}

export enum InvoiceType {
  RECEIVED = "RECEIVED",
  ISSUED = "ISSUED",
}

export enum InvoiceStatus {
  PAID = "PAID",
  ACCOUNTED = "ACCOUNTED",
  CANCELLED = "CANCELLED",
  ON_GOING = "ON_GOING",
  DRAFT = "DRAFT",
  VALIDATED = "VALIDATED",
  SENT = "SENT",
}

export enum InvoiceStatusLabel {
  DRAFT = "Brouillon",
  PAID = "Payée",
  ACCOUNTED = "Mise en comptabilité",
  CANCELLED = "Abandonnée",
  ON_GOING = "En attente de paiement",
  VALIDATED = "Validée",
  SENT = "Envoyée",
}
export class FreeBillingItem {
  item_id: string;
  name: string;
  quantity: number;
  unity: string;
  unit_price: number;
  price: number;

  constructor() {
    this.item_id = generateObjectId();
    this.quantity = 1;
    this.unit_price = 0;
    this.price = 0;
    this.unity = "ENS";
  }

  public static createInstance(jsonData: any): FreeBillingItem {
    const f = Object.assign(new FreeBillingItem(), jsonData);

    return f;
  }

  public static createInstances(jsonData: any[]): FreeBillingItem[] {
    return jsonData.map((f) => FreeBillingItem.createInstance(f));
  }
}
export class FreeBillingItemGroup {
  item_group_id: string;
  name: string;
  items: FreeBillingItem[] = [];

  constructor() {
    this.item_group_id = generateObjectId();
    this.items = [new FreeBillingItem()];
  }

  public static createInstance(jsonData: any): FreeBillingItemGroup {
    const f = Object.assign(new FreeBillingItemGroup(), jsonData);

    f.items = jsonData.items ? FreeBillingItem.createInstances(jsonData.items) : [];

    return f;
  }

  public static createInstances(jsonData: any[]): FreeBillingItemGroup[] {
    return jsonData.map((f) => FreeBillingItemGroup.createInstance(f));
  }
}

export class Invoice {
  invoice_id: string;
  invoice_corporate_id: string;
  eva_company_id: string;
  pdf_config: InvoicePdfConfig;
  free_billing_items_groups: FreeBillingItemGroup[] = [];
  display_contact_name: boolean;
  invoice_type: string;
  invoice_asset_id: string;
  invoice_asset_name: string;
  invoice_address_id: string;
  invoice_ref: string;

  purchase_discount_percent: number = 0;
  public setDiscountRate(discountRate: number) {
    this.purchase_discount_percent = discountRate;
    this.purchase_discount_amount = roundAmount(this.gross_total_price * (this.purchase_discount_percent / 100));
    this.vat_amount = this.computeVat();
    this.total_pt = this.computeNetVatTotalPrice();
  }
  purchase_discount_amount: number = 0;

  credit_note_gross_price: number = 0.0;
  public setCreditNotePrice(grossPrice: number) {
    this.credit_note_gross_price = grossPrice;
    this.vat_amount = this.computeVat();
    this.total_pt = this.computeNetVatTotalPrice();
  }

  gross_total_price: number = 0;
  public setGrossTotalPrice(grossTotalPrice: number) {
    this.gross_total_price = grossTotalPrice;
    this.setDiscountRate(this.purchase_discount_percent);
    this.vat_amount = this.computeVat();
    this.total_pt = this.computeNetVatTotalPrice();
  }

  vat_rate: number = 0.0;
  public setVatRate(vatRate: number) {
    this.vat_rate = vatRate;
    this.vat_amount = this.computeVat();
    this.total_pt = this.computeNetVatTotalPrice();
  }

  round_total_pt = 0.05;
  public setRoundTotalPt(round_total_pt: number) {
    this.round_total_pt = round_total_pt;
    this.recalculatePrices();
  }

  currency = "CHF";
  public setCurrency(currency: string) {
    if (["CHF", "EUR"].includes(currency)) {
      this.currency = currency;
    } else {
      this.currency = "CHF";
    }
  }

  vat_amount: number = null;
  public setVatAmount(vatAmount: number) {
    this.vat_amount = vatAmount;
  }

  total_pt: number = null;
  public setTotalPt(totalPt: number) {
    this.total_pt = totalPt;
  }
  invoice_about: string;

  invoice_date: Date;
  start_date_contract_work: Date;
  end_date_contract_work: Date;
  payment_deadline: Date;

  tags_ids: string[];
  tags: Tag[] = [];

  issued_show_details_offer: boolean = false;
  issued_show_summary_offer: boolean = true;
  issued_show_recap: boolean = true;
  invoice_entry: string;

  is_qr_invoice: boolean = false;
  qr_code: string;

  status: InvoiceStatus;

  payment_proof_asset_id: string;
  payment_proof_asset_name: string;

  payment_iban: string;
  payment_ref: string;
  payment_add_info: string;

  // logs
  created_by: string;
  created_on: Date;
  validated_by: string;
  validated_on: Date;
  sent_by: string;
  sent_on: Date;
  send_type: string;
  paid_by: string;
  paid_on: Date;
  cancelled_by: string;
  cancelled_on: Date;
  accounted_by: string;
  accounted_on: Date;
  scheduled_by: string;
  scheduled_on: Date;

  comment: string;

  // received invoice
  purchase_order_id: string;

  // emitted invoice
  offer_id: string;
  intervention_ids: string[] = [];
  intervention_quotation_more: OfferItemGroup;
  intervention_quotations_to_include: string[] = [];
  contract_id: string;
  contract_quotation: OfferItemGroup;
  paid_gross_total_price: number;
  paid_vat_amount: number;
  paid_total_pt: number;
  contacts_ids: string[] = [];

  // external data to load
  purchase_order: PurchaseOrder;
  interventions: Intervention[];
  customer_id: string;
  external_contract_id: string;
  customer_name: string;
  sent_by_employee_name: string;

  project: any;
  supplier: Supplier;
  offer: any;

  project_id = "";
  supplier_id = "";
  project_corporate_id = "";
  project_name = "";
  offer_corporate_id = "";
  contract_corporate_id = "";
  interventions_nb = 0;
  execution_date: Date;

  get late(): boolean {
    let today = new Date();
    if ([InvoiceStatus.ON_GOING, InvoiceStatus.SENT, InvoiceStatus.VALIDATED].includes(this.status)) {
      if (today > this.payment_deadline) {
        return true;
      }
    }
    return false;
  }

  get purchase_order_corporate_id(): string {
    return this.purchase_order ? this.purchase_order.purchase_order_corporate_id : "";
  }

  get supplier_name(): string {
    return this.supplier ? this.supplier.name : "";
  }

  get purchase_order_fulfilment(): number {
    return this.purchase_order ? this.purchase_order.percentBilled : 0;
  }

  get purchase_order_delivered(): number {
    return this.purchase_order ? this.purchase_order.percentDelivered : 0;
  }

  get excelExport() {
    return {
      "Bon de commande": this.purchase_order?.purchase_order_corporate_id,
      Facture: this.invoice_corporate_id,
      "Chantier numéro": this.project?.project_corporate_id,
      "Chantier nom": this.project?.name,
      Founisseur: this.supplier?.name,
      "Rabais fournisseur": this.getSupplierDiscount(),
      "Brut HT": this.gross_total_price,
      "NET HT": this.getNetTotalPrice(),
      "NET TTC": this.getNetVatTotalPrice(),
      "Date facture": this.invoice_date,
      "Date d'échéance": this.payment_deadline,
      "Retard de paiement": this.paymentDelay + " jours",
      "Date de paiement": this.paid_on,
      "% Remplissage bon": this.purchase_order_fulfilment,
      Statut: this.status,
    };
  }

  get excelIssuedExport() {
    return {
      Facture: this.invoice_corporate_id,
      Projet: this.project_id ? `${this.project_corporate_id} - ${this.project_name}` : "",
      Client: this.customer_name,
      Offre: this.offer_corporate_id,
      "Dû HT": this.gross_total_price,
      "Dû TTC": this.getNetVatTotalPrice(),
      "Payé HT": this.paid_gross_total_price,
      "Payé TTC": this.paid_gross_total_price * (this.vat_rate / 100 + 1),
      "Date de la facture": this.invoice_date,
      "Date de validation": this.validated_on,
      "Envoyée au client": this.sent ? "OUI" : "NON",
      "Date de d'envoi": this.sent_on,
      "Echéance de paiement": this.payment_deadline,
      "Retard de paiement": this.paymentDelay > 0 ? this.paymentDelay : 0,
      "Payée par le client": this.paid ? "OUI" : "NON",
      "Date de paiement": this.paid_on,
      Status: this.status,
    };
  }

  get validated() {
    return this.validated_on !== null;
  }
  set validated(val) {
    //ignored, to make binding checkbox successful
  }

  get sent() {
    return this.sent_on !== null;
  }
  set sent(val) {
    //ignored, to make binding checkbox successful
  }
  get paid() {
    return this.paid_on !== null;
  }
  set paid(val) {
    //ignored, to make binding checkbox successful
  }

  get paymentDelay(): number {
    let dateCompare = this.paid_on ? new Date(this.paid_on) : new Date();
    if (this.payment_deadline) {
      return Math.round((dateCompare.getTime() - this.payment_deadline.getTime()) / (1000 * 60 * 60 * 24));
    } else {
      return 0;
    }
  }

  get netTotalPrice(): number {
    return this.getNetTotalPrice();
  }

  get netVatTotalPrice(): number {
    return this.getNetVatTotalPrice();
  }

  get netVatTotalPriceStr(): string {
    return this.netVatTotalPrice.toFixed(2);
  }

  get vat(): number {
    return this.getVat();
  }

  get supplierDiscount(): number {
    return this.purchase_discount_percent || 0;
  }

  get accounted() {
    return !!this.accounted_on;
  }
  set accounted(val) {
    if (val === false) {
      this.accounted_on = null;
      this.accounted_by = null;
    } else {
      if (this.accounted_on == null) this.accounted_on = new Date();
    }
  }

  get scheduled() {
    return !!this.scheduled_on;
  }
  set scheduled(val) {
    if (val === false) {
      this.scheduled_on = null;
      this.scheduled_by = null;
    } else {
      if (this.scheduled_on == null) this.scheduled_on = new Date();
    }
  }

  constructor() {
    this.status = InvoiceStatus.DRAFT;
    this.invoice_date = getTodayUtc();
    this.start_date_contract_work = getTodayUtc();
    this.end_date_contract_work = getTodayUtc();
    this.payment_deadline = addMonthToDate(this.invoice_date, 1);
    this.pdf_config = new InvoicePdfConfig();
    this.invoice_entry = "ACCORDING_TO_OFFER";
    this.display_contact_name = true;
  }

  public static createInstance(jsonData: Invoice): Invoice {
    const i = Object.assign(new Invoice(), jsonData);

    i.payment_deadline = jsonData.payment_deadline ? new Date(jsonData.payment_deadline) : null;

    i.created_on = jsonData.created_on ? new Date(jsonData.created_on) : null;
    i.validated_on = jsonData.validated_on ? new Date(jsonData.validated_on) : null;
    i.sent_on = jsonData.sent_on ? new Date(jsonData.sent_on) : null;
    i.paid_on = jsonData.paid_on ? new Date(jsonData.paid_on) : null;
    i.cancelled_on = jsonData.cancelled_on ? new Date(jsonData.cancelled_on) : null;
    i.invoice_date = jsonData.invoice_date ? new Date(jsonData.invoice_date) : null;
    i.start_date_contract_work = jsonData.start_date_contract_work ? new Date(jsonData.start_date_contract_work) : null;
    i.end_date_contract_work = jsonData.end_date_contract_work ? new Date(jsonData.end_date_contract_work) : null;
    i.accounted_on = jsonData.accounted_on ? new Date(jsonData.accounted_on) : null;

    i.offer = jsonData.offer ? Offer.createInstance(jsonData.offer) : null;
    i.pdf_config = jsonData.pdf_config ? InvoicePdfConfig.createInstance(jsonData.pdf_config) : null;
    if (jsonData.project) {
      i.project = Project.createInstance(jsonData.project);
      i.project_id = i.project_id ? i.project_id : i.project.project_id;
      i.project_corporate_id = i.project_corporate_id ? i.project_corporate_id : i.project.project_corporate_id;
      i.project_name = i.project_name ? i.project_name : i.project.name;
    }
    i.supplier = jsonData.supplier ? Supplier.createInstance(jsonData.supplier) : null;
    i.invoice_entry = jsonData.invoice_entry;
    i.purchase_order = jsonData.purchase_order ? PurchaseOrder.createInstance(jsonData.purchase_order) : null;
    i.intervention_quotation_more = jsonData.intervention_quotation_more
      ? OfferItemGroup.createInstance(jsonData.intervention_quotation_more)
      : null;
    i.interventions = jsonData.interventions ? Intervention.createInstances(jsonData.interventions) : null;
    i.free_billing_items_groups =
      jsonData.free_billing_items_groups && jsonData.free_billing_items_groups.length > 0
        ? FreeBillingItemGroup.createInstances(jsonData.free_billing_items_groups)
        : [new FreeBillingItemGroup()];
    i.contract_quotation = jsonData.contract_quotation
      ? OfferItemGroup.createInstance(jsonData.contract_quotation)
      : null;
    i.tags = jsonData.tags ? Tag.createInstances(jsonData.tags) : null;

    return i;
  }

  public static createInstances(invoices: any[]): Invoice[] {
    return invoices.map(Invoice.createInstance);
  }

  public static createReceivedInvoiceFromPurchaseOrder(po: PurchaseOrder) {
    const i = new Invoice();
    i.invoice_type = "RECEIVED";
    i.gross_total_price = round(po.confirmation_gross_price - po.totalBilled, 2);
    i.credit_note_gross_price = 0;
    i.purchase_order_id = po.purchase_order_id;
    i.round_total_pt = 0.05;
    if (po.supplier) {
      i.supplier = po.supplier;
    }
    if (po.project) {
      i.project = po.project;
    }
    i.setVatRate(po.confirmation_vat);
    return i;
  }

  public static createEmittedInvoice() {
    const i = new Invoice();
    i.invoice_type = "EMITTED";
    return i;
  }

  public getSupplierDiscount(): number {
    return this.purchase_discount_percent ? this.purchase_discount_percent : 0.0;
  }

  public getTotalDiscount(): number {
    return round((this.gross_total_price * this.getSupplierDiscount()) / 100.0, 2) || 0;
  }

  public getNetTotalPrice(): number {
    return this.gross_total_price - this.purchase_discount_amount - this.credit_note_gross_price || 0;
  }

  public getVat(): number {
    return this.vat_amount ? this.vat_amount : this.computeVat();
  }
  public computeVat() {
    return round(this.getNetTotalPrice() * (this.vat_rate / 100), 2) || 0;
  }

  public getNetVatTotalPrice(): number {
    return this.total_pt ? this.total_pt : this.computeNetVatTotalPrice();
  }

  public computeNetVatTotalPrice(): number {
    let total = this.getNetTotalPrice() + this.getVat();
    if (this.round_total_pt >= 0.01) {
      return roundToNearestCents(total, this.round_total_pt);
    } else {
      return total;
    }
  }

  get leftToPay(): number {
    return this.paid_gross_total_price > this.gross_total_price
      ? 0
      : this.gross_total_price - this.paid_gross_total_price;
  }

  public recalculatePrices() {
    this.vat_amount = this.getVat();
    this.total_pt = this.getNetVatTotalPrice();
  }

  clone(removePO = false): Invoice {
    let i = Object.assign(new Invoice(), this);
    if (removePO) {
      i.purchase_order = null;
    }
    return i;
  }

  getFormErrors(purchaseOrder?: PurchaseOrder, creditNote?: CreditNote): string[] {
    const errors = [];

    if (!this.invoice_corporate_id || this.invoice_corporate_id.trim() == "")
      errors.push("Le numéro de la facture doit être renseigné");

    if (!this.gross_total_price || this.gross_total_price <= 0)
      errors.push("Le montant de la facture doit être supérieur à 0.");
    if (!this.payment_deadline) errors.push("La date d'échéance doit être renseignée.");
    if (!this.invoice_asset_id || this.invoice_asset_id.trim() == "")
      errors.push("Une pièce jointe pour la facture est obligatoire.");

    if (purchaseOrder) {
      if (this.invoice_date.getTime() + 24 * 60 * 60 * 1000 < purchaseOrder.order_date.getTime()) {
        errors.push(
          "Vous devez renseigner une date de facture supérieure ou égale à la date de commande (" +
            purchaseOrder.order_date.toLocaleDateString() +
            ")"
        );
      }
    }

    if (creditNote) {
      if (this.credit_note_gross_price) {
        if (this.credit_note_gross_price > creditNote.remaining_amount)
          errors.push(
            "Le montant de la note de crédit doit être inférieur ou égal au montant disponible de la note de crédit."
          );
      }
    }

    if (this.payment_iban || this.payment_ref || this.payment_add_info) {
      if (this.payment_iban) {
        this.payment_iban = this.payment_iban.replace(/\s/g, "").toUpperCase();
      }
      if (this.payment_ref) {
        this.payment_ref = this.payment_ref.toUpperCase().replace(/\s/g, "");
      }
      if (this.payment_add_info) {
        this.payment_add_info = this.payment_add_info.replace(/[°@#$%^&*{}\[\]|<>]/g, "");
      }
      if (!this.isIbanValid())
        errors.push(
          "L'IBAN doit contenir " + IBAN_CODE_LENGTHS[this.payment_iban.substr(0, 2)] + " caractères alphanumériques."
        );

      if (this.payment_ref) {
        if (this.payment_ref.startsWith("RF") && this.payment_ref.length > 25) {
          errors.push("Le numéro de référence doit contenir au plus 25 caractères pour les ref commençant par 'RF'.");
        } else if (!this.payment_ref.startsWith("RF") && this.payment_ref.length !== 27) {
          errors.push("Le numéro de référence doit contenir 27 caractères pour les ref ne commençant pas par 'RF'.");
        }
      } else if (!this.payment_add_info || this.payment_add_info.length === 0) {
        errors.push("Le numéro de référence ou l'information additionnelle doit être renseigné.");
      }
    }

    return errors;
  }

  isIbanValid(): boolean {
    return (
      this.payment_iban &&
      this.payment_iban.length === IBAN_CODE_LENGTHS[this.payment_iban.substr(0, 2)] &&
      /^[A-Z0-9]+$/i.test(this.payment_iban)
    );
  }

  isRefValid(): boolean {
    if (this.payment_ref) {
      if (this.payment_ref.startsWith("RF") && this.payment_ref.length > 25) {
        return false;
      } else if (!this.payment_ref.startsWith("RF") && this.payment_ref.length !== 27) {
        return false;
      }
    }
    return true;
  }

  areRefAndAdditionnalInfoValid(): boolean {
    if (this.payment_ref) {
      return this.isRefValid();
    } else {
      return this.payment_add_info && this.payment_add_info.length > 0;
    }
  }
}

function generateObjectId(): string {
  const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
  const randomHex = Array.from({ length: 16 }, () => Math.floor(Math.random() * 16).toString(16)).join("");

  return timestamp + randomHex;
}
