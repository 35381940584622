import { Offer } from "../domains/offer";
import { Contact } from "../features/contact/domains/contact";
import { Customer } from "../features/customer/domains/customer";
import { PurchaseOrder } from "../features/purchase-order/domains/purchase-order";
import { Supplier } from "../features/supplier/domains/supplier";
import { Project } from "./project";
import { Workshop } from "../features/workshop/domains/workshop";
import { ExpenseReport } from "../features/expense-report/domains/expense-report";
import { EvaAddress } from "./address";
import { CreditNote } from "./credit-note";

export enum AuditObjectType {
  GLOBAL = "GLOBAL",
  OFFER = "OFFER",
  CUSTOMER = "CUSTOMER",
  PURCHASE_ORDER = "PURCHASE_ORDER",
  PROJECT = "PROJECT",
  WORKSHOP = "WORKSHOP",
  INVOICE = "INVOICE",
  SUPPLIER = "SUPPLIER",
  EVA_COMPANY = "EVA_COMPANY",
  EMPLOYEE = "EMPLOYEE",
  SALARY = "SALARY",
  ASSET = "ASSET",
  YEARLY_CONFIG = "YEARLY_CONFIG",
  AUTH = "AUTH",
  CONTACT = "CONTACT",
  CONTRACT = "CONTRACT",
  EXPENSE_REPORT = "EXPENSE_REPORT",
  CREDIT_NOTE = "CREDIT_NOTE",
}

export enum AuditObjectTypeLabel {
  GLOBAL = "GLOBAL",
  OFFER = "l'offre",
  CUSTOMER = "le client",
  PURCHASE_ORDER = "le bon de commande",
  PROJECT = "le projet",
  WORKSHOP = "l'atelier",
  INVOICE = "la facture",
  SUPPLIER = "le fournisseur",
  EVA_COMPANY = "la société",
  EMPLOYEE = "le collaborateur",
  SALARY = "le salaire",
  CONTACT = "le contact",
  CONTRACT = "le contrat",
  ADDRESS = "l'adresse",
}

export enum AuditActionTypeLabel {
  UPDATE = "mis à jour",
  DENIED_UPDATE = "pas pu mettre à jour",
  CREATE = "créé",
  DENIED_CREATE = "pas pu créer",

  UPDATE_POSITION = "mis à jour la position de",
  CREATE_POSITION = "créé la position de",
  DELETE_POSITION = "supprimé la position de",

  UPDATE_SALARY_AUTO = "mis à jour automatiquement",
  CREATE_SALARY_AUTO = "créé automatiquement",
  DENIED_AUTO_SALARY = "pas pu mettre à jour/créer automatiquement",
  DENIED_CREATE_SALARY = "pas pu créer le salaire de",
  CREATE_SALARY = "créé le salaire de",
  DENIED_UPDATE_SALARY = "pas pu mettre à jour le salaire de",
  UPDATE_SALARY = "mis à jour le salaire de",
  ADD_CONTACT_LINK = "lié le contact",
  REMOVE_CONTACT_LINK = "détaché le contact",
  ADD_CUSTOMER_ADDRESS = "ADD_CUSTOMER_ADDRESS",
  ADD_SUPPLIER_ADDRESS = "ADD_SUPPLIER_ADDRESS",
  UPDATE_CUSTOMER_ADDRESS = "UPDATE_CUSTOMER_ADDRESS",
  UPDATE_SUPPLIER_ADDRESS = "UPDATE_SUPPLIER_ADDRESS",
  DELETE_CUSTOMER_ADDRESS = "DELETE_CUSTOMER_ADDRESS",
  DELETE_SUPPLIER_ADDRESS = "DELETE_SUPPLIER_ADDRESS",
}

export interface Audit {
  audit_id: string;
  eva_company_id: string;
  audit_date: Date;
  object_type: AuditObjectType;
  object_id: string;
  action_type: string;
  object_before: any;
  object_after: any;
  comment: string;
  user_id: string;
  employee_id: string;
  employee_name: string;
  activity_comment: string;
}

export class CustomerAudit {
  audit_id: string;
  eva_company_id: string;
  audit_date: Date;
  object_type: AuditObjectType;
  object_id: string;
  action_type: string;
  object_before: any;
  object_after: any;
  comment: string;
  user_id: string;
  employee_id: string;
  employee_name: string;

  get customer_before(): Customer {
    return this.object_before ? Customer.createInstance(this.object_before) : null;
  }

  get customer_after(): Customer {
    return this.object_after ? Customer.createInstance(this.object_after) : null;
  }

  get customer_name() {
    return this.customer_before?.name;
  }

  get customer_corp_id() {
    return this.customer_before?.customer_corporate_id;
  }

  get customer_id() {
    return this.customer_before?.customer_id;
  }
}

export class ContactAudit {
  audit_id: string;
  eva_company_id: string;
  audit_date: Date;
  object_type: AuditObjectType;
  object_id: string;
  action_type: string;
  object_before: any;
  object_after: any;
  comment: string;
  user_id: string;
  employee_id: string;
  employee_name: string;

  get contact_before(): Contact {
    return this.object_before ? Contact.createInstance(this.object_before) : null;
  }

  get contact_after(): Contact {
    return this.object_after ? Contact.createInstance(this.object_after) : null;
  }

  get contact_name() {
    return this.contact_before?.last_name;
  }

  get contact_corp_id() {
    return this.contact_before?.contact_corporate_id;
  }

  get contact_id() {
    return this.contact_before?.contact_id;
  }
}

export class SupplierAudit {
  audit_id: string;
  eva_company_id: string;
  audit_date: Date;
  object_type: AuditObjectType;
  object_id: string;
  action_type: string;
  object_before: any;
  object_after: any;
  comment: string;
  user_id: string;
  employee_id: string;
  employee_name: string;

  get supplier_before(): Supplier {
    return this.object_before ? Supplier.createInstance(this.object_before) : null;
  }

  get supplier_after(): Supplier {
    return this.object_after ? Supplier.createInstance(this.object_after) : null;
  }

  get supplier_name() {
    return this.supplier_before?.name;
  }

  get supplier_corp_id() {
    return this.supplier_before?.supplier_corporate_id;
  }

  get supplier_id() {
    return this.supplier_before?.supplier_id;
  }
}

export class CreditNoteAudit {
  audit_id: string;
  eva_company_id: string;
  audit_date: Date;
  object_type: AuditObjectType;
  object_id: string;
  action_type: string;
  object_before: any;
  object_after: any;
  comment: string;
  user_id: string;
  employee_id: string;
  employee_name: string;

  get credit_note_before(): CreditNote {
    return this.object_before ? CreditNote.createInstance(this.object_before) : null;
  }

  get credit_note_after(): CreditNote {
    return this.object_after ? CreditNote.createInstance(this.object_after) : null;
  }

  get credit_note_name() {
    return this.credit_note_before?.name;
  }

  get credit_note_id() {
    return this.credit_note_before?.credit_note_id;
  }
}

export class OfferAudit {
  audit_id: string;
  eva_company_id: string;
  audit_date: Date;
  object_type: AuditObjectType;
  object_id: string;
  action_type: string;
  object_before: any;
  object_after: any;
  comment: string;
  user_id: string;
  employee_id: string;
  employee_name: string;

  get offer_before(): Offer {
    return this.object_before ? Offer.createInstance(this.object_before) : null;
  }

  get offer_after(): Offer {
    return this.object_after ? Offer.createInstance(this.object_after) : null;
  }

  get offer_desc() {
    return this.offer_before?.pdf_config.title;
  }

  get offer_corp_id() {
    return this.offer_before?.offer_corporate_id;
  }

  get offer_id() {
    return this.offer_before?.offer_id;
  }
}

export class ProjectAudit {
  audit_id: string;
  eva_company_id: string;
  audit_date: Date;
  object_type: AuditObjectType;
  object_id: string;
  action_type: string;
  object_before: any;
  object_after: any;
  comment: string;
  user_id: string;
  employee_id: string;
  employee_name: string;

  get project_before(): Project {
    return this.object_before ? Project.createInstance(this.object_before) : null;
  }

  get project_after(): Project {
    return this.object_after ? Project.createInstance(this.object_after) : null;
  }

  get project_desc() {
    return this.project_before?.desc;
  }

  get project_corp_id() {
    return this.project_before?.project_corporate_id;
  }

  get project_id() {
    return this.project_before?.project_id;
  }
}

export class WorkshopAudit {
  audit_id: string;
  eva_company_id: string;
  audit_date: Date;
  object_type: AuditObjectType;
  object_id: string;
  action_type: string;
  object_before: any;
  object_after: any;
  comment: string;
  user_id: string;
  employee_id: string;
  employee_name: string;

  get workshop_before(): Workshop {
    return this.object_before ? Workshop.createInstance(this.object_before) : null;
  }

  get workshop_after(): Workshop {
    return this.object_after ? Workshop.createInstance(this.object_after) : null;
  }

  get workshop_info() {
    return this.workshop_before?.info;
  }

  get workshop_corp_id() {
    return this.workshop_before?.workshop_corporate_id;
  }

  get workshop_id() {
    return this.workshop_before?.workshop_id;
  }
}

export class EmployeeAudit {
  audit_id: string;
  eva_company_id: string;
  audit_date: Date;
  object_type: AuditObjectType;
  object_id: string;
  action_type: string;
  object_before: any;
  object_after: any;
  comment: string;
  user_id: string;
  employee_id: string;
  employee_name: string;
  activity_comment: string = "";

  public static createInstance(jsonData: any): EmployeeAudit {
    const a = Object.assign(new EmployeeAudit(), jsonData);
    a.audit_date = new Date(a.audit_date);
    return a;
  }

  public static createInstances(jsonData: any[]): EmployeeAudit[] {
    return jsonData.map(EmployeeAudit.createInstance);
  }

  get activityLabel() {
    return this.activity_comment
      ? this.activity_comment
      : this.employee_name +
          " a " +
          (AuditActionTypeLabel[this.action_type] ? AuditActionTypeLabel[this.action_type] : this.action_type) +
          " " +
          AuditObjectTypeLabel[this.object_type];
  }
}

export class EvaCompanyAudit {
  audit_id: string;
  eva_company_id: string;
  audit_date: Date;
  object_type: AuditObjectType;
  object_id: string;
  action_type: string;
  object_before: any;
  object_after: any;
  comment: string;
  user_id: string;
  employee_id: string;
  employee_name: string;

  public static createInstance(jsonData: any): EvaCompanyAudit {
    const a = Object.assign(new EvaCompanyAudit(), jsonData);
    a.audit_date = new Date(a.audit_date);
    return a;
  }

  public static createInstances(jsonData: any[]): EvaCompanyAudit[] {
    return jsonData.map(EvaCompanyAudit.createInstance);
  }
}

export class PurchaseOrderAudit {
  audit_id: string;
  eva_company_id: string;
  audit_date: Date;
  object_type: AuditObjectType;
  object_id: string;
  action_type: string;
  object_before: any;
  object_after: any;
  comment: string;
  user_id: string;
  employee_id: string;
  employee_name: string;

  get purchase_order_before(): PurchaseOrder {
    return this.object_before ? PurchaseOrder.createInstance(this.object_before) : null;
  }

  get purchase_order_after(): PurchaseOrder {
    return this.object_after ? PurchaseOrder.createInstance(this.object_after) : null;
  }

  get customer_corp_id() {
    return this.purchase_order_before?.purchase_order_corporate_id;
  }

  get customer_id() {
    return this.purchase_order_before?.purchase_order_id;
  }
}

export class ExpenseReportAudit {
  audit_id: string;
  eva_company_id: string;
  audit_date: Date;
  object_type: AuditObjectType;
  object_id: string;
  action_type: string;
  object_before: any;
  object_after: any;
  comment: string;
  user_id: string;
  employee_id: string;
  employee_name: string;

  get expenseReport_before(): ExpenseReport {
    return this.object_before ? ExpenseReport.createInstance(this.object_before) : null;
  }

  get expenseReport_after(): ExpenseReport {
    return this.object_after ? ExpenseReport.createInstance(this.object_after) : null;
  }

  get expenseReport_name() {
    return this.expenseReport_before?.name;
  }

  get expenseReport_corp_id() {
    return this.expenseReport_before?.expense_report_corporate_id;
  }

  get expenseReport_id() {
    return this.expenseReport_before?.expense_report_id;
  }
}

export class AddressAudit {
  audit_id: string;
  eva_company_id: string;
  audit_date: Date;
  object_type: AuditObjectType;
  object_id: string;
  action_type: string;
  object_before: any;
  object_after: any;
  comment: string;
  user_id: string;
  employee_id: string;
  employee_name: string;

  get address_before(): EvaAddress {
    return this.object_before ? EvaAddress.createInstance(this.object_before) : null;
  }

  get address_after(): EvaAddress {
    return this.object_after ? EvaAddress.createInstance(this.object_after) : null;
  }

  get address_name() {
    return this.address_before?.address_type;
  }

  get address_id() {
    return this.address_before?.address_id;
  }
}
